import { SvgIconComponent } from '@mui/icons-material';
import {
  Stack,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

type EventDetailsFormSectionMenuPropsTypes = {
  // expandedId: string;
  // targetId: string;
  headlineTranslationString: string;
  sublineTranslationString: string;
  translationNs?: string;
  Icon: SvgIconComponent;
  //   FormComponent: ReactNode;
  //   mediaQueryMobile: boolean;
  //   keepOpen?: boolean;
  //   status?: string;
  //   active?: boolean;
  clickHandler?: () => void;
  PaymentIcon?: ReactNode;
  activeForm?: boolean;
};

export default function EventDetailsFormSectionMenu(
  props: EventDetailsFormSectionMenuPropsTypes,
) {
  // props
  const {
    headlineTranslationString,
    sublineTranslationString,
    Icon,
    clickHandler,
    PaymentIcon,
    activeForm = false,
  } = props;

  // theme media queries
  const theme = useTheme();

  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  // translation
  const { t } = useTranslation('event');

  return (
    <>
      <Stack
        sx={{
          width: '100%',
          cursor: 'pointer',
          p: 1,
          pr: !mediaQuery ? 0 : 1,
          py: !mediaQuery ? '4px' : 1,
          //   '&:not(:last-child)': { borderBottom: '1px solid red' },
        }}
        onClick={clickHandler}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Icon color={activeForm && !mediaQuery ? 'primary' : 'inherit'} />
            <Stack>
              <Typography
                variant="subtitle1"
                color={activeForm && !mediaQuery ? 'primary' : 'inherit'}
              >
                {t(headlineTranslationString, '_' + headlineTranslationString)}
              </Typography>
              {mediaQuery && (
                <Typography variant="caption">
                  {t(sublineTranslationString, '_' + sublineTranslationString)}
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            {PaymentIcon && PaymentIcon}
            <ArrowForwardIosOutlinedIcon
              color={activeForm && !mediaQuery ? 'primary' : 'inherit'}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
