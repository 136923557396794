import Parse from 'parse';
import useAuthStore from '../../store/authStore';

// !!! typing
export default async function setNewMediaVersionApi(
  mediaId: string | null,
  file: File,
  eventId: string | undefined,
) {
  if (!mediaId) return new Error('No MediaId available');

  const user = useAuthStore.getState().user;

  const fileName = file.name.split('.')[0];
  // const fileSuffix = file.type.split('/')[1];
  const fileNameSplit = file.name.split('.');
  const fileSuffix = fileNameSplit[fileNameSplit.length - 1];

  // generate new file on server and get the url
  const newFile = new Parse.File(fileName + '.' + fileSuffix, file);

  const result = newFile
    .save({ sessionToken: user.sessionToken })
    .then(async (res) => {
      Parse.Cloud.run(
        'setNewMediaVersion',
        {
          fileName: fileName,
          fileSuffix: fileSuffix,
          url: res._url,
          size: file.size,
          mediaId: mediaId,
          userId: user.objectId,
          eventId: eventId,
        },
        { sessionToken: user.sessionToken },
      );
    })
    .catch((e) => e);

  return result;
}
