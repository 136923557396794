import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default function deleteMediaApi(
  mediaId: string | null,
  eventId: string | undefined,
  sessionId: string | undefined,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'deleteMedia',
    {
      mediaId: mediaId,
      eventId: eventId,
      sessionId: sessionId,
    },
    { sessionToken: sessionToken },
  );
}
