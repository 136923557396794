import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import { useTranslation } from 'react-i18next';
import EventDetailsFormLayoutBasic from './EventDetailsFormLayoutBasic';
import EventDetailsFormLayoutMeta from './EventDetailsFormLayoutMeta';
import {
  ReactComponentElement,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import EventDetailsFormSection from './EventDetailsFormSection';

type EventDetailsFormLayoutGeneralPropsTypes = {
  mediaQueryMobile: boolean;
  featureType?: string;
};

export default function EventDetailsFormLayoutGeneral(
  props: EventDetailsFormLayoutGeneralPropsTypes,
) {
  // props
  const { mediaQueryMobile, featureType } = props;

  return (
    <>
      <Stack>
        {(!mediaQueryMobile ||
          (mediaQueryMobile && featureType === 'basics')) && (
          <EventDetailsFormSection
            headlineTranslationString="DETAILS-FORM.SECTION.BASIC.HEADLINE"
            sublineTranslationString="DETAILS-FORM.SECTION.BASIC.SUBLINE"
            translationNs="event"
            Icon={InfoOutlinedIcon}
            FormComponent={<EventDetailsFormLayoutBasic />}
            mediaQueryMobile={mediaQueryMobile}
            keepOpen
          />
        )}
      </Stack>
    </>
  );
}
