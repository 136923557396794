import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { navigationComponents } from './NavigationComponents';
import { useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useRef, useState } from 'react';
import useUserPermissions from '../Hooks/useUserPermissions';
import { getUserMenuAccess } from '../../util/userAccessRights';
import { displayEventFromToDate } from '../../util/dateFormating';

import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import useEventStore from '../../store/eventStore';
import useEventQueryHook from '../../data/event/event.query';
import useLiveDataQueryHook from '../../data/live/live.query';
import { useQueryClient } from '@tanstack/react-query';
import useSessionStore from '../../store/sessionStore';

type SubNavigationParamsTypes = {
  menu?: string;
};

export default function MainNavigation({
  menu = '',
}: SubNavigationParamsTypes) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const events = window.fsEvents;

  // params
  const menuData = getUserMenuAccess(menu);

  // jodit editor dummy ref
  const joditRef = useRef(null);

  // state
  const [subMenuPos, setSubMenuPos] = useState('Sessions');
  const [subMenuBackLink, setSubMenuBackLink] = useState(false);

  // get path params
  const { eventId, sessionId } = useParams();

  // query client
  const queryClient = useQueryClient();

  // get the modal container
  const modalRoot = document.getElementById('modalRoot');

  // setup drawer
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerWidth = 240;

  // drawer container
  const container =
    typeof window !== 'undefined' ? window.document.body : undefined;

  // subscribe to cached query
  useEventQueryHook(eventId!);

  //// global store
  const eventData = useEventStore((state) => state.data);
  const eventDataStatus = useEventStore((state) => state.status);

  const sessionData = useSessionStore((state) => state.data);
  const sessionDataStatus = useSessionStore((state) => state.status);

  // state
  // toogle settings modal
  const [modalOpen, setModalOpen] = useState(false);

  // !!! typing
  const toggleEventSettingsModal = () => {
    // if (modalOpen) useEventStore.setState({ data: eventData });
    // setModalOpen(!modalOpen);
    events?.publish('toggleEventMenu', {});
  };

  // subscribe to live data
  useLiveDataQueryHook(eventId!);

  // navigation
  const navigate = useNavigate();

  // theme
  const theme = useTheme();

  // permission settings
  const { hasPermission } = useUserPermissions();

  // subscribe to session name event
  useEffect(() => {
    // NOT NEEDED ANY MORE
    // const subId = events?.subscribe(
    //   'subMenuPos',
    //   function (subMenuPos: { subMenuPos: string; backLink: boolean }) {
    //     setSubMenuPos(subMenuPos.subMenuPos);
    //     setSubMenuBackLink(subMenuPos.backLink);
    //   },
    // );

    return () => {
      // if (subId) {
      //   events?.unsubscribe(subId);
      // }

      // reset event store & query
      queryClient.removeQueries({ queryKey: ['event', 'details'] });
      useEventStore.setState({ data: null, status: 'pending' });

      // // reset session store & query
      // queryClient.removeQueries({ queryKey: ['session', 'details'] });
      // useSessionStore.setState({ data: null, status: 'pending' });
    };
  }, []);

  return (
    <>
      {eventDataStatus === 'success' && (
        <>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <AppBar
              position="fixed"
              sx={{
                boxShadow: 'none',
                zIndex: (theme) => {
                  return theme.zIndex.drawer + 1;
                },
              }}
            >
              <Toolbar sx={{ px: { xs: 1, sm: 2 } }}>
                <Stack
                  direction="row"
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  width={'100%'}
                >
                  {/* left menu */}
                  {/* Desktop */}
                  <Box
                    sx={{
                      display: { xs: 'none', sm: 'block', zIndex: 10 },
                      maxWidth: '20%',
                    }}
                  >
                    <Stack direction="row">
                      <IconButton
                        onClick={() => {
                          eventData.get('multiSession')
                            ? !sessionId
                              ? navigate('/events')
                              : navigate('../..', { relative: 'path' })
                            : navigate('/events');
                        }}
                        sx={{ pl: 0 }}
                        color="primary"
                      >
                        <ArrowBackIosNewIcon />
                      </IconButton>
                      <Stack sx={{ maxWidth: '100%' }}>
                        {eventDataStatus === 'success' && (
                          <>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                              >
                                {eventData.get('title')}
                              </Typography>
                              {/* {hasPermission({
                                objPath: 'event.settingsMenu',
                              }) && (
                                <Stack>
                                  <IconButton
                                    onClick={toggleEventSettingsModal}
                                    sx={{
                                      p: 0,
                                      fontSize: theme.typography.body1.fontSize,
                                    }}
                                    color="primary"
                                  >
                                    <SettingsIcon fontSize="inherit" />
                                  </IconButton>
                                </Stack>
                              )} */}
                            </Stack>

                            <Typography variant="caption">
                              {displayEventFromToDate(
                                eventData.get('startdate'),
                                eventData.get('enddate'),
                              )}
                            </Typography>
                          </>
                        )}
                      </Stack>
                      {/* Settings menu */}
                    </Stack>
                  </Box>

                  {/* Mobile */}
                  <IconButton
                    sx={{
                      pl: 0,
                      mx: 0,
                      display: { xs: 'inline-flex', sm: 'none' },
                    }}
                    color="primary"
                    onClick={toggleDrawer}
                  >
                    <MenuIcon />
                  </IconButton>

                  {/* center */}
                  {/* Desktop */}
                  {sessionId && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'flex',
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                        },
                        zIndex: 0,
                        cursor: eventData.get('multiSession')
                          ? 'pointer'
                          : 'default',
                      }}
                      onClick={() =>
                        // subMenuBackLink &&
                        eventData.get('multiSession') &&
                        navigate('../..', { relative: 'path' })
                      }
                    >
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{
                          fontWeight: 'bold',
                          maxWidth: '40%',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {/* {subMenuPos} */}
                        {sessionDataStatus === 'success' &&
                          sessionData.get('title')}
                      </Typography>

                      {
                        // subMenuBackLink &&
                        eventData.get('multiSession') && (
                          <>
                            <SwapHorizIcon
                              sx={{
                                width: '18px',
                                height: '18px',
                                ml: '4px !important',
                                opacity: eventData?.get('multiSession') ? 1 : 0,
                              }}
                              color="primary"
                            />
                          </>
                        )
                      }
                    </Stack>
                  )}

                  {/* Mobile */}
                  <Stack
                    alignItems="center"
                    sx={{
                      display: { xs: 'flex', sm: 'none' },
                      width: 'calc(100svw - 40%)',
                      overflow: 'hidden',
                    }}
                  >
                    <Stack
                      onClick={() => !sessionId && navigate('/events')}
                      sx={{
                        cursor: !sessionId ? 'pointer' : 'default',
                        // maxWidth: 'calc(100% - 32px)',
                        width: '100%',
                      }}
                    >
                      {/* Upper row */}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 'bold',
                            lineHeight: 1,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (
                              // subMenuBackLink &&
                              eventData.get('multiSession')
                            ) {
                              navigate('../..', { relative: 'path' });
                            }

                            if (
                              !sessionId ||
                              (sessionId && !eventData?.get('multiSession'))
                            ) {
                              navigate('/events');
                            }
                          }}
                        >
                          {eventDataStatus === 'success' &&
                            eventData.get('title')}
                        </Typography>
                        {(!sessionId ||
                          (sessionId && !eventData?.get('multiSession'))) && (
                          <>
                            <SwapHorizIcon
                              onClick={() =>
                                (!sessionId ||
                                  (sessionId &&
                                    !eventData?.get('multiSession'))) &&
                                navigate('/events')
                              }
                              sx={{
                                fontSize: theme.typography.body1.fontSize,
                                width: '18px',
                                height: '18px',
                                ml: '4px !important',
                              }}
                              color="primary"
                            />
                          </>
                        )}
                      </Stack>

                      {!sessionId && (
                        <Typography
                          variant="caption"
                          sx={{
                            //fontWeight: 'bold',
                            textAlign: 'center',
                            lineHeight: 1,
                          }}
                        >
                          {eventDataStatus === 'success' &&
                            displayEventFromToDate(
                              eventData.get('startdate'),
                              eventData.get('enddate'),
                            )}
                        </Typography>
                      )}

                      {/* Lower row */}
                      {sessionId && eventData?.get('multiSession') && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                          sx={{
                            cursor: eventData.get('multiSession')
                              ? 'pointer'
                              : 'default',
                          }}
                          onClick={() =>
                            eventData.get('multiSession') &&
                            navigate('../..', { relative: 'path' })
                          }
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {/* {subMenuPos} */}
                            {sessionDataStatus === 'success' &&
                              sessionData.get('title')}
                          </Typography>

                          {sessionId &&
                            // subMenuBackLink &&
                            eventData.get('multiSession') && (
                              <>
                                <SwapHorizIcon
                                  onClick={() =>
                                    // subMenuBackLink &&
                                    eventData.get('multiSession') &&
                                    navigate('../..', { relative: 'path' })
                                  }
                                  sx={{
                                    fontSize: theme.typography.body1.fontSize,
                                    width: '18px',
                                    height: '18px',
                                    ml: '4px !important',
                                    display: eventData?.get('multiSession')
                                      ? 'block'
                                      : 'none',
                                  }}
                                  color="primary"
                                />
                              </>
                            )}
                        </Stack>
                      )}
                    </Stack>
                  </Stack>

                  {/* right menu */}
                  {navigationComponents.userMenu}
                </Stack>
              </Toolbar>
            </AppBar>
          </Box>
        </>
      )}
      {/* Mobilde Drawer */}
      <Box
        sx={{
          display: { xs: 'block', sm: 'none' },
          position: 'absolute',
        }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={drawerOpen}
          onClose={() => toggleDrawer()}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            zIndex: 1201,
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {/* top box */}
          <Box sx={{ backgroundColor: theme.palette.secondary.main, p: 1 }}>
            <Stack spacing={1}>
              <Skeleton
                variant="rectangular"
                width={32}
                height={32}
                sx={{ bgcolor: theme.palette.secondary.contrastText, m: 0 }}
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="end"
              >
                <Stack sx={{ maxWidth: '100%' }}>
                  <Stack direction="row" alignItems="center">
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {/* {eventQueryResult.isSuccess &&
                        eventQueryResult.data.get('title')} */}
                      {eventDataStatus === 'success' && eventData.get('title')}
                    </Typography>
                    {hasPermission({ objPath: 'event.settingsMenu' }) && (
                      <IconButton
                        // size="small"
                        onClick={() => toggleEventSettingsModal()}
                        sx={{ fontSize: theme.typography.body1.fontSize }}
                      >
                        <SettingsIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </Stack>

                  <Typography variant="body2">
                    {eventDataStatus === 'success' &&
                      displayEventFromToDate(
                        eventData.get('startdate'),
                        eventData.get('enddate'),
                      )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Box>

          {/* Menu */}
          <Stack direction="column" spacing={1}>
            <Link
              component={RouterLink}
              to="/events"
              underline="none"
              sx={{
                lineHeight: '32px',
              }}
            >
              Event wechseln
            </Link>
            {menuData?.map((link, index) => {
              const pathSegements = link.path?.split('/');
              let targetSegment: string = '';

              if (pathSegements && pathSegements.length > 0) {
                targetSegment = pathSegements[pathSegements.length - 1];
              }

              return link.path ? (
                hasPermission({
                  objPath: 'subnavigation.' + menu + '.' + targetSegment,
                }) && (
                  <Link
                    key={link.title}
                    component={RouterLink}
                    to={link.path}
                    underline="none"
                    sx={{
                      lineHeight: '32px',
                    }}
                    onClick={() => {
                      setSubMenuPos(link.title);
                      toggleDrawer();
                    }}
                  >
                    {link.title}
                  </Link>
                )
              ) : (
                <Link key={link.title}>{link.title}</Link>
              );
            })}
          </Stack>
        </Drawer>
      </Box>
    </>
  );
}
