import { KeyboardEvent } from 'react';

// helper to extract the react hook-form / yup errors in forms
type ErrorsObject = {
  [key: string]:
    | {
        message?: string;
      }
    | undefined;
};

export function getErrorMessage(
  errors: ErrorsObject,
  fieldName: string,
): string | undefined {
  const fieldError = errors[fieldName];
  return typeof fieldError?.message === 'string'
    ? fieldError.message
    : undefined;
}

export function preventEnterToSubmit(
  e: KeyboardEvent<HTMLFormElement>,
  emulateTab?: boolean,
) {
  if (emulateTab === undefined) emulateTab = false;

  if (e.key === 'Enter') {
    e.preventDefault();

    if (emulateTab) {
      const form = e.currentTarget;
      const inputs = Array.from(
        form.querySelectorAll('input, select, textarea, button'),
      );

      const index = inputs.indexOf(document.activeElement as HTMLElement);
      if (index > -1 && index < inputs.length - 1) {
        (inputs[index + 1] as HTMLElement).focus();
      } else {
        (inputs[0] as HTMLElement).focus();
      }
    }
  }
}
