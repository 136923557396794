import * as yup from 'yup';
import i18n from 'i18next';

export const eventDetailsFeatureMonetizationValidationSchema = yup.object({
  partnerLogo: yup.array().of(yup.string()),
});

export const eventDetailsFeatureMonetizationDefaults = {
  partnerLogo: [],
};
