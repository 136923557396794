import { Stack } from '@mui/material';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';
import {
  eventDetailsFeatureMonetizationValidationSchema,
  eventDetailsFeatureMonetizationDefaults,
} from './eventDetailsFeatureMonetizationValidationSchema';

type EventDetailsFormLayoutFeaturesMonetPropsTypes = {
  featureState?: boolean;
};
export default function EventDetailsFormLayoutFeaturesMonet(
  props: EventDetailsFormLayoutFeaturesMonetPropsTypes,
) {
  // props
  const { featureState } = props;

  // event details context
  const { monetData, updateMonetData } = useEventDetailsFormData();

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsFeatureMonetizationValidationSchema),
    defaultValues: {
      ...eventDetailsFeatureMonetizationDefaults,
      ...monetData?.data,
    },
  });

  const watch = useWatch({ control });

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  useEffect(() => {
    // save changed form data to context
    if (!firstRun) {
      updateMonetData(getValues(), featureState ? featureState : false);
    } else {
      setFirstRun(false);
    }
  }, [watch, featureState]);

  return (
    <Stack sx={{ mt: 2 }}>
      <_FileUpload
        fullWidth
        control={control}
        fieldName="partnerLogo"
        fieldLabel="Partner Logos"
        error={errors.partnerLogo && getErrorMessage(errors, 'partnerLogo')}
        multiple
        maxFiles={5}
        setValue={setValue}
        defaultValue={getValues!('partnerLogo')}
        imageResizerSettings={{
          maxWidth: 256,
          maxHeight: 256,
          minWidth: 256,
          minHeight: 256,
          jpegQuality: 80,
        }}
        sx={{ mb: 2 }}
      />
    </Stack>
  );
}
