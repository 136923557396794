import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export type deleteSessionParamsTypes = { eventId: string; sessionId: string };

export default async function deleteSessionApi(
  params: deleteSessionParamsTypes,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run('deleteSession', params, {
    sessionToken: sessionToken,
  });
}
