import { useEffect, useState } from 'react';

/**
 * Hook to calculate checkout sums & building the payment info object
 * @param params The hook parameters
 * @param params.eventIds Ids of the events that have open payment options (currently only one event)
 * @param params.allAvailableFeatures All feature types that exist in the app
 * @param params.eventFeatures All items that can have paid features (currently only for one event)
 * @returns 
 */
export default function usePayment(params: usePaymentParamsTypes) {
  // props
  const { eventIds, allAvailableFeatures, eventFeatures } = params;;
  
  // state
  // !!! typing
  const [features, setFeatures] = useState<any>();

  const [featureList, setFeatureList] = useState<
    | {
        featureListActive: { [key: string]: any[] };
        featureListPaid: { [key: string]: any[] };
      }
    | undefined
  >();

  // the list of the items to be paid
  const [orderList, setOrderList] = useState<
    { [key: string]: any } | undefined
  >();

  // the total amount
  const [orderTotal, setOrderTotal] = useState(0);

  // calculation & sorting
  /**
   * Function building the featureLists & the cost calculation
   * @param features All features the are or can be activated in this event
   * @param allAvailableFeatures All features that are avaialble in the app
   */
  const featuresCalc = (features: any, allAvailableFeatures: any[]) => {
    // convert array of Parse Objects to object
    const allAvailableFeaturesObj = allAvailableFeatures.reduce(
      (acc, current) => {
        acc[current.attributes.handle] = current.attributes;
        return acc;
      },
      {},
    );

    // the resulting arrays
    const featureListActive: { [key: string]: any[] } = {};
    const featureListPaid: { [key: string]: any[] } = {};

    // sort features by paid and notPaid
    if (features) {
      for (const [key] of Object.entries(features)) {

        featureListPaid[key] = [];
        featureListActive[key] = [];

        features[key].forEach((item: any) => {

          if (item.status === 'paid') {
            featureListPaid[key].push({
              ...item,
              ...allAvailableFeaturesObj[key],
              eventId: eventIds.masterEventId,
              remoteId: eventIds.remoteEventId,
            });
          } else {
            featureListActive[key].push({
              ...item,
              ...allAvailableFeaturesObj[key],
              eventId: eventIds.masterEventId,
              remoteId: eventIds.remoteEventId,
            });
          }
        });
      }
    }

    // set the paid / unPaid Lists
    setFeatureList({
      featureListActive: featureListActive,
      featureListPaid: featureListPaid,
    });

    // do the calculation for the notPaid features
    const invoiceList: { [key: string]: any } = {};
    let invoiceTotal = 0;

    allAvailableFeatures.forEach((feature) => {
      let featureAmount = 0;
      let featureCount = 0;
      const featureHandle = feature.get('handle');

      // check if this feature needs to be billed
      if(featureListActive[featureHandle]){
        if (!invoiceList[featureHandle]) {
          invoiceList[featureHandle] = {};
        }

        invoiceList[featureHandle]['name'] = feature.get('name');
        invoiceList[featureHandle]['description'] = feature
          .get('description')
          .replace(
            '#value#',
            feature.get('amount').value + ' ' + feature.get('amount').currency,
          );
          
        const currentFeature = featureListActive[featureHandle];

        if (currentFeature.length > 0) {
          currentFeature.forEach((cf) => {
            featureCount += 1;
            featureAmount += feature.get('amount').value;
          });

          invoiceList[featureHandle]['count'] = featureCount;
          invoiceList[featureHandle]['amount'] = featureAmount;
          invoiceList[featureHandle]['singleAmount'] =
            feature.get('amount').value;
          invoiceList[featureHandle]['taxRate'] = feature.get('amount').taxRate;
          invoiceTotal += featureAmount;
      }}
    });

    setOrderList(invoiceList);
    setOrderTotal(invoiceTotal);
  };

  useEffect(() =>{
    // call the calculationFct if all data is available
    if(eventFeatures && allAvailableFeatures) {
        featuresCalc(eventFeatures, allAvailableFeatures)
    }
  },[eventFeatures, allAvailableFeatures])

  return {
    featureList: featureList,
    total: orderTotal,
    orderList: orderList,
  };
}
