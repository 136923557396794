import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default async function deleteStaffApi(staffId: string) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'deleteStaff',
    { staffId: staffId },
    { sessionToken: sessionToken },
  );
}
