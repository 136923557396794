import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import updateEventApi, {
  updateEventApiParamsTypes,
} from '../../../api/event/updateEventApi';
import { useFeedback } from '../../Feedback/FeedbackContext';
import { useState } from 'react';

type useEventDetailsFormDataHandlingReturnTypes = {
  handleSubmit: () => void;
};

export default function useEventDetailsFormDataHandling(): useEventDetailsFormDataHandlingReturnTypes {
  // feedback
  const { setFeedback } = useFeedback();

  // event details form data context
  const {
    eventId,
    basicData,
    metaData,
    customizationData,
    marketingData,
    mediaData,
    monetData,
    registrationData,
    onlineData,
    handleCancel,
    updateEventSettingsIsDirty,
    submitCallback,
  } = useEventDetailsFormData();

  //// data mutation
  const queryClient = useQueryClient();

  // build the payload
  const payload: { [key: string]: any } = {};

  payload.eventId = eventId;

  if (basicData.isDirty) {
    delete basicData.data?.features_active;
    payload.basicData = basicData.data;
  }

  if (metaData?.isDirty) payload.metaData = metaData.data;

  if (customizationData?.isDirty)
    payload.customizationData = customizationData.data;

  if (marketingData?.isDirty) payload.marketingData = marketingData.data;

  if (mediaData?.isDirty)
    payload.mediaData = { data: mediaData.data, active: mediaData.active };

  if (monetData?.isDirty)
    payload.monetData = { data: monetData.data, active: monetData.active };

  if (registrationData?.isDirty)
    payload.registrationData = {
      data: registrationData.data,
      active: registrationData.active,
    };

  if (onlineData?.isDirty)
    payload.onlineData = { data: onlineData.data, active: onlineData.active };

  // save the data (basics at first)
  const mutateSaveEvent = useMutation({
    mutationFn: () => updateEventApi(payload as updateEventApiParamsTypes),
    onSuccess: () => {
      // setFeedback({ type: 'success', headline: 'Daten gespeichert' });
      setFeedback(null);

      // reset isDirty state (disabling save button etc.)
      updateEventSettingsIsDirty(false);

      // call submitSuccessCallback
      submitCallback && submitCallback('success');

      queryClient.invalidateQueries({
        queryKey: ['event', 'details', eventId],
      });
      queryClient.invalidateQueries({
        queryKey: ['sessions', 'list', eventId],
      });
    },
    onError: (e) => {
      // call submitErrorCallback
      submitCallback && submitCallback('error');

      setFeedback({ type: 'error', errorCode: e });
    },
  });

  const submitData = () => {
    // handleCancel();
    setFeedback({ type: 'loading' });

    // delay the submit function to make sure onBlur data is set
    setTimeout(function () {
      mutateSaveEvent.mutate();
    }, 1000);
  };

  return {
    handleSubmit: submitData,
  };
}
