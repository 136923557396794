import { useQuery, useQueryClient } from '@tanstack/react-query';
import useEventStore from '../../store/eventStore';
import { getEventApi } from '../../api';
import { useEffect } from 'react';

// Layer II Data Handling
// define the query
export function useEventByIdQuery(eventId: string) {
  const queryClient = useQueryClient();

  return useQuery(
    {
      queryKey: ['event', 'details', eventId],
      queryFn: () => getEventApi(eventId),
    },
    queryClient,
  );
}

// Layer III Data Handling
// sync with localstore
export default function useEventQueryHook(eventId: string) {
  // event query
  const { data, error, isLoading, isSuccess, isError, status } =
    useEventByIdQuery(eventId);

  // event store
  const { setData, setStatus } = useEventStore();

  useEffect(() => {
    // update data
    if (data) setData(data);
  }, [data]);

  useEffect(() => {
    // update state
    setStatus(status);
  }, [status]);

  return { data, isLoading, isSuccess, isError };
}
