import { error } from 'console';
import Parse from 'parse';
import useAuthStore from '../../store/authStore';

// !!! typing
export default async function setNewMediaFileBasedApi(
  data: any,
  eventId: string | undefined,
  sessionId: string | undefined,
) {
  // file: any,
  // parent: { type: string; id: string },
  const { file, parent } = data;

  // get user:
  const user = useAuthStore.getState().user;

  const fileName = file.name.split('.')[0];
  const fileSuffix = file.type.split('/')[1];

  // generate new file on server and get the url
  const newFile = new Parse.File(fileName + '.' + fileSuffix, file);

  const result = await newFile
    .save({ sessionToken: user.sessionToken })
    .then(async (res) => {
      return await Parse.Cloud.run(
        'setNewMedia',
        {
          fileName: fileName,
          fileSuffix: fileSuffix,
          url: res._url,
          size: file.size,
          parentType: parent.type,
          parentId: parent.id,
          userId: user.objectId,
          eventId: eventId,
          sessionId: sessionId,
        },
        { sessionToken: user.sessionToken },
      );
    })
    .catch((e) => {
      console.error(e);

      return e;
    });

  return result;
}
