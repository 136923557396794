import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export type SetNewActorPropsTypes = {
  firstname?: string;
  lastname?: string;
  eventId: string;
  sessionId: string;
  parent: { className: string; id: string };
};

export default async function setNewActorApi(data: SetNewActorPropsTypes) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  const newData = data;
  if (!newData.firstname && !newData.lastname) {
    newData.firstname = 'Neuer';
    newData.lastname = 'Benutzer';
  }
  return Parse.Cloud.run(
    'setNewActor',
    { ...newData },
    { sessionToken: sessionToken },
  );
}
