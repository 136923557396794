import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getEventApi, getSessionApi, getSessionsApi } from '../../api';
import { useEffect } from 'react';
import useSessionStore from '../../store/sessionStore';

// Layer II Data Handling
export function useSessionsByEventIdQuery(eventId: string) {
  const queryClient = useQueryClient();

  return useQuery(
    {
      queryKey: ['sessions', 'list', eventId],
      queryFn: () => getSessionsApi({ eventId: eventId }),
    },
    queryClient,
  );
}
