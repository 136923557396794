import { useForm, useWatch } from 'react-hook-form';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Theme,
  useMediaQuery,
} from '@mui/material';
import {
  ErHtmlEditor,
  ErSwitch,
  ErTextField,
} from 'eventrocket-commoncomponents';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';
import { yupResolver } from '@hookform/resolvers/yup';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  eventDetailsMarketingValidationSchema,
  eventDetailsMarketingDefaults,
} from './eventDetailsMarketingValidationSchema';

import { htmlEditorDefaultConfig } from '../../../util/htmlEditor/htmlEditorDefaultConfig';

export default function EventDetailsFormLayoutMarketingText() {
  // props

  // translation
  const { t } = useTranslation('event');

  // media query
  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  // event details context
  const { marketingData, updateMarketingData } = useEventDetailsFormData();

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsMarketingValidationSchema),
    defaultValues: {
      ...eventDetailsMarketingDefaults,
      ...marketingData?.data,
    },
  });

  const watch = useWatch({ control });

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  useEffect(() => {
    // save changed form data to context

    if (!firstRun) {
      updateMarketingData(getValues());
    } else {
      setFirstRun(false);
    }
  }, [watch]);

  return (
    <>
      <Stack sx={{ my: 2 }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: '10px',
            gridTemplateColumns: 'repeat(auto-fill, minmax(150px, auto));',
          }}
        >
          <ErSwitch
            control={control}
            fieldName="showEventText"
            fieldLabel={t(
              'DETAILS-FORM.SECTION.MARKETINGTEXT.SWITCH_showEventText',
            )}
            labelPos="end"
            size="small"
          />
          <ErSwitch
            control={control}
            fieldName="showProgram"
            fieldLabel={t(
              'DETAILS-FORM.SECTION.MARKETINGTEXT.SWITCH_showProgram',
            )}
            labelPos="end"
            size="small"
          />
        </Box>
        {/* <Stack
          direction={mediaQuery ? 'column' : 'row'}
          spacing={mediaQuery ? 0 : 0}
          sx={{ mb: 2 }}
        >
          <Box sx={{ width: '50%' }}>
            <ErSwitch
              control={control}
              fieldName="showEventText"
              fieldLabel={t(
                'DETAILS-FORM.SECTION.MARKETINGTEXT.SWITCH_showEventText',
              )}
              labelPos="end"
              size="small"
            />
          </Box>
          <Box sx={{ width: '50%' }}>
            <ErSwitch
              control={control}
              fieldName="showProgram"
              fieldLabel={t(
                'DETAILS-FORM.SECTION.MARKETINGTEXT.SWITCH_showProgram',
              )}
              labelPos="end"
              size="small"
            />
          </Box>
        </Stack> */}

        {/* <Stack
          direction={mediaQuery ? 'column' : 'row'}
          spacing={mediaQuery ? 0 : 2}
          sx={{ mb: 2 }}
        > */}
        {/* <ErSwitch
            control={control}
            fieldName="showCountDown"
            fieldLabel={t(
              'DETAILS-FORM.SECTION.MARKETINGTEXT.SWITCH_showCountDown',
            )}
            labelPos="end"
          /> */}

        <ErSwitch
          control={control}
          fieldName="showAnnouncementText"
          fieldLabel={t(
            'DETAILS-FORM.SECTION.MARKETINGTEXT.SWITCH_showAnnouncementText',
          )}
          labelPos="end"
          size="small"
          sx={{ mb: 1 }}
        />
        {/* </Stack> */}

        <ErHtmlEditor
          control={control}
          editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
          // fieldLabel={t(
          //   'DETAILS-FORM.SECTION.MARKETINGTEXT.FIELD_announcementHtml',
          // )}
          fieldName="announcementHtml"
          sx={{ mb: 2 }}
          error={
            errors.announcementHtml &&
            getErrorMessage(errors, 'announcementHtml')
          }
          onChangeCallback={() =>
            !marketingData?.isDirty && updateMarketingData({}, true)
          }
          language="de"
        />

        {/* <ErHtmlEditor
          control={control}
          editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
          fieldLabel={t('DETAILS-FORM.SECTION.MARKETINGTEXT.FIELD_agendaHtml')}
          fieldName="agendaHtml"
          sx={{ mb: 2 }}
          error={errors.agendaHtml && getErrorMessage(errors, 'agendaHtml')}
          onChangeCallback={() =>
            !marketingData?.isDirty && updateMarketingData({}, true)
          }
          language="de"
        /> */}
      </Stack>
    </>
  );
}
