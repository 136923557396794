import FillBuffer from '-!svg-react-loader!../svg/Slotlist/fillBuffer.svg';
import { createSvgIcon } from '@mui/material';

const props = {
  version: '1.1',
  width: '24px',
  height: '24px',
  viewBox: '0 0 512 512',
};

export const FillBufferIcon = createSvgIcon(
  <FillBuffer {...(props as any)} />,
  'FillBuffer',
);
