import { ReactNode, useEffect, useState } from 'react';
import * as Parse from 'parse';
import { setConfig } from './util/setConfig';
import { useParams, useRoutes } from 'react-router-dom';
import routesConfig from './Router/routesConfig';
import { FeedbackProvider } from './Components/Feedback/FeedbackContext';
import FeedbackDisplayController from './Components/Feedback/FeedbackDisplayController';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { navigationComponents } from './Components/Navigation/NavigationComponents';
import { getUserRolesApi, updateSSOUserApi } from './api';
import useAuthStore from './store/authStore';
import { I18nextProvider } from 'react-i18next';
import i18n from './util/i18n';

import './util/htmlEditor/htmlEditorThemes.css';

export type ConnectionDataType = {
  serverUrl: string;
  appId: string;
  javascriptKey: string;
};

export type EventAdminTypes = {
  user?: Parse.User | undefined;
  userMenu?: ReactNode;
  masterEventId?: string;
  connectionData?: ConnectionDataType;
  ssoToken?: string | undefined | null;
  queryClient?: QueryClient;
  startUpActions?: { [key: string]: any };
};

// eslint-disable-next-line
if (process.env.NODE_ENV === 'production') console.log = function () {};
// console.log = function () {};

export default function App(params: EventAdminTypes) {
  // params
  const {
    user,
    userMenu,
    masterEventId,
    connectionData,
    ssoToken,
    queryClient,
    startUpActions,
  } = params;

  // Get the global event bus ref
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const events = window.fsEvents;

  // store the components handed down from parent to global object
  navigationComponents.userMenu = userMenu;

  //// state
  const [confSet, setConfSet] = useState(false);
  const [serverData, setServerData] = useState<any>(undefined);

  // nav params
  const { eventId } = useParams();

  console.log('###!!!§-userOrig', user);

  //// side effects
  useEffect(() => {
    // check if there is already an existing session
    const updateUserStore = (e: StorageEvent) => {
      const { key, newValue } = e;
      console.log('###!!!§-updateUserStore', e);
      if (key === 'bs_EVENTSERVER_SESSION' && newValue) {
        useAuthStore.setState({ user: JSON.parse(newValue) });
      }
    };

    window.addEventListener('storage', updateUserStore);

    return () => {
      // remove storage listener
      window.removeEventListener('storage', updateUserStore);
      // remove tokens
      localStorage.removeItem('bs_EVENTSERVER_SESSION');
      // reset user object in store
      useAuthStore.setState({ user: null });
    };
  }, []);

  // do app setup
  useEffect(() => {
    //// Do setup
    if (!confSet) {
      (async () => {
        //
        const confRes: any = await setConfig(
          ssoToken!,
          user!,
          eventId!,
          startUpActions,
          connectionData,
        ).catch((e) => console.error(e));

        //res && setServerData(res);
        if (confRes && confRes.objectId) {
          const localUser: any = confRes;

          // update user meta data
          await updateSSOUserApi({
            userId: localUser.objectId,
            originalUser: user!,
            data: {
              username: user!.get('username'),
              email: user!.get('email'),
              firstname: user!.get('firstname'),
              lastname: user!.get('lastname'),
              linkToActorId: startUpActions?.linkToActorId,
              linkToStaffId: startUpActions?.linkToStaffId,
            },
          }).catch((e) => {
            console.error(e);
          });

          // get the user roles after evt. linking is done
          const userRoles = await getUserRolesApi(eventId!);
          useAuthStore.setState({ userRoles: userRoles });

          confRes ? setConfSet(true) : setConfSet(false);
        }
      })();
    }

    // set the navigation path
    events?.publish('navLocation', { path: '' });
  }, [confSet]);

  // !!! THIS SHOULD NOT BE NEEDED ANY MORE
  // useEffect(() => {
  //   if (serverData) {
  //     // update the sso users props (profile data, invitation,...)
  //     (async () => {
  //       const localUser = serverData;

  //       await updateSSOUserApi({
  //         userId: localUser.id,
  //         originalUser: user!,
  //         data: {
  //           username: user!.get('username'),
  //           email: user!.get('email'),
  //           linkToActorId: startUpActions?.linkToActorId,
  //           linkToStaffId: startUpActions?.linkToStaffId,
  //         },
  //       }).catch((e) => {
  //         console.error(e);
  //       });
  //     })();

  //     // get the user roles after evt. linking is done
  //     (async () => {
  //       const userRoles = await getUserRolesApi(eventId!);
  //       useAuthStore.setState({ userRoles: userRoles });
  //     })();
  //   }
  // }, [serverData]);

  // react query setup
  // get the existing query client either from parent app or
  // if running stand alone from index.tsx
  const qc = queryClient ? queryClient : useQueryClient();

  // force update of all queries
  // !!! invalidateQueries, check if this is running to often!!!
  // qc.invalidateQueries(); // check if we need this anyway!!!

  // setup routes
  const routes = useRoutes(routesConfig(qc));

  return (
    <>
      <I18nextProvider i18n={i18n}>
        {/* Query Client provider probably not necessary here, move to index if required? */}
        {/* <QueryClientProvider client={qc}> */}
        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        <FeedbackProvider>
          {confSet && routes}
          <FeedbackDisplayController />
        </FeedbackProvider>
        {/* </QueryClientProvider> */}
      </I18nextProvider>
    </>
  );
}
