import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default async function setNewStaffApi(
  eventId: string,
  name: string,
  role: string,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'setNewStaff',
    {
      eventId: eventId,
      name: name,
      role: role,
    },
    { sessionToken: sessionToken },
  );
}
