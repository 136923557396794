import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export type UpdateActorApiParamsTypes = {
  actorId: string;
  data: any;
  sessionId: string | undefined;
  eventId: string | undefined;
};

export default function updateActorApi(data: UpdateActorApiParamsTypes) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  const updateData = { ...data };

  // split name into first- and lastname
  if (data.data.name && data.data.name !== '') {
    const splitName = data.data.name
      .split(/(\s+)/)
      .filter((e: any) => e.trim().length > 0);

    updateData.data.firstname = '';
    updateData.data.lastname = '';

    splitName.forEach((namePart: string, index: number) => {
      if (index < splitName.length - 2) {
        updateData.data.firstname += namePart + ' ';
      } else if (index < splitName.length - 1) {
        updateData.data.firstname += namePart;
      } else {
        updateData.data.lastname += namePart;
      }
    });
  }

  delete updateData.data.name;

  return Parse.Cloud.run(
    'updateActor',
    { ...updateData },
    { sessionToken: sessionToken },
  );
}
