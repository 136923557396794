import JoditEditor from 'jodit-pro-react';
import { useRef, useState } from 'react';
import { htmlEditorDefaultConfig } from '../../util/htmlEditor/htmlEditorDefaultConfig';
import { Box, Button, Stack } from '@mui/material';

type PreviewTextEditorProps = {
  text: string;
  closeHandler?: () => void;
  submitHandler: (text: string) => void;
};

export default function PreviewTextEditor(props: PreviewTextEditorProps) {
  // props
  const { text, closeHandler = () => {}, submitHandler } = props;

  //
  const [editorText, setEditorText] = useState(text);

  // editor ref
  const editor = useRef(null);

  // editor styles
  const jedtitorStyles = {
    flexGrow: 1,
  };

  return (
    <>
      {/* <Box sx={{ display: 'flex', position: 'relative', height: '100%' }}> */}
      <Stack sx={{ height: '100%' }}>
        <Stack sx={{ flexGrow: 1 }}>
          <JoditEditor
            ref={editor}
            value={editorText}
            config={{
              ...htmlEditorDefaultConfig,
              language: 'de',
              height: '96%',
              width: '100%',
              allowResizeX: false,
              allowResizeY: false,
            }}
            onBlur={(newContent) => {
              // onChange(newContent); // Update the value in the form
              // onBlur(); // Mark the field as touched
              // console.log('#!!#!-htmlEditorBlur', newContent);
              setEditorText(newContent);
            }}
            // onChange={(newContent) => {
            //   onChangeCallback && onChangeCallback();
            //   // onChange(newContent);
            //   // onBlur();
            // }}
          />
        </Stack>
        <Stack direction="row">
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={() => closeHandler()}
          >
            Schliessen
          </Button>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={() => submitHandler(editorText)}
          >
            Speichern
          </Button>
        </Stack>
      </Stack>
      {/* </Box> */}
    </>
  );
}
