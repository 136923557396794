import { error } from 'console';
import Parse from 'parse';
import useAuthStore from '../../store/authStore';

// !!! typing
export default async function setNewMediaApi(
  parentType: string,
  parentId: string,
  fileName: string,
  eventId: string | undefined,
  sessionId: string | undefined,
) {
  // get user:
  const user = useAuthStore.getState().user;

  return await Parse.Cloud.run(
    'setNewMedia',
    {
      parentType: parentType,
      parentId: parentId,
      fileName: fileName,
      eventId: eventId,
      sessionId: sessionId,
    },
    { sessionToken: user.sessionToken },
  );
}
