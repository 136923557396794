import Default from '-!svg-react-loader!../svg/Default.svg';
import { createSvgIcon } from '@mui/material';

const props = {
  version: '1.1',
  width: '24px',
  height: '24px',
  viewBox: '0 0 512 512',
};

export const DefaultIcon = createSvgIcon(
  <Default {...(props as any)} />,
  'Default',
);
