import Image from '-!svg-react-loader!../svg/Image.svg';
import { createSvgIcon } from '@mui/material';

const props = {
  version: '1.1',
  width: '24px',
  height: '24px',
  viewBox: '0 0 512 512',
};

export const ImageIcon = createSvgIcon(<Image {...(props as any)} />, 'PDF');
