export const ErSlotContentTypesOptions = [
    { label: 'Begrüßung', value: 'welcome' },
    { label: 'Ansprache', value: 'speech' },
    { label: 'Keynote', value: 'keynote' },
    { label: 'Vortrag', value: 'lecture' },
    { label: 'Diskussion', value: 'discussion' },
    { label: 'Summary', value: 'summary' },
    { label: 'Interview', value: 'interview' },
    { label: 'Darbietung', value: 'performance' },
    { label: 'Pitch', value: 'pitch' },
    { label: 'Sonstiges', value: 'miscellaneous' },
  ];