import { QueryClient, QueryKey, useQueryClient } from '@tanstack/react-query';
import Parse from 'parse';
import { useEffect, useMemo, useState } from 'react';
import useLiveUpdateStore from '../store/liveUpdateStore';
import useAuthStore from '../store/authStore';

export type useLiveQueryParamTypes = {
  className?: string; // classNames must be registered for LiveQueries in Parse Server Setup
  queryKeywords?: { queryKey: string[] }[];
  queryParams?: LiveQueryParamTypes;
};

export type useLiveQueryReturnTypes = {
  subscription: Parse.LiveQuerySubscription;
  unsubscribe: () => void;
};

type LiveQueryParamType = {
  //   operation: 'equalTo' | 'notEqualTo' | 'lessThan' | 'greaterThan'; // und so weiter
  fieldName: string;
  value: string | number | boolean | Parse.Object; // je nach Ihrem Modell
};

type LiveQueryParamTypes = {
  [key: string]: LiveQueryParamType[];
};

type createQueriesReturnType = Parse.Query;

export default function useLiveQuery({
  className = 'LiveUpdate',
  queryKeywords,
  queryParams,
}: useLiveQueryParamTypes) {
  // state to save the subscription too
  const [actualSubscription, setActualSubscription] =
    useState<Parse.LiveQuerySubscription | null>(null);

  // create base query (set the className)
  const baseLiveQuery = new Parse.Query(className);
  // container for the updated query
  let updatedLiveQuery: Parse.Query;
  // the parse query query client
  const queryClient = useQueryClient();

  //   check if the query has any constrains
  if (queryParams) {
    // add the query constrains
    updatedLiveQuery = createQueries(baseLiveQuery, queryParams);
  } else {
    // else only add the query
    updatedLiveQuery = baseLiveQuery;
  }

  // use useEffect to init the subscription creation only on first mount
  // !!! CHECK FOR MULTIPLE RERENDERING
  useEffect(() => {
    // wrapper for async subscription handling
    const fetchSubscription = async () => {
      // subscribe to the livequery
      // const res = await updatedLiveQuery.subscribe();
      // const sessionToken = useAuthStore.getState().user.sessionToken;

      const res = useLiveUpdateStore
        .getState()
        .liveQueryClient?.subscribe(updatedLiveQuery);

      // set the event handlers which will trigger the selected react query to be invalidated
      res!.on('create', (object) => {
        invalidateMultipleQueries(queryClient, queryKeywords);
      });

      res!.on('update', (object) => {
        invalidateMultipleQueries(queryClient, queryKeywords);
      });

      res!.on('enter', (object) => {
        invalidateMultipleQueries(queryClient, queryKeywords);
      });

      // save the subscription to state
      setActualSubscription(res!);
    };

    fetchSubscription();

    return () => {
      // unsubscribe from the livequery on unmount
      actualSubscription?.unsubscribe();
    };
  }, []);

  // maybe we want to expose the object here...
  //   return {
  //     subscription: actualSubscription,
  //     unsubscribe: () => actualSubscription?.unsubscribe(),
  //   };
}

// helper to invalidate multiple queries at once
export function invalidateMultipleQueries(
  queryClient: QueryClient,
  queryKeywords?: { queryKey: string[] }[],
) {
  if (queryKeywords && queryKeywords.length > 0) {
    queryKeywords.forEach((queryKeyword) => {
      queryClient.invalidateQueries(queryKeyword);
    });
  } else {
    // if no specific query key is given, invalidate all queries
    queryClient.invalidateQueries();
  }
}

function createQueries(
  baseLiveQuery: Parse.Query,
  queryParams: LiveQueryParamTypes,
): createQueriesReturnType {
  // define the constrain types supported by the live query
  const allowedMethods = ['equalTo', 'notEqualTo', 'lessThan', 'greaterThan'];

  // for ts: define type for dynamic methods to be called on baseLiveQuery
  interface DynamicMethods {
    [key: string]: (fieldName: string, value: any) => void;
  }

  // iterate over all constrain types
  for (const key in queryParams) {
    if (queryParams.hasOwnProperty(key)) {
      // iterate over all the individual constrains of one type
      queryParams[key].forEach((queryParam) => {
        // check if the constrain tye is supported
        if (allowedMethods.includes(key.toString())) {
          // add the constrain to the query
          (baseLiveQuery as unknown as DynamicMethods)[key](
            queryParam.fieldName,
            queryParam.value,
          );
        }
      });
    }
  }

  return baseLiveQuery;
}
