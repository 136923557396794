import * as yup from 'yup';
import i18n from 'i18next';
import { UpdateMediaDataTypes } from './mediaFormTypes';

// !!! Error Messages Missing !!!
export const updateMediaValSchema = yup.object({
  title: yup.string(),
});

export const MediaDefaults: UpdateMediaDataTypes = {
  title: 'Neue Datei',
  // id: undefined,
  // sessionId: undefined,
  // title: '',
  // description: '',
  // type: 'standard',
  // durationPlaned: 0,
  // durationMin: 0,
  // starttimePlanned: undefined,
};
