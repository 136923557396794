/**
 * Create from to date string
 * @param startdate
 * @param enddate
 * @returns string in the form "dd.mm.yyyy - dd.mm.yyyy"
 */
export function displayEventFromToDate(startdate: Date, enddate: Date) {
  // get midnight of start day
  const startDateMidnight = new Date(startdate);
  startDateMidnight.setMilliseconds(999);
  startDateMidnight.setSeconds(59);
  startDateMidnight.setMinutes(59);
  startDateMidnight.setHours(23);

  // set midnight to hour desired endpoint (1:00 next day)
  startDateMidnight.setHours(
    startDateMidnight.getHours() + 1,
    startDateMidnight.getMinutes() + 1,
    0,
    0,
  );

  let dateString = startdate.toLocaleDateString();

  const endDate = new Date(enddate);

  // if (enddate > startDateMidnight) {
  if (startDateMidnight.getTime() < endDate.getTime()) {
    dateString +=
      ' - ' +
      new Date(endDate.setDate(endDate.getDate() - 1)).toLocaleDateString();
  }

  return dateString;
}

/**
 * Function to create a list of all event days between start- & enddate
 * @param eventTimes startDate: Date; enddate: Date
 * @returns array of label / value objects
 */
export function getAllDates(eventTimes: any) {
  const dates = [];
  let currentDate = new Date(eventTimes.startdate);

  let index = 1;
  while (currentDate <= eventTimes.enddate) {
    // dates.push(new Date(currentDate));
    dates.push({
      label: 'Tag ' + index + ' (' + currentDate.toLocaleDateString() + ')',
      value: currentDate.toISOString(),
    });
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    index++;
  }

  return dates;
}

export const combineDateTime = (date: Date, time: Date) => {
  // Kombiniert das Datum mit der Zeit in einem neuen Date-Objekt
  const combined = new Date(date);
  combined.setHours(time.getHours());
  combined.setMinutes(time.getMinutes());
  combined.setSeconds(time.getSeconds());
  return new Date(combined.getTime());
};

export function formatTime(timeFormat: string, time: Date) {
  let formattedTime;
  time = new Date(time);
  const hours = time.getHours();
  const minutes = time.getMinutes();

  switch (timeFormat) {
    case 'longDot':
      formattedTime = `${hours}.${minutes < 10 ? '0' + minutes : minutes} Uhr`;
      break;
    case 'shortColon':
      formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
      break;
    case 'longColon':
      formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes} Uhr`;
      break;
    default:
      formattedTime = `${hours}.${minutes < 10 ? '0' + minutes : minutes}`;
  }

  return formattedTime;
}
