import { QueryStatus } from '@tanstack/react-query';
import { create } from 'zustand';

type State = {
  [key: string]: any;
};

// init empty store
const useSlotsStore = create<State>((set) => ({
  data: null,
  status: 'pending',
  isSuccess: (state: State) => state.status === 'success',
  isError: (state: State) => state.status === 'error',
  isPending: (state: State) => state.status === 'pending',
  setData: (data: any) => set({ data }), // !!! typing
  setStatus: (status: QueryStatus) => set({ status }),
}));

// // populate store
// export function populateAuthStore(data: any) {
//   useEventStore.setState(data);
// }

// export hook
export default useSlotsStore;
