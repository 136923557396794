export const getUserMenuAccess = (section: string) => {
  switch (section) {
    case 'main':
      return [
        { path: 'sessions', active: true, title: 'Sessions', backLink: true },
        // {
        //   path: 'actors',
        //   active: true,
        //   title: 'Akteure',
        //   backLink: false,
        // },
        // { path: 'media', active: true, title: 'Medien-Event', backLink: false },
      ];
    case 'session':
      return [
        {
          path: 'sessions/:sessionId/slots',
          active: true,
          title: 'Ablauf',
          backLink: true,
        },
        {
          path: 'sessions/:sessionId/actors',
          active: true,
          title: 'Akteure',
          backLink: false,
        },
        // {
        //   path: 'sessions/:sessionId/options',
        //   active: true,
        //   title: 'Optionen',
        //   backLink: false,
        // },
        // {
        //   path: 'sessions/:sessionId/media',
        //   active: true,
        //   title: 'Medien-Session',
        //   backLink: false,
        // },
      ];
    default:
      break;
  }
};
