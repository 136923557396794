import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  ListItem,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import { MediaFileUpdateTypes, MediaUpdateTypes } from '../Slots/Slots';
import getFileIcon from '../../util/getFileIcon';
import useUserPermissions from '../Hooks/useUserPermissions';
import { useSlotContext } from '../Slots/Context/SlotContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateMediaValSchema } from './updateMediaValidationSchema';
import { _TextFieldWithSubmit } from '../GeneralUI/FormElements';
import { ErTextFieldWithSubmit } from 'eventrocket-commoncomponents';
import { downloadFile } from '../../util/fileActions';
import { DefaultIcon } from '../GeneralUI/FileIcons/Icons/DefaultIcon';
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import { useFeedback } from '../Feedback/FeedbackContext';
import { mediaIconStateTypes } from '../Slots/SlotListItem';
import getMediaState from '../../util/media/getMediaState';

export type MediaListItemPropsTypes = {
  key: string;
  id: string;
  active: boolean;
  setActiveId: Dispatch<SetStateAction<any>>;
  //   mediaData?: MediaDataTypes;
  mediaData: Parse.Object;
  itemOpen?: string;
  setItemOpen: Dispatch<SetStateAction<any>>;
  // toggleDetailMenu: (id: string) => void;
  deleteVersion: (mediaFileId: string) => void;
  // downloadMedia: (filePath: string) => void;
  deleteMedia: (id: string) => void;
  addNewMediaVersion: (activeMediaId: string | null, files: FileList) => void;
  // updateMedia: (updateData: MediaUpdateTypes) => void;
  updateMediaFile: (updateData: MediaFileUpdateTypes) => void;
  setIsEditingDetailsId: Dispatch<SetStateAction<any>>;
  isEditingDetailsId?: null | string;
  sessionId: string;
  eventId: string;
  newItemId: string | undefined;
  mediaMngActive?: boolean;
  variant?: 'reduced' | 'full';
};

// Textfield
const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-readOnly.MuiInputBase-readOnly:before':
    {
      borderBottom: 'none',
    },
});

// !!!typing
export default forwardRef(function MediaListItem(
  props: MediaListItemPropsTypes,
  ref: any,
) {
  // props
  const {
    id,
    mediaData,
    itemOpen,
    setItemOpen,
    // toggleDetailMenu,
    setActiveId,
    deleteVersion,
    deleteMedia,
    addNewMediaVersion,
    // updateMedia,
    updateMediaFile,
    isEditingDetailsId,
    setIsEditingDetailsId,
    sessionId,
    eventId,
    newItemId,
    mediaMngActive = false,
    active,
    variant = 'full',
  } = props;

  // slot context
  const { updateMedia } = useSlotContext();

  // feedback
  const { setFeedback } = useFeedback();

  // state
  const [open, setOpen] = useState(false);
  const [activeVersion, setActiveVersion] = useState(
    // mediaData.get('files')[0].version,
    mediaData.get('activeVersion') ?? '1',
  );
  const [activeFileType, setActiveFileType] = useState('');

  // media form fields
  const [mediaFields, setMediaFields] = useState<{
    title: string;
  }>({
    title: mediaData.get('title'),
  });

  // handle the foldout state
  const toggleFoldout = () => {
    if (!mediaTitleEditable && !isEditingDetailsId) {
      if (
        (transform && Math.abs(transform.x) < 1 && Math.abs(transform.y) < 1) ||
        transform === null
      )
        setItemOpen((itemId: string) => {
          if (open && itemId === id) {
            setActiveId(null);
            return undefined;
          } else {
            setActiveId(id);
            return id;
          }
        });

      setOpen(!open);
    }
  };

  // the version list
  const [fileStates, setFilesStates] = useState(mediaData.get('files'));

  // version list helpers
  const getCurFileData = () => {
    return fileStates.filter((file: any) => file.version === activeVersion)[0];
  };

  const setCurFileData = (state: boolean) => {
    // get the approved state for the active file
    const isFileApproved = getCurFileData().file.get('approved');

    setFeedback({
      type: 'confirmAction',
      headline: mediaData?.get('title'),
      message: isFileApproved
        ? `Freigabe für Version ${activeVersion} aufheben?`
        : `Version ${activeVersion} freigeben?`,
      callback: () => {
        // only update file approved state after dialog confirm
        // get the version to update & persist it to the server
        const updateVersion = fileStates.map((file: any) => {
          if (file.version === activeVersion) {
            file.file.set('approved', state);
            updateMediaFile({
              mediaFileId: file.file.id,
              data: { approved: state },
            });
          }
          return file;
        });
        // set local ui state
        setFilesStates(updateVersion);
      },
      confirmButtons: {
        cancel: 'Abbrechen',
        confirm: isFileApproved ? 'Aufheben' : 'Freigeben',
      },
    });
  };

  // editable media title
  const [mediaTitle, setMediaTitle] = useState(mediaData?.get('title'));
  const [mediaTitleEditable, setMediaTitleEditable] = useState(false);

  const toggleMediaTitleEditable = () => {
    setMediaTitleEditable(!mediaTitleEditable);
  };

  // theme
  const theme = useTheme();

  // setup sortable
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props.id,
    // disabled: variant === 'full' ? false : true,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // form handling
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    reset,
    formState,
  } = useForm({
    // mode: 'onChange',
    defaultValues: mediaFields,
    resolver: yupResolver(updateMediaValSchema),
  });

  // save fields data
  const saveMediaFields = (mediaFields: any) => {
    updateMedia({ data: { ...mediaFields }, mediaId: id });
  };

  // check if field has changed
  const watchTitle = useWatch({ control, name: 'title' });

  const isFieldEditable = useCallback(
    (fieldName: 'title') => {
      if (getValues(fieldName)?.trim() !== mediaFields[fieldName]?.trim()) {
        return true;
      } else {
        return false;
      }
    },
    // [formState.isDirty, mediaFields, newItemId],
    [watchTitle, mediaFields],
  );

  // hidden input for file upload button
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  //
  const { hasPermission } = useUserPermissions();

  // handle popover
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // const [timeoutRef, setTimeoutRef] = useState<any>();
  // const timeoutRef = useRef<any>(null);

  const popoverOpen = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // file download handling
  const downloadFileHandler = () => {
    // get the filepath
    const activeVersion = mediaData?.get('activeVersion') ?? '1';
    const activeFile = mediaData
      ?.get('files')
      .filter((file: any) => file.version === activeVersion);

    let newFileName = '';

    if (mediaData.get('parents')[0].className === 'Session') {
      newFileName =
        mediaData?.get('parents')?.[0].get('title') +
        '.' +
        mediaData?.get('title') +
        '.v-' +
        mediaData?.get('activeVersion') +
        '.' +
        activeFile?.[0]?.file?.get('type');
    } else {
      newFileName =
        mediaData?.get('parents')?.[0]?.get('session')?.get('title') +
        '.' +
        mediaData?.get('parents')?.[0].get('title') +
        '.' +
        mediaData?.get('title') +
        '.v-' +
        mediaData?.get('activeVersion') +
        '.' +
        activeFile?.[0]?.file?.get('type');
    }

    console.log(
      '###!!!-downloadHandler',
      activeVersion,
      activeFile,
      newFileName,
      activeFile[0].file.get('filePath'),
    );

    downloadFile(activeFile[0].file.get('filePath'), newFileName);
  };

  // handle delete media
  const handleDeleteMediaVersion = () => {
    const activeVersion = mediaData?.get('activeVersion') ?? '1';
    const activeFile = mediaData
      ?.get('files')
      .filter((file: any) => file.version === activeVersion);

    setFeedback({
      type: 'confirmAction',
      headline: mediaData?.get('title'),
      message: `Version ${activeVersion} löschen?`,
      callback: () => deleteVersion(activeFile?.[0]?.file?.id),
      confirmButtons: { cancel: 'Abbrechen', confirm: 'Löschen' },
    });
  };

  // close if another item is opened
  useEffect(() => {
    if (itemOpen !== id) setOpen(false);
  }, [itemOpen]);

  // update the fileVersion list
  useEffect(() => {
    setFilesStates(mediaData.get('files'));
    setActiveVersion(mediaData.get('activeVersion') ?? '1'); // use 1 as fallback

    // set activeFileType
    if (mediaData.get('files') && mediaData.get('files').length > 0) {
      const activeFile = mediaData.get('files').filter((file: any) => {
        return file.version === mediaData.get('activeVersion') ?? '1';
      });
      if (activeFile) setActiveFileType(activeFile?.[0]?.file?.get('type'));
    }

    setMediaFields({ title: mediaData.get('title') });
  }, [mediaData]);

  // persist changes to active version
  useEffect(() => {
    updateMedia({
      mediaId: id,
      data: {
        activeVersion: activeVersion,
      },
    });
  }, [activeVersion]);

  //// get the media state color
  // media icon color
  let mediaIconState: mediaIconStateTypes = {
    mediaColor: theme.palette.warning.main,
    mediaActive: true,
  };

  const slotMedia = [mediaData];

  mediaIconState = getMediaState({ media: slotMedia, theme: theme });

  // const slotMedia = mediaData;

  // // set the default values
  // if (mediaData?.get('files')?.length === 0) {
  //   mediaIconState.mediaColor = theme.palette.action.disabled;
  //   mediaIconState.mediaActive = false;
  // }

  // // check for unapproved newer version
  // let hasUnapprovedNewerVersion = false; // default
  // const approvedFile = mediaData
  //   ?.get('files')
  //   ?.find((file: any) => file.file.get('approved'));

  // if (approvedFile) {
  //   const approvedCreatedAt = new Date(approvedFile?.file?.get('createdAt'));

  //   mediaData?.get('files')?.some((file: any) => {
  //     const fileCreatedAt = new Date(file.file.get('createdAt'));
  //     hasUnapprovedNewerVersion =
  //       !file.file.get('approved') && fileCreatedAt > approvedCreatedAt;
  //   });
  // }

  // let allLatestVersionsApproved = false;

  // if (mediaData?.get('files').length > 0) {
  //   const latestFile = mediaData
  //     ?.get('files')
  //     ?.reduce((latest: any, file: any) => {
  //       return new Date(file.file.get('createdAt')) >
  //         new Date(latest.file.get('createdAt'))
  //         ? file
  //         : latest;
  //     });

  //   allLatestVersionsApproved = latestFile?.file.get('approved');
  // }

  // if (hasUnapprovedNewerVersion)
  //   mediaIconState.mediaColor = theme.palette.info.main;

  // if (allLatestVersionsApproved)
  //   mediaIconState.mediaColor = theme.palette.success.main;

  return (
    <>
      {/* Popover filename */}
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{mediaFields.title}</Typography>
      </Popover>

      {mediaData && (
        <ListItem
          sx={{
            bgcolor: theme.palette.background.default,
            my: '1px',
            mx: '1px',
            p: 1,
            '&:lastChild': { mb: '1px' },
            zIndex: active ? 100 : 0,
          }}
          ref={setNodeRef}
          style={style}
        >
          <form
            onSubmit={handleSubmit(saveMediaFields)}
            noValidate
            style={{ width: '100%' }}
          >
            <Stack width="100%">
              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    width="100%"
                    spacing={1}
                    alignItems="center"
                    // onPointerUp={toggleFoldout}
                    {...attributes}
                  >
                    <Stack
                      onPointerUp={() =>
                        mediaMngActive &&
                        fileStates &&
                        fileStates.length > 0 &&
                        toggleFoldout()
                      }
                      direction="row"
                      spacing={0.5}
                      sx={{ cursor: 'pointer' }}
                      alignItems="center"
                    >
                      {mediaMngActive &&
                        fileStates &&
                        fileStates.length > 0 && (
                          <ArrowForwardIosIcon
                            sx={{
                              fontSize: '1.2rem',
                              opacity: 0.54,
                              transform: open ? 'rotate(90deg)' : 'none',
                              transition: 'transform 200ms',
                            }}
                          />
                        )}

                      {getFileIcon(activeFileType, {
                        // color: 'primary',

                        sx: {
                          fontSize: '1rem',
                          color: mediaIconState.mediaColor,
                        },
                      })}
                    </Stack>

                    <Box
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      onClick={handlePopoverClose}
                      sx={{ width: '100%' }}
                    >
                      <ErTextFieldWithSubmit
                        fieldName="title"
                        fullWidth
                        variant="outlined"
                        size="small"
                        fontSize={theme.typography.body2.fontSize!}
                        fontWeight={'bold'}
                        lineHeight={theme.typography.body2.lineHeight!}
                        control={control}
                        readOnly={
                          !hasPermission({
                            objPath: 'slots.slot.actors',
                            operation: 'update',
                            context: {
                              eventId: eventId,
                              sessionId: sessionId,
                              userId: id,
                            },
                          })
                        }
                        // label="Name"
                        editActive={isFieldEditable('title')}
                        submitForm={() => handleSubmit(saveMediaFields)()}
                      />
                    </Box>
                  </Stack>

                  {hasPermission({
                    objPath: 'slots.slot.media',
                    operation: 'update',
                    context: {
                      eventId: eventId,
                      sessionId: sessionId,
                      userId: mediaData?.get('owner').id,
                    },
                  }) && (
                    <Stack direction="row" alignItems="center">
                      <>
                        {/* <Input type="file" sx={{ display: 'none' }} /> */}
                        {variant === 'full' && (
                          <IconButton size="small" {...listeners}>
                            <DragHandleOutlinedIcon
                              sx={{ fontSize: '1.4rem' }}
                            />
                          </IconButton>
                        )}
                        {variant === 'full' && (
                          <IconButton
                            component="label"
                            size="small"
                            disabled={!mediaMngActive}
                          >
                            <FileUploadIcon sx={{ fontSize: '1.4rem' }} />
                            <VisuallyHiddenInput
                              type="file"
                              onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.target.files &&
                                  addNewMediaVersion(id, e.target.files);
                              }}
                            />
                          </IconButton>
                        )}
                      </>

                      <IconButton size="small" onClick={() => deleteMedia(id)}>
                        <DeleteIcon sx={{ fontSize: '1.4rem' }} />
                      </IconButton>
                    </Stack>
                  )}
                </Stack>
              </Stack>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <Stack direction="row" sx={{ width: '100%', mt: 1 }}>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    {mediaMngActive && fileStates && fileStates.length > 0 && (
                      <ArrowForwardIosIcon
                        sx={{
                          fontSize: '1.2rem',
                          opacity: 0,
                        }}
                      />
                    )}

                    <DefaultIcon sx={{ opacity: 0 }} />
                  </Stack>

                  {fileStates && fileStates.length > 0 && (
                    <Stack
                      // alignItems="flex-end"
                      spacing={1}
                      sx={{ width: '100%' }}
                    >
                      <Stack direction="row" width="100%">
                        <Select
                          value={activeVersion}
                          onChange={(e: SelectChangeEvent) => {
                            setActiveVersion(e.target.value);
                          }}
                          inputProps={{
                            readOnly: !hasPermission({
                              objPath: 'slots.slot.media',
                              operation: 'update',
                              context: {
                                eventId: eventId,
                                sessionId: sessionId,
                                userId: mediaData?.get('owner').id,
                              },
                            }),
                          }}
                          fullWidth
                          size="small"
                          variant="outlined"
                          sx={{
                            fontSize: theme.typography.body2.fontSize,
                            width: '100%',
                          }}
                          renderValue={(value) => {
                            return (
                              <Typography variant="body2">
                                {'Version: ' + value}
                              </Typography>
                            );
                          }}
                        >
                          {fileStates.map(
                            (
                              fileData: { version: string; file: Parse.Object },
                              index: number,
                            ) => {
                              return (
                                <MenuItem
                                  dense
                                  key={
                                    'slot-' +
                                    id +
                                    '-media-file-' +
                                    fileData.version
                                  }
                                  value={fileData.version}
                                  sx={{ py: 0 }}
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                  >
                                    <Typography variant="body2">
                                      {'Version: ' + fileData.version}
                                    </Typography>
                                    {/* <IconButton
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleDeleteMediaVersion(
                                          fileData.file.id,
                                          fileData.version,
                                        );
                                      }}
                                      size="small"
                                    >
                                      <DeleteIcon />
                                    </IconButton> */}
                                  </Stack>
                                </MenuItem>
                              );
                            },
                          )}
                        </Select>

                        <IconButton
                          disabled={
                            !mediaMngActive ||
                            !fileStates ||
                            !(fileStates.length > 0)
                          }
                          onClick={() => {
                            downloadFileHandler();
                          }}
                          size="small"
                        >
                          <DownloadIcon sx={{ fontSize: '1.4rem' }} />
                        </IconButton>

                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDeleteMediaVersion();
                          }}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>

                      {/* <Typography>Upload</Typography> */}
                      {/* <Stack
                        direction="row"
                        width="100%"
                        justifyContent="space-between"
                      > */}
                      <Typography
                        alignSelf="flex-start"
                        variant="body2"
                        // sx={{ mt: '0px !important' }}
                      >
                        {'Upload durch ' +
                          getCurFileData()
                            ?.file?.get('uploadedBy')
                            ?.get('firstname') +
                          ' ' +
                          getCurFileData()
                            ?.file?.get('uploadedBy')
                            ?.get('lastname') +
                          ' am ' +
                          getCurFileData()
                            ?.file?.get('createdAt')
                            ?.toLocaleDateString() +
                          ' um ' +
                          getCurFileData()
                            ?.file?.get('createdAt')
                            ?.toLocaleTimeString('de-DE', {
                              hour: '2-digit',
                              minute: '2-digit',
                            })}
                      </Typography>
                      {/* <Typography
                        alignSelf="flex-start"
                        variant="body2"
                        sx={{ mt: '0px !important' }}
                      >
                        {'Datum: ' +
                          getCurFileData()
                            .file.get('createdAt')
                            .toLocaleDateString() +
                          ' - ' +
                          getCurFileData()
                            .file.get('createdAt')
                            .toLocaleTimeString()}
                      </Typography> */}
                      {/* </Stack> */}

                      <Stack
                        direction="row"
                        width="100%"
                        sx={{ mt: '0px !important' }}
                      >
                        {/* <Checkbox
                          size="small"
                          onClick={(e: any) => {
                            // e.preventDefault();
                            setCurFileData(e.target.checked);
                          }}
                          // onChange={(
                          //   e: ChangeEvent<HTMLInputElement>,
                          // ) => {}}
                          // checked={getCurFileData().file.get('approved')}
                        /> */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              onClick={(e: any) => {
                                // e.preventDefault();
                                setCurFileData(e.target.checked);
                              }}
                              // onChange={(
                              //   e: ChangeEvent<HTMLInputElement>,
                              // ) => {}}
                              checked={getCurFileData()?.file?.get('approved')}
                            />
                          }
                          label="Freigegeben"
                          componentsProps={{ typography: { variant: 'body2' } }}
                          disabled={
                            !hasPermission({
                              objPath: 'slots.slot.media',
                              operation: 'update',
                              context: {
                                eventId: eventId,
                                sessionId: sessionId,
                                userId: mediaData?.get('owner').id,
                              },
                            })
                          }
                        />
                      </Stack>
                    </Stack>
                  )}

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ opacity: 0 }}
                  >
                    <>
                      {/* <Input type="file" sx={{ display: 'none' }} /> */}
                      {variant === 'full' && (
                        <IconButton
                          component="label"
                          size="small"
                          disabled={!mediaMngActive}
                        >
                          <FileUploadIcon sx={{ fontSize: '1.4rem' }} />
                          <VisuallyHiddenInput type="file" />
                        </IconButton>
                      )}
                    </>

                    {variant === 'full' && (
                      <IconButton size="small">
                        <DownloadIcon sx={{ fontSize: '1.4rem' }} />
                      </IconButton>
                    )}

                    <IconButton size="small">
                      <DeleteIcon sx={{ fontSize: '1.4rem' }} />
                    </IconButton>
                  </Stack>
                </Stack>
              </Collapse>
            </Stack>
          </form>
        </ListItem>
      )}
    </>
  );
});
