import { useForm, useWatch } from 'react-hook-form';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
} from '@mui/material';
import { ErTextField } from 'eventrocket-commoncomponents';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';
import { yupResolver } from '@hookform/resolvers/yup';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import EventDetailsFormLayoutLegal from './EventDetailsFormLayoutLegal';
import EventDetailsFormSection from './EventDetailsFormSection';
import EventDetailsFormLayoutBranding from './EventDetailsFormLayoutBranding';
import StyleIcon from '@mui/icons-material/Style';
import {
  eventDetailsCustomizationValidationSchema,
  eventDetailsCustomizationDefaults,
} from './eventDetailsCustomizationValidationSchema';
import EventDetailsFormLayoutOnlineStageDisplay from './EventDetailsFormLayoutOnlineStageDisplay';
import EventDetailsFormLayoutMeta from './EventDetailsFormLayoutMeta';

import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';

type EventDetailsFormLayoutCustomizationPropsTypes = {
  mediaQueryMobile: boolean;
  featureType?: string;
};

export default function EventDetailsFormLayoutCustomization(
  props: EventDetailsFormLayoutCustomizationPropsTypes,
) {
  // props
  const { mediaQueryMobile, featureType } = props;

  // event details context
  const { customizationData, updateCustomizationData } =
    useEventDetailsFormData();

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsCustomizationValidationSchema),
    defaultValues: {
      ...eventDetailsCustomizationDefaults,
      ...customizationData?.data,
    },
  });

  const watch = useWatch({ control });

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  useEffect(() => {
    // save changed form data to context

    if (!firstRun) {
      updateCustomizationData(getValues());
    } else {
      setFirstRun(false);
    }
  }, [watch]);

  return (
    <>
      {/* <Stack sx={{ mr: mediaQueryMobile ? 0 : 4 }}> */}
      <Stack>
        {/* {(!mediaQueryMobile ||
          (mediaQueryMobile && featureType === 'meta')) && ( */}
        {/* <EventDetailsFormSection
          headlineTranslationString="DETAILS-FORM.SECTION.META.HEADLINE"
          sublineTranslationString="DETAILS-FORM.SECTION.META.SUBLINE"
          translationNs="event"
          Icon={ContactPhoneOutlinedIcon}
          FormComponent={<EventDetailsFormLayoutMeta />}
          // mediaQueryMobile={mediaQueryMobile}
          mediaQueryMobile={false}
        /> */}
        {/* )} */}

        {/* {(!mediaQueryMobile ||
          (mediaQueryMobile && featureType === 'branding')) && ( */}
        <EventDetailsFormSection
          headlineTranslationString="DETAILS-FORM.SECTION.BRANDING.HEADLINE"
          sublineTranslationString="DETAILS-FORM.SECTION.BRANDING.SUBLINE"
          translationNs="event"
          Icon={PreviewOutlinedIcon}
          FormComponent={<EventDetailsFormLayoutBranding />}
          // mediaQueryMobile={mediaQueryMobile}
          mediaQueryMobile={false}
        />
        {/* )} */}

        {/* {(!mediaQueryMobile ||
          (mediaQueryMobile && featureType === 'legal')) && ( */}
        <EventDetailsFormSection
          headlineTranslationString="DETAILS-FORM.SECTION.LEGAL.HEADLINE"
          sublineTranslationString="DETAILS-FORM.SECTION.LEGAL.SUBLINE"
          translationNs="event"
          Icon={CopyrightOutlinedIcon}
          FormComponent={<EventDetailsFormLayoutLegal />}
          // mediaQueryMobile={mediaQueryMobile}
          mediaQueryMobile={false}
        />
        {/* )} */}

        {/* {(!mediaQueryMobile ||
          (mediaQueryMobile && featureType === 'onlineStageDisplay')) && ( */}
        <EventDetailsFormSection
          headlineTranslationString="DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.HEADLINE"
          sublineTranslationString="DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.SUBLINE"
          translationNs="event"
          Icon={StyleIcon}
          FormComponent={<EventDetailsFormLayoutOnlineStageDisplay />}
          // mediaQueryMobile={mediaQueryMobile}
          mediaQueryMobile={false}
        />
        {/* )} */}
      </Stack>
    </>
  );

  // return (
  //   <>
  //     {/* <Box height="1280px" width="100%" bgcolor="red"></Box> */}
  //     <Stack sx={{ mt: 2 }}>
  //       <Accordion variant="outlined" sx={{ mb: 2, bgcolor: 'unset' }}>
  //         <AccordionSummary
  //           expandIcon={<ExpandMoreIcon />}
  //           aria-controls="branding-options"
  //           id="branding-options-header"
  //         >
  //           Branding
  //         </AccordionSummary>
  //         <AccordionDetails>
  //           <_FileUpload
  //             fullWidth
  //             control={control}
  //             fieldName="bannerImage"
  //             fieldLabel="Banner Bild"
  //             error={
  //               errors.bannerImgae && getErrorMessage(errors, 'bannerImage')
  //             }
  //             // error={errors?.logoFile && getErrorMessage(errors, 'logoFile')}
  //             setValue={setValue}
  //             defaultValue={getValues!('bannerImage')}
  //             imageResizerSettings={{
  //               maxWidth: 256,
  //               maxHeight: 256,
  //               minWidth: 256,
  //               minHeight: 256,
  //               jpegQuality: 80,
  //             }}
  //             sx={{ mb: 2 }}
  //           />

  //           <_ColorPicker
  //             fullWidth
  //             control={control}
  //             fieldLabel="Primärfarbe"
  //             fieldName="primaryColor"
  //             error={
  //               errors?.primaryColor && getErrorMessage(errors, 'primaryColor')
  //             }
  //             setValue={setValue}
  //             sx={{ mb: 2 }}
  //           />

  //           <_ColorPicker
  //             fullWidth
  //             control={control}
  //             fieldLabel="Sekundärfarbe"
  //             fieldName="secondaryColor"
  //             error={
  //               errors?.secondaryColor &&
  //               getErrorMessage(errors, 'secondaryColor')
  //             }
  //             setValue={setValue}
  //             sx={{ mb: 2 }}
  //           />

  //           <_ColorPicker
  //             fullWidth
  //             control={control}
  //             fieldLabel="Hintergrundfarbe"
  //             fieldName="backgroundColor"
  //             error={
  //               errors?.backgroundColor &&
  //               getErrorMessage(errors, 'backgroundColor')
  //             }
  //             setValue={setValue}
  //             sx={{ mb: 2 }}
  //           />
  //         </AccordionDetails>
  //       </Accordion>

  //       <Accordion variant="outlined" sx={{ mb: 2, bgcolor: 'unset' }}>
  //         <AccordionSummary
  //           expandIcon={<ExpandMoreIcon />}
  //           aria-controls="legal-options"
  //           id="legal-options-header"
  //         >
  //           Rechtliches
  //         </AccordionSummary>
  //         <AccordionDetails>
  //           <ErTextField
  //             fullWidth
  //             size="small"
  //             control={control}
  //             fieldLabel="Datenschutz"
  //             fieldName="legalText"
  //             sx={{ mb: 2 }}
  //             error={errors.legalText && getErrorMessage(errors, 'legalText')}
  //           />

  //           <ErTextField
  //             fullWidth
  //             size="small"
  //             control={control}
  //             fieldLabel="Impressum"
  //             fieldName="imprintText"
  //             sx={{ mb: 2 }}
  //             error={
  //               errors.imprintText && getErrorMessage(errors, 'imprintText')
  //             }
  //           />

  //           <ErTextField
  //             fullWidth
  //             size="small"
  //             control={control}
  //             fieldLabel="Cookies"
  //             fieldName="cookiesText"
  //             sx={{ mb: 2 }}
  //             error={
  //               errors.cookiesText && getErrorMessage(errors, 'cookiesText')
  //             }
  //           />

  //           <ErTextField
  //             fullWidth
  //             size="small"
  //             control={control}
  //             fieldLabel="Kontakt"
  //             fieldName="contactText"
  //             sx={{ mb: 2 }}
  //             error={
  //               errors.contactText && getErrorMessage(errors, 'contactText')
  //             }
  //           />
  //         </AccordionDetails>
  //       </Accordion>
  //     </Stack>
  //   </>
  // );
}
