import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Stack,
  Box,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';
import {
  ErDatePicker,
  ErDateTimePicker,
  ErHtmlEditor,
  ErSelect,
  ErSwitch,
  ErTextField,
  ErTimePickerDesktop,
} from 'eventrocket-commoncomponents';
import {
  eventDetailsFeatureOnlineStageValidationSchema,
  eventDetailsFeatureOnlineStageDefaults,
} from './eventDetailsFeatureOnlineStageValidationSchema';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { htmlEditorDefaultConfig } from '../../../util/htmlEditor/htmlEditorDefaultConfig';

type EventDetailsFormLayoutFeaturesOnlineStagePropsTypes = {
  featureState?: boolean;
  toggleOnlineStageActive: () => void;
};
export default function EventDetailsFormLayoutFeaturesOnlineStage(
  props: EventDetailsFormLayoutFeaturesOnlineStagePropsTypes,
) {
  // props
  const { featureState = false, toggleOnlineStageActive } = props;

  // event details context
  const {
    onlineData,
    updateOnlineData,
    basicData,
    registrationData,
    origEventData,
  } = useEventDetailsFormData();

  const openingTimes = [];

  for (let index = 0; index < basicData.data.duration; index++) {
    const openDate = new Date(basicData.data.startdate);
    openDate.setDate(openDate.getDate() + index);

    openingTimes.push({ date: openDate, starttime: null });
  }

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsFeatureOnlineStageValidationSchema),
    defaultValues: {
      ...eventDetailsFeatureOnlineStageDefaults,
      ...onlineData?.data,
    },
  });

  const watch = useWatch({ control });
  const watchEmbed = useWatch({ name: 'embed', control });

  const watchAAutoStartEvent = useWatch({ name: 'autoStartEvent', control });

  // media query
  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  // state
  const [embedActive, setEmbedActive] = useState(false);

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  // useEffect(() => {},[])

  useEffect(() => {
    // save changed form data to context
    if (!firstRun) {
      updateOnlineData(getValues(), featureState ? featureState : false);
    } else {
      setFirstRun(false);
    }
  }, [watch, featureState]);

  useEffect(() => {
    setEmbedActive(getValues('embed'));
  }, [watchEmbed]);

  useEffect(() => {
    if (registrationData?.active && !featureState) {
      updateOnlineData(getValues(), true);
      toggleOnlineStageActive();
    }
  }, [registrationData?.active]);

  return (
    <Stack sx={{ mt: 2 }}>
      <Accordion disabled={!featureState}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="basic-options"
          id="basic-options-header"
        >
          Basic Settings
        </AccordionSummary>
        <AccordionDetails>
          <ErTextField
            disabled={!featureState}
            fullWidth
            size="small"
            control={control}
            fieldLabel="Zugangs Code"
            fieldName="accessCode"
            sx={{ mb: 2 }}
            error={errors.accessCode && getErrorMessage(errors, 'accessCode')}
          />

          {basicData.data.multiSession && (
            <>
              <ErSwitch
                disabled={!featureState}
                control={control}
                fieldLabel="Automatischer Einlass"
                labelPos="end"
                fieldName="autoStartEvent"
                size="small"
              />

              <Collapse in={watchAAutoStartEvent}>
                <Box sx={{ mt: 2 }}>
                  {openingTimes.map((dayTime, index) => (
                    <>
                      <Stack
                        direction="row"
                        key={'days-' + index}
                        spacing={1}
                        alignItems="center"
                        sx={{ mb: 1 }}
                      >
                        <Typography variant="body1">
                          {'Öffnungszeit am ' +
                            dayTime.date.toLocaleDateString() +
                            ':'}
                        </Typography>
                        <ErTimePickerDesktop
                          fullWidth
                          disabled={!featureState}
                          control={control}
                          fieldName={`openingTimes[${index}].starttime`}
                          size="small"
                        />
                      </Stack>
                    </>
                  ))}
                </Box>
              </Collapse>
            </>
          )}
        </AccordionDetails>
      </Accordion>

      {/* {origEventData.multiSession && (
        <Accordion disabled={!featureState}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="basic-options"
            id="basic-options-header"
          >
            Lobby
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: 'grid',
                columnGap: '10px',
                gridTemplateColumns: 'repeat(auto-fill, minmax(150px, auto));',
              }}
            >
              <ErSwitch
                disabled={!featureState}
                control={control}
                fieldLabel="Event-Text"
                labelPos="end"
                fieldName="showEventText"
                size="small"
              />
              <ErSwitch
                disabled={!featureState}
                control={control}
                fieldLabel="Agenda"
                labelPos="end"
                fieldName="showAgenda"
                size="small"
              />
            </Box>
            

            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldLabel="Zusatztext"
              labelPos="end"
              fieldName="showLobbyText"
              size="small"
              sx={{ mb: 1 }}
            />

            <ErHtmlEditor
              fullWidth
              control={control}
              editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
              // fieldLabel="Lobby Text"
              fieldName="playerText"
              sx={{ mb: 2 }}
              error={errors.playerText && getErrorMessage(errors, 'playerText')}
              onChangeCallback={() =>
                onlineData &&
                !onlineData?.isDirty &&
                updateOnlineData({}, onlineData.active, true)
              }
              language="de"
            />
          </AccordionDetails>
        </Accordion>
      )} */}

      {/* <Accordion disabled={!featureState}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="basic-options"
          id="basic-options-header"
        >
          Verabschiedung
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: 'grid',
              columnGap: '10px',
              gridTemplateColumns: 'repeat(auto-fill, minmax(150px, auto));',
            }}
          >
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldLabel="Event-Text"
              labelPos="end"
              fieldName="showEventTextFarewell"
              size="small"
            />
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldLabel="Agenda"
              labelPos="end"
              fieldName="showAgendaFarewell"
              size="small"
            />
          </Box>
          

          <ErSwitch
            disabled={!featureState}
            control={control}
            fieldLabel="Zusatztext"
            labelPos="end"
            fieldName="showFarewellText"
            size="small"
            sx={{ mb: 1 }}
          />

          <ErHtmlEditor
            fullWidth
            control={control}
            editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
            // fieldLabel="Verabschiedungs Text"
            fieldName="farewellText"
            sx={{ mb: 2 }}
            error={
              errors.farewellText && getErrorMessage(errors, 'farewellText')
            }
            onChangeCallback={() =>
              onlineData &&
              !onlineData?.isDirty &&
              updateOnlineData({}, onlineData.active, true)
            }
            language="de"
          />
        </AccordionDetails>
      </Accordion> */}
    </Stack>
  );
}
