import { useEffect, useState } from 'react';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import useEventDetailsFormDataHandling from './useEventDetailsFormDataHandling';
import EventDetailsFormLayoutCustomization from './EventDetailsFormLayoutCustomization';
import EventDetailsFormLayoutFeatures from './EventDetailsFormLayoutFeatures';
import { useTranslation } from 'react-i18next';
import EventDetailsFormLayoutGeneral from './EventDetailsFormLayoutGeneral';
import EventDetailsFormLayoutAccess from './EventDetailsFormLayoutAccess';
import EventDetailsFormSectionMenu from './EventDetailsFormSectionMenu';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import StyleIcon from '@mui/icons-material/Style';
import EventDetailsFormLayoutMarketing from './EventDetailsFormLayoutMarketing';
import TuneIcon from '@mui/icons-material/Tune';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function EventDetailsFormLayout() {
  // props

  //// state

  // toggle the display of the form container
  const [displayForm, setDisplayForm] = useState(false);

  // theme media queries
  const theme = useTheme();

  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  // translation
  const { t, i18n } = useTranslation('event');

  const formHeadline: { [key: string]: string } = {
    main: 'Einstellungen',
    general: '',
    basics: t(
      'DETAILS-FORM.SECTION.BASIC.HEADLINE',
      '_DETAILS-FORM.SECTION.BASIC.HEADLINE',
    ),
    meta: t(
      'DETAILS-FORM.SECTION.META.HEADLINE',
      '_DETAILS-FORM.SECTION.META.HEADLINE',
    ),
    access: t(
      'DETAILS-FORM.SECTION.ACCESS.HEADLINE',
      '_DETAILS-FORM.SECTION.ACCESS.HEADLINE',
    ),
    customization: '',
    branding: t(
      'DETAILS-FORM.SECTION.BRANDING.HEADLINE',
      '_DETAILS-FORM.SECTION.BRANDING.HEADLINE',
    ),
    legal: t(
      'DETAILS-FORM.SECTION.LEGAL.HEADLINE',
      '_DETAILS-FORM.SECTION.LEGAL.HEADLINE',
    ),
    onlineStageDisplay: t(
      'DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.HEADLINE',
      '_DETAILS-FORM.SECTION.ONLINESTAGEDISPLAY.HEADLINE',
    ),
    features: '',
    mediaMng: t(
      'DETAILS-FORM.SECTION.MEDIAMNG.HEADLINE',
      '_DETAILS-FORM.SECTION.MEDIAMNG.HEADLINE',
    ),
    monet: t(
      'DETAILS-FORM.SECTION.MONET.HEADLINE',
      '_DETAILS-FORM.SECTION.MONET.HEADLINE',
    ),
    register: t(
      'DETAILS-FORM.SECTION.REGISTRATION.HEADLINE',
      '_DETAILS-FORM.SECTION.REGISTRATION.HEADLINE',
    ),
    online: t(
      'DETAILS-FORM.SECTION.ONLINE.HEADLINE',
      '_DETAILS-FORM.SECTION.ONLINE.HEADLINE',
    ),
  };

  // form data
  const {
    eventId,
    basicData,
    monetData,
    eventSettingsIsDirty,
    handleCancel,
    activateSection,
  } = useEventDetailsFormData();

  const { handleSubmit } = useEventDetailsFormDataHandling();

  // active form
  const [activeForm, setActiveForm] = useState(
    activateSection ? activateSection : 'basics',
  );

  //// functions
  // menu action handler
  const changeMenu = async (menuId: string) => {
    setActiveForm(menuId);
    setDisplayForm(true);
  };

  // manually blur input fields
  // mainly needed for jodit editor data submit behaviour
  const handleSubmitButtonMouseDown = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    // Get the active element, which should be the input field
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement && activeElement.tagName === 'INPUT') {
      activeElement.blur();
    }
  };

  //// side effects
  useEffect(() => {
    // if screensize changed to mobile reset the menu open flag
    if (mediaQuery) {
      setDisplayForm(false);
    }
  }, [mediaQuery]);

  useEffect(() => {
    if (
      activeForm === 'mediaMng' ||
      activeForm === 'mont' ||
      activeForm === 'register' ||
      activeForm === 'monet'
    ) {
      changeMenu(activeForm);
    }
  }, [activeForm]);

  return (
    <>
      <Stack
        direction="row"
        sx={{
          width: { xs: '200vw', sm: '100%' },
          height: { xs: '100%', sm: '80vh' },
          maxHeight: { xs: '100vh', sm: '750px' },
          position: { xs: 'absolute', sm: 'unset' },
          transform:
            mediaQuery && displayForm ? 'translateX(-100vw)' : 'translateX(0)',
          transition: theme.transitions.create(['transform', 'transform'], {
            duration: theme.transitions.duration.standard,
          }),
        }}
      >
        {/* Menu */}
        <Box
          sx={{
            width: { xs: '100vw', sm: '250px' },
            // px: '10px',
            flex: { xs: '0 0 100vw', sm: '0 0 250px' },
            overflow: 'auto',
            backgroundColor: theme.palette.navigationBgLight.main,
            borderRight: `1px solid ${theme.palette.navigationBg.main}`,
          }}
        >
          {/* Header */}
          <Box
            display="flex"
            width="100%"
            height={mediaQuery ? '44px' : '60px'}
            alignItems="center"
            position={mediaQuery ? 'fixed' : 'static'}
            sx={{ backgroundColor: theme.palette.navigationBg.main }}
          >
            {/* Desktop */}
            {!mediaQuery && (
              <Stack
                direction="row"
                alignItems="center"
                sx={{ maxWidth: '100%' }}
              >
                <IconButton color="primary" onClick={handleCancel}>
                  <ArrowBackIosNewIcon />
                </IconButton>

                <Stack sx={{ maxWidth: 'calc(100% - 40px)', pr: 2 }}>
                  <Typography
                    variant="InfoTextBold"
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {basicData.data.title}
                  </Typography>
                  <Typography
                    variant="InfoText"
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {t('DETAILS-FORM.HEADLINE', '_DETAILS-FORM.HEADLINE')}
                  </Typography>
                </Stack>
              </Stack>
            )}

            {/* Mobile */}
            {mediaQuery && (
              <Stack
                sx={{ width: '50%', pr: '5px', height: '100%' }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                bgcolor={theme.palette.navigationBg.main}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ maxWidth: 'calc(100% - 94px)' }}
                >
                  <IconButton
                    size="large"
                    onClick={handleCancel}
                    color="primary"
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                  <Stack sx={{ maxWidth: 'calc(100% - 40px)', pr: 2 }}>
                    <Typography
                      variant="InfoTextBold"
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {basicData.data.title}
                      {/* {basicData.data.title +
                        'hdhasj dhasdjks das djha sdj asjdkh asjkdh asdh asjk dhajkdh askjdhajksdh asjdkad kas'} */}
                    </Typography>
                    <Typography
                      variant="InfoText"
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {t('DETAILS-FORM.HEADLINE', '_DETAILS-FORM.HEADLINE')}
                    </Typography>
                  </Stack>
                </Stack>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!eventSettingsIsDirty}
                >
                  {t('DETAILS-FORM.SAVE_button', '_DETAILS-FORM.SAVE_button')}
                </Button>
              </Stack>
            )}
          </Box>

          {/*  */}
          <Stack sx={{ p: 2 }}>
            <Typography
              variant="logo"
              sx={{
                // fontSize: { xs: '0.875rem', sm: '1.125rem' },
                fontSize: '1.125rem',
                mt: mediaQuery ? '44px' : 0,
              }}
              color="primary"
            >
              Britestage
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2">Smart Plan</Typography>
              <Button
                size="small"
                variant="outlined"
                sx={{ px: '4px', py: 0, fontSize: '0.6875rem' }}
              >
                Upgrade
              </Button>
            </Stack>
          </Stack>

          {/* Buttons */}
          {/* <Stack sx={{ px: 1, py: 1.5, mt: mediaQuery ? '44px' : 0 }}> */}
          <Stack sx={{ px: 1, py: 1.5 }}>
            {/* GENERAL */}
            <Box>
              {mediaQuery && (
                <>
                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.BASIC.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.BASIC.SUBLINE'
                    }
                    Icon={SettingsOutlinedIcon}
                    clickHandler={() => changeMenu('basics')}
                  />

                  <Divider />

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.ACCESS.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.ACCESS.SUBLINE'
                    }
                    Icon={PeopleOutlineOutlinedIcon}
                    clickHandler={() => changeMenu('access')}
                  />

                  <Divider />
                </>
              )}

              {!mediaQuery && (
                <>
                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.BASIC.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.BASIC.SUBLINE'
                    }
                    Icon={SettingsOutlinedIcon}
                    clickHandler={() => changeMenu('basics')}
                    activeForm={activeForm === 'basics'}
                  />

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.ACCESS.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.ACCESS.SUBLINE'
                    }
                    Icon={PeopleOutlineOutlinedIcon}
                    clickHandler={() => changeMenu('access')}
                    activeForm={activeForm === 'access'}
                  />
                </>
              )}
            </Box>

            {/* CUSTOMIZATION */}
            {/* <Box sx={{ mt: mediaQuery ? 1 : 0 }}> */}
            <Box sx={{ mt: 0 }}>
              {mediaQuery && (
                <>
                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.MENU.CUSTOMIZATION_button'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.CUSTOMIZATION.SUBLINE'
                    }
                    Icon={TuneIcon}
                    clickHandler={() => changeMenu('customization')}
                    activeForm={activeForm === 'customization'}
                  />
                </>
              )}

              {!mediaQuery && (
                <>
                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.MENU.CUSTOMIZATION_button'
                    }
                    sublineTranslationString={''}
                    Icon={TuneIcon}
                    clickHandler={() => changeMenu('customization')}
                    activeForm={activeForm === 'customization'}
                  />
                </>
              )}
            </Box>

            {/* FEATURES */}
            <Box sx={{ mt: mediaQuery ? 1 : 0 }}>
              {mediaQuery && (
                <>
                  <Typography variant="subtitle1" color="primary" padding={1}>
                    {t(
                      'DETAILS-FORM.SECTION.FEATURES.HEADLINE',
                      '_DETAILS-FORM.SECTION.FEATURES.HEADLINE',
                    )}
                  </Typography>

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.MEDIAMNG.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.MEDIAMNG.SUBLINE'
                    }
                    Icon={StarsOutlinedIcon}
                    clickHandler={() => changeMenu('mediaMng')}
                    PaymentIcon={<CheckCircleOutlineOutlinedIcon />}
                  />

                  <Divider />

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.MONET.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.MONET.SUBLINE'
                    }
                    Icon={MonetizationOnOutlinedIcon}
                    clickHandler={() => changeMenu('monet')}
                    PaymentIcon={<CheckCircleOutlineOutlinedIcon />}
                  />

                  <Divider />

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.REGISTRATION.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.REGISTRATION.SUBLINE'
                    }
                    Icon={HowToRegOutlinedIcon}
                    clickHandler={() => changeMenu('register')}
                    PaymentIcon={<CheckCircleOutlineOutlinedIcon />}
                  />

                  <Divider />

                  <EventDetailsFormSectionMenu
                    headlineTranslationString={
                      'DETAILS-FORM.SECTION.ONLINE.HEADLINE'
                    }
                    sublineTranslationString={
                      'DETAILS-FORM.SECTION.ONLINE.SUBLINE'
                    }
                    Icon={SmartDisplayOutlinedIcon}
                    clickHandler={() => changeMenu('online')}
                    PaymentIcon={<CheckCircleOutlineOutlinedIcon />}
                  />
                </>
              )}

              {!mediaQuery && (
                <>
                  <Typography variant="h6" padding={1} pt={2}>
                    {t(
                      'DETAILS-FORM.SECTION.FEATURES.HEADLINE',
                      '_DETAILS-FORM.SECTION.FEATURES.HEADLINE',
                    )}
                  </Typography>

                  <Button
                    fullWidth
                    variant="text"
                    size="small"
                    sx={{
                      justifyContent: 'space-between',
                      color:
                        activeForm === 'features'
                          ? theme.palette.primary.main
                          : theme.palette.text.primary,
                    }}
                    endIcon={mediaQuery && <ArrowForwardIosIcon />}
                    onClick={() => changeMenu('features')}
                  >
                    {t(
                      'DETAILS-FORM.MENU.FEATURES_button',
                      '_DETAILS-FORM.MENU.FEATURES_button',
                    )}
                  </Button>
                </>
              )}
            </Box>
          </Stack>
        </Box>

        {/* Content / forms */}
        <Box
          sx={{
            width: { xs: '100vw', sm: 'auto' },
            // px: '10px',
            position: 'relative',
            flex: { xs: '0 0 100vw', sm: '0 0 auto' },
            display: 'flex',
            flexDirection: 'column',
            flexGrow: { xs: 'unset', sm: 1 },
          }}
        >
          {/* Header */}
          <Box
            display="flex"
            flexShrink={0}
            width="100%"
            height={mediaQuery ? '44px' : '60px'}
            alignItems="center"
            justifyContent="flex-end"
          >
            {/* {!mediaQuery && (
              <IconButton
                size="small"
                onClick={handleCancel}
                sx={{ position: 'absolute', p: 0, top: '10px', right: '10px' }}
              >
                <CloseOutlinedIcon />
              </IconButton>
            )} */}

            {mediaQuery && (
              <Stack
                sx={{ width: '100%', pr: '5px', height: '100%' }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                bgcolor={theme.palette.navigationBg.main}
              >
                <Stack direction="row" alignItems="center">
                  <IconButton
                    size="large"
                    onClick={() => {
                      setDisplayForm(false);
                    }}
                    sx={{ p: 0, width: '40px', height: '40px' }}
                    color="primary"
                  >
                    <KeyboardArrowLeftOutlinedIcon
                      sx={{ width: '40px', height: '40px' }}
                    />
                  </IconButton>

                  <Typography variant="InfoTextBold">
                    {formHeadline[activeForm]}
                  </Typography>
                </Stack>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!eventSettingsIsDirty}
                >
                  {t('DETAILS-FORM.SAVE_button', '_DETAILS-FORM.SAVE_button')}
                </Button>
              </Stack>
            )}
          </Box>

          {/* Content */}
          <Box
            width="100%"
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              py: 1.5,
              px: 2,
            }}
          >
            {(activeForm === 'general' || activeForm === 'basics') && (
              <EventDetailsFormLayoutGeneral
                mediaQueryMobile={mediaQuery}
                featureType={activeForm}
              />
            )}

            {/* {activeForm === 'access' && <StaffList role="eventManager" />} */}
            {activeForm === 'access' && (
              <EventDetailsFormLayoutAccess mediaQueryMobile={mediaQuery} />
            )}

            {(activeForm === 'customization' ||
              activeForm === 'branding' ||
              activeForm === 'legal' ||
              activeForm === 'onlineStageDisplay' ||
              activeForm === 'meta') && (
              <EventDetailsFormLayoutCustomization
                mediaQueryMobile={mediaQuery}
                featureType={activeForm}
              />
            )}

            {(activeForm === 'marketing' || activeForm === 'marketingText') && (
              <EventDetailsFormLayoutMarketing
                mediaQueryMobile={mediaQuery}
                featureType={activeForm}
              />
            )}

            {(activeForm === 'features' ||
              activeForm === 'mediaMng' ||
              activeForm === 'monet' ||
              activeForm === 'register' ||
              activeForm === 'online') && (
              <EventDetailsFormLayoutFeatures
                mediaQueryMobile={mediaQuery}
                featureType={activeForm}
              />
            )}
          </Box>

          {/* Desktop Footer */}
          {!mediaQuery && (
            <Box
              width="100%"
              display="flex"
              flexShrink={0}
              justifyContent="end"
              sx={{ mb: 2, pr: 1 }}
              height="60px"
              alignItems="center"
            >
              <Button
                size="small"
                variant="contained"
                onMouseDown={handleSubmitButtonMouseDown}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  handleSubmit();
                }}
                disabled={!eventSettingsIsDirty}
              >
                {t('DETAILS-FORM.SAVE_button', '_DETAILS-FORM.SAVE_button')}
              </Button>
            </Box>
          )}
        </Box>
      </Stack>
    </>
  );
}
