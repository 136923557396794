import { ReactElement } from "react";
import { EventCelebrationIcon, EventCeremonyIcon, EventConferenceIcon, EventMeetingIcon, EventSymposiumIcon, EventTrainingIcon } from "src/components/ErIcons";

export type eventTypes = 'training' | 'symposium' | 'meeting' | 'conference' | 'ceremony' | 'celebration' | string;
export type eventTypeOptions = {label: string; value: eventTypes, icon: any}[];

export const ErEventTypeOptions: eventTypeOptions = [
  { label: 'Seminar / Schulung', value: 'training', icon: EventTrainingIcon},
  { label: 'Symposium', value: 'symposium', icon:  EventSymposiumIcon},
  { label: 'Tagung / Meeting', value: 'meeting', icon:  EventMeetingIcon},
  { label: 'Konferenz', value: 'conference', icon:  EventConferenceIcon},
  { label: 'Preisverleihung', value: 'ceremony', icon: EventCeremonyIcon},
  { label: 'Feier / Festakt', value: 'celebration', icon: EventCelebrationIcon},
];
