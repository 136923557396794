import { PDFIcon } from '../Components/GeneralUI/FileIcons';
import { PPTIcon } from '../Components/GeneralUI/FileIcons/Icons/PPTIcon';
import { DefaultIcon } from '../Components/GeneralUI/FileIcons/Icons/DefaultIcon';
import { VideoIcon } from '../Components/GeneralUI/FileIcons/Icons/VideoIcon';
import { ImageIcon } from '../Components/GeneralUI/FileIcons/Icons/ImageIcon';
import { IconOwnProps, IconProps } from '@mui/material';

export default function getFileIcon(type: string, props: IconOwnProps) {
  let icon;

  switch (type) {
    case 'png':
    case 'gif':
    case 'jpeg':
    case 'jpg':
      icon = <ImageIcon {...props} />;
      break;
    case 'pdf':
      icon = <PDFIcon {...props} />;
      break;
    case 'ppt':
      icon = <PPTIcon {...props} />;
      break;
    default:
      icon = <DefaultIcon {...props} />;
  }

  return icon;
}
