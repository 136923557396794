import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default function deleteMediaVersionApi(
  mediaId: string | null,
  mediafileId: string,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'deleteMediaVersion',
    {
      mediaId: mediaId,
      mediafileId: mediafileId,
    },
    { sessionToken: sessionToken },
  );
}
