import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export type UpdateActorOrderApiParamsTypes = {
  parentClass: string;
  parentId: string;
  actorList: string[];
  sessionId: string | undefined;
  eventId: string | undefined;
};

export default function updateActorOrderApi(
  data: UpdateActorOrderApiParamsTypes,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run('updateActorOrder', data, {
    sessionToken: sessionToken,
  });
}
