import Parse from 'parse';
import { UpdateSlotDataTypes } from '../../Components/Slots/Form/slotDetailsFormTypes';
import useAuthStore from '../../store/authStore';

// !!! typings
export default async function updateSlotApi(data: any) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  // remove parse objects before sending
  delete data.actors;
  delete data.media;

  return Parse.Cloud.run(
    'updateSlot',
    { slotData: data },
    { sessionToken: sessionToken },
  )
    .then((res) => res)
    .catch((e) => {
      throw new Error(e);
    });
}
