import Parse from 'parse';
import useAuthStore from '../../store/authStore';

type agendaType = 'preview' | 'marketing' | 'live';

export default async function updateSessionAgendaApi(
  agendaData: any,
  agendaTypes: agendaType | agendaType[],
  updateProgramOnly?: boolean,
) {
  // get the user form global store
  const user = useAuthStore.getState().user;

  return await Parse.Cloud.run(
    'updateSessionAgenda',
    {
      agendaData: agendaData,
      agendaTypes: agendaTypes,
      updateProgramOnly: updateProgramOnly,
    },
    { sessionToken: user.sessionToken },
  );
}
