import { Outlet, useParams } from 'react-router-dom';
import SubNavigationMain, {
  SubNavigationParamsTypes,
} from '../Navigation/SubNavigationMain';
import routesConfig from '../../Router/routesConfig';
import { Box, Container, Skeleton, Stack, Toolbar } from '@mui/material';
import useEventStore from '../../store/eventStore';
import { Suspense, useEffect, useState } from 'react';
import { SlotProvider } from '../Slots/Context/SlotContext';
import useSessionQueryHook from '../../data/session/session.query';
import useSessionStore from '../../store/sessionStore';
import useLiveQuery from '../../Hooks/useLiveQuery';
import useSlotsQueryHook from '../../data/slots/slots.query';
import useSlotsStore from '../../store/slotsStore';
import { useActorsQuery } from '../../Router/routes/actor';

export default function SubLayoutMain({ menu }: SubNavigationParamsTypes) {
  // global state data
  const { data: eventData, status: eventDataStatus } = useEventStore();

  // navigation
  // const { sessionId, masterEventId, eventId } = useParams();

  // console.log('##!!-sessionId', sessionId);

  // //// initial data queries
  // // get session details
  // useSessionQueryHook(sessionId!);
  // const { data: sessionData, status: statusSession } = useSessionStore();

  // // opt in for live query for session
  // // need also to invalidate slots for slotlist update to work
  // useLiveQuery({
  //   queryKeywords: [
  //     { queryKey: ['session', 'details', sessionId!] },
  //     { queryKey: ['slots', 'list', sessionId!] },
  //   ],
  //   queryParams: {
  //     equalTo: [
  //       { fieldName: 'updatedClass', value: 'Session' },
  //       { fieldName: 'updatedClassId', value: sessionId! },
  //     ],
  //   },
  // });

  // // get slot list
  // useSlotsQueryHook(sessionId!);
  // const { data: slotsData, status: statusSlots } = useSlotsStore();
  // console.log('##!!-slotsData', slotsData, statusSlots);

  // // opt in for live query for slots
  // useLiveQuery({
  //   queryKeywords: [{ queryKey: ['slots', 'list', sessionId!] }],
  //   queryParams: {
  //     equalTo: [
  //       { fieldName: 'updatedClass', value: 'Slot' },
  //       { fieldName: 'updatedSessionId', value: sessionId! },
  //     ],
  //   },
  // });

  // // get session actors
  // const {
  //   data: actorsSession,
  //   status: statusActorsSession,
  // }: { data: any; status: any } = useActorsQuery(eventId!);

  const [displayContent, setDisplayContent] = useState(false);

  //// side effects
  useEffect(() => {
    // make sure session list is not displayed for singleSession Events
    // !!! not nice should be handled earlier
    if (
      eventData.get('multiSession') ||
      (!eventData.get('multiSession') && menu === 'session')
    ) {
      setDisplayContent(true);
    } else {
      setDisplayContent(false);
    }
  }, [menu, eventData]);

  return (
    <>
      {displayContent && (
        <>
          <SubNavigationMain menu={menu} />
          {/* <SlotListDummy /> */}
          {/* {sessionId ? (
            <>
              {statusSlots === 'success' && statusSession === 'success' && (
                <Suspense fallback={<SlotListDummy />}>
                  <SlotProvider
                    session={sessionData}
                    slots={slotsData}
                    actorsSession={actorsSession}
                    eventId={eventId!}
                    masterEventId={masterEventId!}
                    sessionId={sessionId!}
                  >
                    <Outlet />
                  </SlotProvider>
                </Suspense>
              )}
            </>
          ) : (
            <Outlet />
          )} */}
          <Outlet />
        </>
      )}
    </>
  );
}

function SlotListDummy() {
  return (
    <>
      <Toolbar variant="dense" />
      <Container sx={{ height: 'calc(100% - 64px)' }}>
        <Stack direction="row" spacing={2} height="100%" p={4}>
          {/* <Box width="40%" height="100%"> */}
          <Skeleton variant="rounded" width="40%" height="100%" />
          {/* </Box> */}
          {/* <Box width="60%" height="100%"> */}
          <Skeleton variant="rounded" width="60%" height="100%" />
          {/* </Box> */}
        </Stack>
      </Container>
    </>
  );
}
