import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export type UpdateSSOUserApiPropsTypes = {
  userId: string;
  originalUser: Parse.User;
  data: { [key: string]: any };
};

export default async function updateSSOUserApi(
  props: UpdateSSOUserApiPropsTypes,
) {
  //   const { userId, originalUser, data } = props;
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'updateSSOUser',
    { ...props },
    { sessionToken: sessionToken },
  );
}
