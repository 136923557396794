import React from 'react';
import { FormControl, SxProps } from '@mui/material';
import { LocalizationProvider, DesktopTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { de, enGB, enUS, fr } from 'date-fns/locale';

type ErTimePickerDesktopTypes<T extends FieldValues> = {
  fullWidth?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  fieldName: Path<T>;
  fieldLabel?: string;
  control: Control<T>;
  required?: boolean;
  error?: string;
  defaultValue?: any;
  textFieldVariant?: 'outlined' | 'filled' | 'standard';
  sx?: SxProps;
  // views?: Array<'day' | 'hours' | 'minutes' | 'month' | 'seconds' | 'year'>;
  locale?: Locale;
};

/**
 * TimePickerDesktop Component
 * @param props 
 * @returns a TimePicker component to be directly used in react-hook-form contexts Desktop Version
 */
export default function ErTimePickerDesktop<T extends FieldValues>(
  props: ErTimePickerDesktopTypes<T>,
) {
  const {
    fullWidth,
    disabled,
    size,
    fieldName,
    control,
    defaultValue,
    error,
    fieldLabel,
    textFieldVariant,
    sx,
    locale = de,
  } = props;

  return (
    <FormControl fullWidth={fullWidth}>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={locale}
          >
            <DesktopTimePicker
            disabled={disabled}
              sx={sx}
              label={fieldLabel}
              slotProps={{
                textField: {
                  error: Boolean(error),
                  helperText: error && error,
                  variant: textFieldVariant,
                  size: size,
                },
              }}
              {...field}
            />
          </LocalizationProvider>
        )}
      />
    </FormControl>
  );
}
