import {
  DndContext,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Alert,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  Menu,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import MediaListItem from './MediaListItem';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import MediaListItemMenu from './MediaListItemMenu';
import { MediaFileUpdateTypes, MediaUpdateTypes } from '../Slots/Slots';
import { useParams } from 'react-router-dom';
import { mediaDataTypes } from '../Slots/Context/SlotContext';
import useEventStore from '../../store/eventStore';
import FolderZipOutlinedIcon from '@mui/icons-material/FolderZipOutlined';
import downloadMediaFilesApi, {
  downloadMediaFilesApiParamsTypes,
  downloadType,
  parentClass,
} from '../../api/media/downloadMediaFilesApi';
import { downloadFile } from '../../util/fileActions';
import { useFeedback } from '../Feedback/FeedbackContext';
import MediaListDownloadPopover from './MediaDownload/MediaListDownloadPopover';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

export type MediaListPropsTypes = {
  media: any[];
  addNewMedia?: (fileName: string) => void;
  addNewMediaFileBased?: (files: any[], parentClassName: string) => void;
  addNewMediaVersion?: (activeMediaId: string | null, files: FileList) => void;
  deleteMediaVersion?: (
    activeMediaId: string | null,
    mediaFileId: string,
  ) => void;
  deleteMedia?: (activeMediaId: string | null) => void;
  // downloadMedia: (filePath: string) => void;
  updateMedia?: (updateData: MediaUpdateTypes) => void;
  updateMediaFile?: (updateData: MediaFileUpdateTypes) => void;
  updateMediaOrder?: (
    data: string[],
    invalidateQuery?: boolean,
  ) => Promise<any>;
  mediaquery?: boolean;
  sx?: SxProps;
  detailsEditing?: string;
  activeEditId?: string | undefined; // used to determine item changes in lists where mutliple items of the same class exist
  parentClass?: parentClass;
  parentId?: string;
  variant?: 'reduced' | 'full';
};

export type MediaDataTypes = {
  id: string;
  title: string;
  type: string;
};

export interface downloadMediaFilesDataTypes
  extends Omit<downloadMediaFilesApiParamsTypes, 'downloadType'> {}

export default function MediaList(props: MediaListPropsTypes) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const events = window.fsEvents;

  // props
  const {
    media,
    addNewMedia = () => {},
    addNewMediaVersion = () => {},
    deleteMedia = () => {},
    deleteMediaVersion = () => {},
    updateMediaFile = () => {},
    updateMediaOrder = () => {},
    // mediaquery,
    sx,
    detailsEditing = 'slots',
    activeEditId,
    parentClass,
    parentId,
    variant = 'full',
  } = props;

  // url params
  const { sessionId, eventId } = useParams();

  // global store
  const paidFeatures = useEventStore(
    (state) => state.data?.get('features_active'),
  );

  // feedback
  const feedback = useFeedback();

  // media queries
  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const downloadMediaFilesData: downloadMediaFilesDataTypes = {
    parentClass: parentClass || 'Slot',
    parentId: parentId || '',
    eventId: eventId!,
  };

  // media management activated?
  const [mediaMngActive, setMediaMngActive] = useState(true);

  const [activateMedieMngLink, setActivateMedieMngLink] = useState(
    mediaQuery ? 'mediaMng' : 'features',
  );

  // state
  // const [items, setItems] = useState(media);
  const [items, setItems] = useState<any[]>([]);

  const [oldItems, setOldItems] = useState<mediaDataTypes[]>([]);
  const [newItem, setNewItem] = useState<mediaDataTypes | undefined>();

  //
  const [activeId, setActiveId] = useState(null);

  // id of the item that is expanded
  const [itemOpen, setItemOpen] = useState();

  // id of the item that has active details editing (title,...)
  const [isEditingDetailsId, setIsEditingDetailsId] = useState(null);

  // new media ui active?
  const [newMediaActive, setNewMediaActive] = useState(false);
  const [newMediaName, setNewMediaName] = useState('');

  //// paid options modal
  const [paidOptionsModal, setPaidOptionsModal] = useState(false);

  const togglePaidOptionsModal = () => {
    // close the download overlay if open
    downloadOverlayOpen && toggleDownloadOverlayOpen();
    setPaidOptionsModal(!paidOptionsModal);
  };

  //  handle the detail menus on desktop
  // !!! typing
  const detailMenuRefs = useRef<any>([]);
  // const [activeMediaDetailsRef, setActiveMediaDetailsRef] = useState();

  // download overlay
  const downloadOverlayRef = useRef(null);
  const [downloadOverlayOpen, setDownloadOverlayOpen] = useState(false);

  const toggleDownloadOverlayOpen = () => {
    setDownloadOverlayOpen(!downloadOverlayOpen);
  };

  // // !!!typing
  // const toggleDetailMenu = (id: any) => {
  //   !detailMenuOpen ? setActiveId(id) : setActiveId(null);
  //   setDetailMenuOpen(!detailMenuOpen);

  //   // set the ref of the active menu
  //   const activeIndex = media.map((m) => m.id).indexOf(id);

  //   if (activeIndex >= 0)
  //     setActiveMediaDetailsRef(detailMenuRefs.current[activeIndex]);
  // };

  // activate the form for adding new media
  const handleNewMedia = () => {
    setNewMediaActive(true);
  };

  // save new media
  const saveNewMedia = () => {
    if (newMediaName && newMediaName !== '') {
      addNewMedia(newMediaName);
      setNewMediaActive(false);
      setNewMediaName('');
    }
  };

  const deleteMediaWrapper = (activeMediaId: string | null) => {
    // setActiveMediaDetailsRef(undefined);
    // setDetailMenuOpen(false);

    deleteMedia(activeMediaId);
  };

  // delete item wrapper
  const deleteVersion = (mediafileId: string) => {
    console.log('###!!!-deleteVersion', activeId, mediafileId);

    deleteMediaVersion(activeId, mediafileId);
  };

  // download media handler
  const downloadHandler = (type: downloadType) => {
    // display loading indicator
    feedback.setFeedback({ headline: 'Preparing download', type: 'loading' });

    downloadMediaFilesApi({
      ...downloadMediaFilesData,
      downloadType: type,
    })
      .then((res: string) => {
        downloadFile(res);
        feedback.setFeedback(null);
      })
      .catch((e: Error) => {
        feedback.setFeedback({
          type: 'error',
          errorCode: e,
          autoclose: true,
        });
        console.error(e);
      });
  };

  // theme
  const theme = useTheme();

  // dnd setup
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
  );

  function handleDragStart(event: any) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setItems((items: any[]) => {
        const oldIndex = items.map((media: any) => media.id).indexOf(active.id);
        const newIndex = items.map((media: any) => media.id).indexOf(over.id);

        const resortedItems = arrayMove(items, oldIndex, newIndex);

        // get the sorting of the media
        const mediaIds: string[] = [];

        resortedItems.forEach((mediaObj: Parse.Object) =>
          mediaIds.push(mediaObj.id),
        );

        updateMediaOrder(mediaIds);

        return resortedItems;
      });
    }
  }

  useEffect(() => {
    // update the paid features state for the active session

    // let thisSessionMediaMngIndex = -1;

    // get the mediaMng feature payment state for the current session
    if (paidFeatures?.mediaMng && paidFeatures?.mediaMng.length > 0) {
      // thisSessionMediaMngIndex = paidFeatures.mediaMng.findIndex(
      //   (feature: any) => {
      //     return (
      //       feature.obj.objectId === sessionId && feature.status === 'paid'
      //     );
      //   },
      // );
      setMediaMngActive(true);
    } else {
      setMediaMngActive(false);
    }

    // if (thisSessionMediaMngIndex !== -1) {
    //   setMediaMngActive(true);
    // }
  }, [paidFeatures]);

  // update the list items
  useEffect(() => {
    setItems(media);
    // detailMenuRefs.current = detailMenuRefs.current.slice(0, media.length);
  }, [media]);

  // useEffect(() => {
  //   // close detail menu if on mobile
  //   if (!mediaquery) {
  //     () => setDetailMenuOpen(false);
  //   }
  // }, [mediaquery]);

  // update the name in the new media UI
  useEffect(() => {
    if (!newMediaActive) {
      setNewMediaName('');
    }
  }, [newMediaActive]);

  // unset active media if type of item (slot or session) changes
  useEffect(() => {
    setNewMediaActive(false);
  }, [activeEditId]);

  return (
    <>
      <Stack sx={{ alignItems: 'flex-start', ...sx }}>
        {/* BUY NOW */}
        {!mediaMngActive && variant === 'full' && (
          <Alert
            icon={<GradeOutlinedIcon />}
            severity="info"
            sx={{ width: '100%', mt: 1, cursor: 'pointer' }}
            // onClick={() => {
            //   setPaidOptionsModal(true);
            // }}
            onClick={() => {
              events?.publish('toggleEventMenu', {
                activateSection: activateMedieMngLink,
              });
            }}
          >
            Media Management jetzt buchen!
          </Alert>
        )}

        {/* DND List */}
        {items && items.length > 0 && (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              <List
                sx={{
                  // bgcolor: '#eee',
                  mt: variant === 'full' ? 1 : 0,
                  p: 0,

                  borderRadius: theme.shape.borderRadius / 2,
                  width: '100%',
                }}
              >
                {items.map((mediaData: any, index: number) => (
                  <MediaListItem
                    // ref={(el) => (detailMenuRefs.current[index] = el)}
                    key={mediaData.id}
                    id={mediaData.id}
                    active={mediaData.id === activeId}
                    setActiveId={setActiveId}
                    itemOpen={itemOpen}
                    setItemOpen={setItemOpen}
                    mediaData={mediaData}
                    // toggleDetailMenu={toggleDetailMenu}
                    deleteVersion={deleteVersion}
                    updateMediaFile={updateMediaFile}
                    addNewMediaVersion={addNewMediaVersion}
                    deleteMedia={deleteMedia}
                    isEditingDetailsId={isEditingDetailsId}
                    setIsEditingDetailsId={setIsEditingDetailsId}
                    sessionId={sessionId!}
                    eventId={eventId!}
                    newItemId={newItem?.id}
                    mediaMngActive={mediaMngActive}
                    variant={variant}
                  />
                ))}
              </List>
            </SortableContext>
          </DndContext>
        )}

        <Divider sx={{ my: 1 }} />

        {newMediaActive && (
          <Box sx={{ px: { xs: 1, md: 0 }, width: '100%' }}>
            <TextField
              label="Neues Medium anlegen"
              size="small"
              fullWidth
              autoFocus
              value={newMediaName}
              onChange={(event) => setNewMediaName(event.currentTarget.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  saveNewMedia();
                }
              }}
              InputProps={{
                endAdornment: (
                  <Stack direction="row">
                    <IconButton
                      size="small"
                      onClick={() => {
                        saveNewMedia();
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setNewMediaActive(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                ),
              }}
            />
          </Box>
        )}
        {!newMediaActive && variant === 'full' && (
          // <Box sx={{ px: { xs: 1, md: 0 }, width: '100%' }}>
          <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
            <IconButton
              color="primary"
              sx={{
                // width: '100%',
                flex: 'auto',
                border: '2px solid',
                borderColor: theme.palette.primary.main,
                borderRadius: theme.shape.borderRadius / 2,
              }}
              onClick={() => {
                handleNewMedia();
              }}
            >
              <NoteAddOutlinedIcon />
            </IconButton>

            <IconButton
              color="primary"
              sx={{
                flex: 'auto',
                border: '2px solid',
                borderColor: theme.palette.primary.main,
                borderRadius: theme.shape.borderRadius / 2,
              }}
              onClick={() => {
                toggleDownloadOverlayOpen();
              }}
              ref={downloadOverlayRef}
            >
              <FolderZipOutlinedIcon />
            </IconButton>
          </Stack>
          // </Box>
        )}

        {/* Paid features modal */}
        {/* <Modal open={paidOptionsModal} onClose={togglePaidOptionsModal}>
          <PayedOptions
            featuresFor={{ className: 'Session', objectId: sessionId! }}
            focus={{ topic: 'mediaMng', id: sessionId! }}
          />
        </Modal> */}

        {/* Download modal */}
        <MediaListDownloadPopover
          open={downloadOverlayOpen}
          toggleOpen={toggleDownloadOverlayOpen}
          togglePaidOptionsModal={togglePaidOptionsModal}
          anchorRef={downloadOverlayRef}
          featurePaid={mediaMngActive}
          downloadHandler={downloadHandler}
          position={{
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            transformOrigin: { vertical: 'bottom', horizontal: 'center' },
          }}
          headline={
            detailsEditing === 'slots'
              ? 'Download Slot-Medien'
              : 'Download Session-Medien'
          }
          subline="Lade die neusten Medien-Versionen in einer ZIP-Datei auf Deinen
        Rechner."
        />
      </Stack>
    </>
  );
}
