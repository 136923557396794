import { useCallback, useEffect, useMemo, useState } from 'react';
import { slotDataTypes, slotsDataTypes, useSlotContext } from './SlotContext';
import { log } from 'util';

export type sessionTimingTypes = {
  endTimePlanned: boolean;
  sessionStartTime: Date;
  sessionEndTime: Date;
  sessionEndTimeCalculated: Date;
};

export interface slotsTimingTypes {
  startTime: Date;
  //   startTimeLatest: Date;
  duration: number;
  durationMin: number;
  endTime: Date;
}

export type slotlistRenderDataTypes = {
  sessionTiming: sessionTimingTypes;
  slotsRenderData: slotsDataTypes;
  slotsTiming: slotsTimingTypes[];
  slotsFeedback: any[];
  // slotsFeedback: slotsFeedbackTypes[];
};

export type slotsFeedbackTypes =
  | undefined
  | {
      timing?: {
        state?: 'warning' | 'error' | 'info';
        overlapMinutes?: number;
        calculatedDuration?: number;
        hideTime?: boolean;
      };
    };

// export type slotsFeedbackTypes = {
//   timing?: {
//     state?: 'warning' | 'error' | 'info';
//     overlapMinutes?: number;
//     calculatedDuration?: number;
//     hideTime?: boolean;
//   };
// };

export type useSlotlistDataReturnTypes = {
  calcSlotlistRenderData: (
    slotsDataExt: slotsDataTypes,
    sessionDataExt?: any,
    preview?: boolean,
    persist?: boolean,
  ) => slotlistRenderDataTypes;
  slotlistRenderData: slotlistRenderDataTypes;
  slotlistRenderDataPreview: slotlistRenderDataTypes | undefined;
  resetPreviewData: () => void;
};

export default function useSlotlistRenderData(): useSlotlistDataReturnTypes {
  // use slot context
  // const context = useSlotContext();
  const { sessionData, slotsData, setSlotsRenderDataUI, updateSlotOrder } =
    useSlotContext();

  // console.log('##-RENDERDATA', sessionData, slotsData);

  // preview data
  const [slotlistRenderDataPreview, setSlotlistRenderDataPreview] = useState<
    slotlistRenderDataTypes | undefined
  >();

  const resetPreviewData = () => {
    setSlotlistRenderDataPreview(undefined);
  };

  /**
   * Function to calculate a state from outside rendering cycle
   *
   * @param slotsDataExt An external (not based on useSlotlistRenderData) slots list
   * @param sessionDataExt External (not based on useSlotlistRenderData) session data
   * @param preview Flag if this is to be calculated as preview (if it renders to the UI)
   * @param persist Flag if the calculation is to be persited to the DB
   *
   * @returns Calculated set of slotlist render data
   */
  const calcSlotlistRenderData = (
    slotsDataExt: slotsDataTypes,
    sessionDataExt: any = sessionData,
    preview: boolean = false,
    persist: boolean = true,
  ) => {
    //
    const slotlistRenderRes = mainCycle({
      sessionData: sessionDataExt,
      slotsData: slotsDataExt,
      preview: preview,
      persist: persist,
      updateSlotOrder: updateSlotOrder,
    });

    if (preview) {
      setSlotlistRenderDataPreview(slotlistRenderRes);
    }

    return slotlistRenderRes;
  };

  //// CALL THE MAIN CYCLE
  const mainCycleResult = mainCycle({
    sessionData: sessionData,
    slotsData: slotsData,
    updateSlotOrder: updateSlotOrder,
  });

  //// FINAL DATA
  // useMemo to prevent hook result from rerendering
  // on unrelevant changes in slot context
  // slotlistRenderDataPreview -> needed for preview reset inSlotActionMenu to work
  const slotlistRenderData = useMemo(
    () => mainCycleResult,
    [sessionData, slotsData, slotlistRenderDataPreview],
  );

  // make current timing available in slot context
  useEffect(() => {
    setSlotsRenderDataUI(slotlistRenderData);
  }, [slotlistRenderData, setSlotsRenderDataUI]);

  // reset preview data if new server data is coming in
  useEffect(() => {
    resetPreviewData();
  }, [slotsData]);

  // finally return the list to build the UI
  return {
    slotlistRenderData: slotlistRenderData,
    slotlistRenderDataPreview: slotlistRenderDataPreview,
    calcSlotlistRenderData: calcSlotlistRenderData,
    resetPreviewData: resetPreviewData,
  };
}

function mainCycle(params: {
  sessionData: any;
  slotsData: slotsDataTypes;
  preview?: boolean;
  persist?: boolean;
  updateSlotOrder: any;
}) {
  const {
    sessionData,
    slotsData,
    preview = false,
    persist = true,
    updateSlotOrder,
  } = params;

  //// BASIC SETTINGS
  // set the starttime
  const sessionStartTime = new Date(sessionData.get('starttime'));

  // check if we have a set endtimePlanned
  const endTimePlanned = Boolean(sessionData.get('endtimePlanned'));
  let sessionEndTime: Date = new Date(sessionData.get('endtimePlanned'));
  let sessionEndTimeCalculated = new Date(sessionData.get('starttime'));

  //// THE RESULT ARRAYS
  // new timing array
  const slotsTiming: slotsTimingTypes[] = [];
  // new render data array
  const slotsRenderData: slotsDataTypes = [];
  // new feedback array
  const slotsFeedback: any[] = [];

  // eos object store for open end sessions
  let eosStore: any;
  // flag for opend end sessions to register if slotorder needs to be persisted
  let openEosIsLast: boolean = true;

  // flag if we need to persist slot order to server
  // HANDLE WITH CARE, persisting data in this hook may cause undesired (infinite) rerenders!
  let callUpdateSlotOrder = false;

  if (slotsData && slotsData.length > 0) {
    //// LOCAL UPDATE OF EOS OBJ

    //// STARTTIMEPLANNED PRECALC
    let allPauses: slotDataTypes[] = slotsData
      ?.filter((slot) => slot?.starttimePlanned)
      .sort((a: any, b: any) => a.starttimePlanned - b.starttimePlanned);

    if (!endTimePlanned) {
      allPauses = slotsData
        ?.filter((slot) => slot?.starttimePlanned && !(slot?.type === 'eos'))
        .sort((a: any, b: any) => a.starttimePlanned - b.starttimePlanned);
    }

    let slotContainer = slotsData?.map((slot) => slot);

    // // remove eos for open end sessions and add it at the end of calculation again
    if (!endTimePlanned) {
      const eosIndex = slotContainer.findIndex((slot) => slot?.type === 'eos');

      if (eosIndex !== slotContainer.length - 1) {
        openEosIsLast = false;
      } else {
        openEosIsLast = true;
      }

      eosStore = slotContainer.filter((slot) => slot?.type === 'eos')[0];
      slotContainer = slotContainer.filter((slot) => slot?.type !== 'eos');
    }

    //// SPLIT SLOT PRECALCULATIONS
    // check for split slots
    const splitSlotsIndecies = findDuplicateIds(slotContainer);

    // update split slots in slotContainer & create time obj
    if (splitSlotsIndecies) {
      // get all split slot keys
      const allSplitKeys = Object.keys(splitSlotsIndecies);

      // for all split slots
      allSplitKeys.forEach((key: string, index: number) => {
        // build timing object

        // rename all split slots
        splitSlotsIndecies[key].forEach((pos: number, index: number) => {
          const newName = slotContainer[pos]?.id + '-part_' + index;
          slotContainer[pos]!.id = newName;
        });
      });
    }

    // remove invalid splits (e.g. split elements that are
    // side by side in a pause section) from slotContainer
    const newSlotList = removeInvalidSplits(slotContainer);
    const splitSlotsRemaingTime: { [k: string]: any } = {};

    // get all already renamed split slots
    const allRenamedSplitSlots = newSlotList.filter((slot) => {
      if (slot?.id!.includes('-part_')) {
        // get the baseId
        const baseId = slot?.id!.split('-part_')[0];
        // add key to remaining time obj
        splitSlotsRemaingTime[baseId] = slotContainer.find(
          (item) => item?.id === slot!.id,
        )?.durationPlaned;

        return slot?.id;
      }
    });

    // see if there were any changes after handling split elements
    if (slotContainer.length !== newSlotList?.length) {
      slotContainer = newSlotList;
      callUpdateSlotOrder = true;
    }

    //// I - CYCLE THROUGH PAUSES
    if (allPauses && allPauses.length > 0) {
      //// I.A - PAUSE CYCLE
      allPauses.forEach((pause, index) => {
        // index of the pause in the recent overall list
        const pauseIndexInContainer = slotContainer?.findIndex(
          (slot: slotDataTypes) => slot!.id === pause!.id,
        );

        // pause times
        const pauseStartTime = pause!.starttimePlanned;
        const pauseEndtime = new Date(pauseStartTime!);
        pauseEndtime.setMinutes(
          pauseEndtime.getMinutes() + pause!.durationPlaned,
        );

        // time space available before the pause
        let freeTimeMinutes = calcDurationBetweenDates(
          sessionStartTime,
          pauseStartTime!,
        ).abs;

        if (index !== 0) {
          // it's not the first pause so use pause.starttimePlanned as startTime
          const endTimePreviousPause = new Date(
            allPauses[index - 1]!.starttimePlanned!,
          );
          endTimePreviousPause.setMinutes(
            endTimePreviousPause.getMinutes() +
              allPauses[index - 1]!.durationPlaned,
          );

          freeTimeMinutes = calcDurationBetweenDates(
            endTimePreviousPause,
            pauseStartTime!,
          ).abs;
        }

        // collect the indecies to be deleted from slotsContainer after the loop is finished
        const slotContainerIndexesToDelete: number[] = [];

        // iterate over the slots
        for (let index = 0; index < slotContainer.length; index++) {
          // check if we have slots left that should be placed before the pause

          if (
            index < pauseIndexInContainer &&
            // !(slotContainer[index]?.type === 'eos') // see if this is good???
            !slotContainer[index]?.starttimePlanned // fix, to prevent pauses from double rendering, not good... see if this is good???
          ) {
            // start adding slots if they are in order before the pause

            //// SPLIT SLOTS
            // check if we have a split slot
            const splitSlot = slotContainer[index]!.id!.includes('-part_');
            let splitSlotRestTime = 1000000000000000; // make sure this var is not relevant if we do not have a split slot

            if (splitSlot) {
              const splitSlotIdParts =
                slotContainer[index]!.id!.split('-part_');
              const baseId = splitSlotIdParts[0];
              const partNumber = parseInt(splitSlotIdParts[1], 10);

              splitSlotRestTime = splitSlotsRemaingTime[baseId];

              // !!! delete splitslot if remaining time is 0, do not enter the calculation cycle
              if (splitSlotRestTime === 0) {
                // mark slot to be deleted from slotContainer
                slotContainerIndexesToDelete.push(index);
                // slot order needs to be persisted
                callUpdateSlotOrder = true;
                // exit the loop
                break;
              }
            }

            if (
              slotContainer[index]!.durationPlaned <= freeTimeMinutes ||
              splitSlotRestTime <= freeTimeMinutes
            ) {
              // if time is available push render data to array
              slotsRenderData.push(slotContainer[index]);

              // calc slot endtime
              const slotEndtime = new Date(sessionEndTimeCalculated);
              slotEndtime.setMinutes(
                slotEndtime.getMinutes() + slotContainer[index]!.durationPlaned,
              );

              //// REGULAR SLOT (NONE SPLIT)
              // none split timing
              const slotTiming = {
                startTime: new Date(sessionEndTimeCalculated),
                duration: slotContainer[index]?.durationPlaned as number,
                durationMin: slotContainer[index]?.durationMin as number,
                endTime: slotEndtime,
              };

              // none split feedback
              let slotFeedback: slotsFeedbackTypes;

              // none split sessionEndTimeCalc
              let slotSessionEndTime = new Date(slotEndtime);

              // none split time consumed
              let slotConsumedTime = slotContainer[index]!.durationPlaned;

              // eos handling
              // always show error if we are over the eos time
              if (
                endTimePlanned &&
                slotTiming.startTime.getTime() >= sessionEndTime.getTime()
              ) {
                slotFeedback = {
                  timing: {
                    state: 'error',
                    calculatedDuration: 0,
                    overlapMinutes: slotTiming.duration,
                    hideTime: true,
                  },
                };
              }

              //// SPLIT SLOT
              // check if we have a split slot
              if (slotContainer[index]!.id!.includes('-part_')) {
                // update timing
                const slotIdParts = slotContainer[index]!.id!.split('-part_');
                const baseId = slotIdParts[0];
                const partNumber = parseInt(slotIdParts[1], 10);

                // set timimg
                const splitSlotEndtime = new Date(sessionEndTimeCalculated);
                splitSlotEndtime.setMinutes(
                  splitSlotEndtime.getMinutes() + splitSlotsRemaingTime[baseId],
                );

                slotTiming.endTime = splitSlotEndtime;

                slotSessionEndTime = new Date(splitSlotEndtime);
                // !!!!
                slotConsumedTime = splitSlotsRemaingTime[baseId];

                // set feedback
                if (
                  endTimePlanned &&
                  slotTiming.startTime.getTime() >= sessionEndTime.getTime()
                ) {
                  // handle eos
                  slotFeedback = {
                    timing: {
                      state: 'error',
                      calculatedDuration: 0,
                      overlapMinutes: 0,
                      hideTime: true,
                    },
                  };

                  // update the previous split parts feedback
                  const allParts = allRenamedSplitSlots.filter(
                    (slot) => slot?.id!.includes(baseId),
                  );

                  allParts.forEach(
                    (splitPart: slotDataTypes, index: number) => {
                      if (index < allParts.length - 1) {
                        const indexInslotsRenderData =
                          slotsRenderData.findIndex(
                            (slot) => slot?.id === splitPart?.id,
                          );

                        if (
                          slotsRenderData[indexInslotsRenderData]!
                            .durationMin <=
                          slotsRenderData[indexInslotsRenderData]!
                            .durationPlaned -
                            splitSlotsRemaingTime[baseId]
                        ) {
                          slotsFeedback[indexInslotsRenderData]!.timing.state =
                            'warning';
                        } else {
                          slotsFeedback[indexInslotsRenderData]!.timing.state =
                            'error';
                        }
                      }
                    },
                  );
                } else {
                  slotFeedback = {
                    timing: {
                      state: 'info',
                      calculatedDuration: splitSlotsRemaingTime[baseId],
                      overlapMinutes: 0,
                      hideTime: false,
                    },
                  };
                }

                // split slot completed
                splitSlotsRemaingTime[baseId] = 0;
              }

              // push timing to array
              slotsTiming.push(slotTiming);

              // push feedback to array
              slotsFeedback.push(slotFeedback);

              // add time to the calculated session end time
              sessionEndTimeCalculated = slotSessionEndTime;

              // mark slot to be removed from slotsContainer
              slotContainerIndexesToDelete.push(index);

              // update free timespace
              freeTimeMinutes -= slotConsumedTime;
            } else {
              // not enough time; handle overlap, add buffer (if necessary) & add pause itself
              // get the amount over overlap
              const slotOverlap =
                slotContainer[index]!.durationPlaned - freeTimeMinutes;

              // get the slot timing as if there was no pause
              const slotStarttimeWithoutPause = new Date(
                sessionEndTimeCalculated,
              );
              const slotEndtimeWithoutPause = new Date(
                slotStarttimeWithoutPause,
              );

              slotEndtimeWithoutPause.setMinutes(
                slotEndtimeWithoutPause.getMinutes() +
                  slotContainer[index]!.durationPlaned,
              );

              const slotEndtime = new Date(slotStarttimeWithoutPause);
              slotEndtime.setMinutes(
                slotEndtime.getMinutes() + freeTimeMinutes,
              );

              if (freeTimeMinutes >= 0) {
                // still some time available
                // build the feedback data
                const slotFeedbackToAdd: slotsFeedbackTypes = {
                  timing: {
                    state: 'error',
                    overlapMinutes: slotOverlap,
                    calculatedDuration: freeTimeMinutes,
                    hideTime: false,
                  },
                };

                // check if at least the minimum time fits in
                if (
                  slotOverlap <=
                  slotContainer[index]!.durationPlaned -
                    slotContainer[index]!.durationMin
                ) {
                  slotFeedbackToAdd.timing!.state = 'warning';
                }

                // handle eos
                if (
                  endTimePlanned &&
                  sessionEndTimeCalculated.getTime() >= sessionEndTime.getTime()
                ) {
                  slotFeedbackToAdd.timing!.state = 'error';
                  slotFeedbackToAdd.timing!.calculatedDuration = 0;
                  slotFeedbackToAdd.timing!.overlapMinutes = slotOverlap;
                  slotFeedbackToAdd.timing!.hideTime = true;
                }

                // set slot data
                const slotDataToAdd: slotDataTypes = {
                  ...slotContainer[index]!,
                };

                // set slots timing data
                const slotTimingToAdd: slotsTimingTypes = {
                  startTime: new Date(slotStarttimeWithoutPause),
                  // startTimeLatest: slot.startdateLatest,
                  duration: slotContainer[index]?.durationPlaned as number,
                  durationMin: slotContainer[index]?.durationMin as number,
                  // endTime: slotEndtimeWithoutPause,
                  endTime: slotEndtime,
                };

                //// SPLIT SLOT
                //check if we have a split slot
                if (slotContainer[index]!.id!.includes('-part_')) {
                  // update timing
                  const slotIdParts = slotContainer[index]!.id!.split('-part_');
                  const baseId = slotIdParts[0];
                  const partNumber = parseInt(slotIdParts[1], 10);

                  // split slot overlap
                  const splitSlotOverlap =
                    splitSlotsRemaingTime[baseId] - freeTimeMinutes;

                  splitSlotsRemaingTime[baseId] = splitSlotOverlap;

                  //check if this is the last part of the split slot
                  const allParts = allRenamedSplitSlots.filter(
                    (slot) => slot?.id!.includes(baseId),
                  );

                  let isLastSplitPart = false;

                  if (allParts.length > 0) {
                    if (
                      partNumber + 1 === allParts.length &&
                      splitSlotsRemaingTime[baseId] !== 0
                    ) {
                      isLastSplitPart = true;
                      // it is the last slot & it has time left to be distributed
                      if (
                        slotContainer[index]!.durationMin <=
                        slotContainer[index]!.durationPlaned -
                          splitSlotsRemaingTime[baseId]
                      ) {
                        slotFeedbackToAdd.timing!.state = 'warning';
                        slotFeedbackToAdd.timing!.calculatedDuration =
                          freeTimeMinutes;
                        slotFeedbackToAdd.timing!.overlapMinutes =
                          splitSlotOverlap;
                      } else {
                        slotFeedbackToAdd.timing!.state = 'error';
                        slotFeedbackToAdd.timing!.calculatedDuration =
                          freeTimeMinutes;
                        slotFeedbackToAdd.timing!.overlapMinutes =
                          splitSlotOverlap;
                      }
                    } else {
                      slotFeedbackToAdd.timing!.state = 'info';
                      slotFeedbackToAdd.timing!.calculatedDuration =
                        freeTimeMinutes;
                      slotFeedbackToAdd.timing!.overlapMinutes =
                        splitSlotOverlap;
                    }
                  }

                  // eos handling
                  if (
                    endTimePlanned &&
                    sessionEndTimeCalculated.getTime() >=
                      sessionEndTime.getTime()
                  ) {
                    // adjust the feedback
                    slotFeedbackToAdd.timing!.state = 'error';
                    slotFeedbackToAdd.timing!.calculatedDuration = 0;
                    slotFeedbackToAdd.timing!.overlapMinutes =
                      splitSlotsRemaingTime[baseId];

                    // update the previous split parts feedback
                    allParts.forEach(
                      (splitPart: slotDataTypes, index: number) => {
                        if (index < allParts.length - 1) {
                          const indexInslotsRenderData =
                            slotsRenderData.findIndex(
                              (slot) => slot?.id === splitPart?.id,
                            );

                          slotsFeedback[indexInslotsRenderData]?.timing
                            .state === 'warning';
                        }
                      },
                    );
                  }
                }

                // SLOT
                // return the slot
                // push slot at end of arrays
                slotsRenderData.push(slotDataToAdd);
                // push to the timing array

                slotsTiming.push(slotTimingToAdd);

                // push feedback to array
                slotsFeedback.push(slotFeedbackToAdd);

                sessionEndTimeCalculated = new Date(pauseStartTime!);

                // add slot to be deleted from slotContainer
                slotContainerIndexesToDelete.push(index);
              } else {
                // no more time available
                // this slot will be added after the current pause
                // therefor we need to update the slotorder for future
                callUpdateSlotOrder = true;
              }

              // PAUSE
              // push pause slot at end of arrays
              slotsRenderData.push(pause);
              // push to the timing array

              let pauseTiming: slotsTimingTypes = {
                startTime: new Date(pauseStartTime!),
                duration: pause?.durationPlaned as number,
                durationMin: pause?.durationMin as number,
                endTime: pauseEndtime,
              };

              // pause feedback
              let pauseFeedback: slotsFeedbackTypes;

              // eos handling
              if (
                endTimePlanned &&
                pauseStartTime!.getTime() >= sessionEndTime.getTime()
              ) {
                pauseTiming = {
                  startTime: sessionEndTime,
                  duration: pause?.durationPlaned as number,
                  durationMin: pause?.durationMin as number,
                  endTime: sessionEndTime,
                };

                pauseFeedback = {
                  timing: {
                    state: 'error',
                    calculatedDuration: 0,
                    overlapMinutes: pause?.durationPlaned,
                    hideTime: true,
                  },
                };
              }

              if (
                endTimePlanned &&
                pauseStartTime!.getTime() < sessionEndTime.getTime() &&
                pauseEndtime!.getTime() > sessionEndTime.getTime()
              ) {
                const calcDuration = calcDurationBetweenDates(
                  new Date(pause!.starttimePlanned!),
                  sessionEndTime,
                ).abs;

                pauseTiming = {
                  startTime: new Date(pauseStartTime!),
                  duration: pause?.durationPlaned as number,
                  durationMin: pause?.durationMin as number,
                  endTime: sessionEndTime,
                };

                pauseFeedback = {
                  timing: {
                    state: 'error',
                    calculatedDuration: calcDuration,
                    overlapMinutes: pause!.durationPlaned - calcDuration,
                    hideTime: false,
                  },
                };
              }

              // check for overlapping pauses
              if (
                pauseIndexInContainer + 1 <= slotContainer.length - 1 &&
                slotContainer[pauseIndexInContainer + 1]?.starttimePlanned
              ) {
                if (
                  pauseTiming.endTime.getTime() >
                  slotContainer[
                    pauseIndexInContainer + 1
                  ]!.starttimePlanned!.getTime()
                ) {
                  pauseTiming = {
                    startTime: new Date(pauseStartTime!),
                    duration: pause?.durationPlaned as number,
                    durationMin: pause?.durationMin as number,
                    endTime:
                      slotContainer[pauseIndexInContainer + 1]!
                        .starttimePlanned!,
                  };

                  const calcDuration = calcDurationBetweenDates(
                    pause!.starttimePlanned!,
                    slotContainer[pauseIndexInContainer + 1]!.starttimePlanned!,
                  ).dif;

                  pauseFeedback = {
                    timing: {
                      state: 'error',
                      calculatedDuration: calcDuration,
                      overlapMinutes: pause!.durationPlaned - calcDuration,
                      hideTime: false,
                    },
                  };

                  if (
                    pauseFeedback.timing!.calculatedDuration! >=
                    pause!.durationMin
                  ) {
                    pauseFeedback.timing!.state = 'warning';
                  }
                }
              }

              //// FINALLY PUSH EVERYTHING TO RENDER ARRAY
              // push timing to array
              slotsTiming.push(pauseTiming);

              // push feedback to array
              slotsFeedback.push(pauseFeedback);

              // sessionEndTimeCalculated = new Date(pauseEndtime);
              sessionEndTimeCalculated = pauseTiming.endTime;

              // add pause to be deleted from slotContainer
              slotContainerIndexesToDelete.push(pauseIndexInContainer);

              // remove everything we already added from slotsContainer
              // do not remove the item that didn' fit in!
              slotContainer = slotContainer.filter(
                (element, index) =>
                  !slotContainerIndexesToDelete.includes(index),
              );

              // exit for loop
              break;
            }
          } else {
            // add buffer (if necessary), the pause and then exit this cycle
            //
            // buffer
            if (
              (freeTimeMinutes >= 0 &&
                sessionEndTimeCalculated.getTime() <=
                  sessionEndTime?.getTime()) ||
              (freeTimeMinutes >= 0 && !endTimePlanned)
            ) {
              const newBuffer = createNewBuffer(
                sessionEndTimeCalculated,
                pauseStartTime!,
              );

              if (newBuffer) {
                // push buffer slot at END of arrays
                slotsRenderData.push(newBuffer.bufferData);

                // push to the timing array
                slotsTiming.push(newBuffer.bufferTiming);

                // push feedback to array
                slotsFeedback.push(newBuffer.bufferFeedback);

                sessionEndTimeCalculated = new Date(pauseStartTime!);
              }
            }
            //
            // pause
            // PAUSE
            // push pause slot at end of arrays
            slotsRenderData.push(pause);
            // push to the timing array

            let pauseTiming: slotsTimingTypes = {
              startTime: new Date(pauseStartTime!),
              duration: pause?.durationPlaned as number,
              durationMin: pause?.durationMin as number,
              endTime: pauseEndtime,
            };

            // pause feedback
            let pauseFeedback: slotsFeedbackTypes;

            // eos handling
            if (
              endTimePlanned &&
              pauseStartTime!.getTime() >= sessionEndTime.getTime()
            ) {
              pauseTiming = {
                startTime: sessionEndTime,
                duration: pause?.durationPlaned as number,
                durationMin: pause?.durationMin as number,
                endTime: sessionEndTime,
              };

              pauseFeedback = {
                timing: {
                  state: 'error',
                  calculatedDuration: 0,
                  overlapMinutes: pause?.durationPlaned,
                  hideTime: true,
                },
              };
            }

            if (
              endTimePlanned &&
              pauseStartTime!.getTime() < sessionEndTime.getTime() &&
              pauseEndtime!.getTime() > sessionEndTime.getTime()
            ) {
              const calcDuration = calcDurationBetweenDates(
                new Date(pause!.starttimePlanned!),
                sessionEndTime,
              ).abs;

              pauseTiming = {
                startTime: new Date(pauseStartTime!),
                duration: pause?.durationPlaned as number,
                durationMin: pause?.durationMin as number,
                endTime: sessionEndTime,
              };

              pauseFeedback = {
                timing: {
                  state: 'error',
                  calculatedDuration: calcDuration,
                  overlapMinutes: pause!.durationPlaned - calcDuration,
                  hideTime: false,
                },
              };
            }

            // check for overlapping pauses
            if (
              pauseIndexInContainer + 1 <= slotContainer.length - 1 &&
              slotContainer[pauseIndexInContainer + 1]?.starttimePlanned
            ) {
              if (
                pauseTiming.endTime.getTime() >
                slotContainer[
                  pauseIndexInContainer + 1
                ]!.starttimePlanned!.getTime()
              ) {
                pauseTiming = {
                  startTime: new Date(pauseStartTime!),
                  duration: pause?.durationPlaned as number,
                  durationMin: pause?.durationMin as number,
                  endTime:
                    slotContainer[pauseIndexInContainer + 1]!.starttimePlanned!,
                };

                const calcDuration = calcDurationBetweenDates(
                  pause!.starttimePlanned!,
                  slotContainer[pauseIndexInContainer + 1]!.starttimePlanned!,
                ).dif;

                pauseFeedback = {
                  timing: {
                    state: 'error',
                    calculatedDuration: calcDuration,
                    overlapMinutes: pause!.durationPlaned - calcDuration,
                    hideTime: false,
                  },
                };

                if (
                  pauseFeedback.timing!.calculatedDuration! >=
                  pause!.durationMin
                ) {
                  pauseFeedback.timing!.state = 'warning';
                }
              }
            }

            // push timing to array
            slotsTiming.push(pauseTiming);

            // push feedback to array
            slotsFeedback.push(pauseFeedback);

            // sessionEndTimeCalculated = new Date(pauseEndtime);
            sessionEndTimeCalculated = pauseTiming.endTime;

            slotContainerIndexesToDelete.push(pauseIndexInContainer);

            // remove everything we already added from slotsContainer
            // do not remove the item that didn' fit in
            slotContainer = slotContainer.filter(
              (element, index) => !slotContainerIndexesToDelete.includes(index),
            );

            // exit the loop, nothing more to do
            break;
          }
        }
        // }
      });
    }

    // II  - SORT IN THE REST IF ALL PAUSES ARE HANDLED
    if (slotContainer.length > 0) {
      // if there are still "regular" slots left add them now
      slotContainer.forEach((slot, index) => {
        // check if we have a split slot
        if (slotContainer[index]?.id!.includes('-part_')) {
          const allslotIdParts = slotContainer[index]!.id!.split('-part_');
          const baseId = allslotIdParts[0];
          const partNumber = parseInt(allslotIdParts[1], 10);

          // check if the slot has remaining time
          if (splitSlotsRemaingTime[baseId] > 0) {
            // ok, add it

            // prepare timing data
            const slotEndtime = new Date(sessionEndTimeCalculated);
            slotEndtime.setMinutes(
              slotEndtime.getMinutes() + splitSlotsRemaingTime[baseId],
            );

            // push render data to array
            slotsRenderData.push(slot);

            // push timing data to array
            const slotTiming: slotsTimingTypes = {
              startTime: new Date(sessionEndTimeCalculated),
              // startTimeLatest: slot.startdateLatest,
              duration: slot!.durationPlaned as number,
              durationMin: slot!.durationMin as number,
              endTime: slotEndtime,
            };

            // prepare feedback data
            const slotFeedback: slotsFeedbackTypes = {
              timing: {
                state: 'info',
                calculatedDuration: splitSlotsRemaingTime[baseId],
                overlapMinutes: 0,
                hideTime: false,
              },
            };

            if (endTimePlanned) {
              //
              if (slotEndtime.getTime() > sessionEndTime.getTime()) {
                slotFeedback.timing!.state = 'error';
                slotFeedback.timing!.calculatedDuration = 0;
                slotFeedback.timing!.hideTime = true;
                slotFeedback.timing!.overlapMinutes = new Date(
                  sessionEndTime.getTime() - slotEndtime.getTime(),
                ).getMinutes();

                // update the previous split parts feedback
                const allParts = allRenamedSplitSlots.filter(
                  (slot) => slot?.id!.includes(baseId),
                );

                allParts.forEach((splitPart: slotDataTypes, index: number) => {
                  if (index < allParts.length - 1) {
                    const indexInslotsRenderData = slotsRenderData.findIndex(
                      (slot) => slot?.id === splitPart?.id,
                    );

                    if (
                      slotsRenderData[indexInslotsRenderData]!.durationMin <=
                      slotsRenderData[indexInslotsRenderData]!.durationPlaned -
                        splitSlotsRemaingTime[baseId]
                    ) {
                      slotsFeedback[indexInslotsRenderData]!.timing.state =
                        'warning';
                    } else {
                      slotsFeedback[indexInslotsRenderData]!.timing.state =
                        'error';
                    }
                  }
                });
              }
            }

            // push timing data
            slotsTiming.push(slotTiming);

            // push feedback data
            slotsFeedback.push(slotFeedback);

            splitSlotsRemaingTime[baseId] = 0;

            // add time to the calculated session end time
            sessionEndTimeCalculated = new Date(slotEndtime);
          } else {
            // no more time left, delete it
            // slot won't be added, so slot order needs to be persisted
            callUpdateSlotOrder = true;
          }
        } else {
          // push render data to array
          slotsRenderData.push(slot);

          // push the timing to array
          const slotEndtime = new Date(sessionEndTimeCalculated);
          slotEndtime.setMinutes(
            slotEndtime.getMinutes() + slot!.durationPlaned,
          );

          slotsTiming.push({
            startTime: new Date(sessionEndTimeCalculated),
            // startTimeLatest: slot.startdateLatest,
            duration: slot!.durationPlaned as number,
            durationMin: slot!.durationMin as number,
            endTime: slotEndtime,
          });

          // check if the slot overlaps the session end
          let slotFeedback: slotsFeedbackTypes = undefined;

          if (endTimePlanned) {
            if (slotEndtime.getTime() > sessionEndTime.getTime()) {
              slotFeedback = {
                timing: {
                  state: 'error',
                  // calculatedDuration: calcDurationBetweenDates(
                  //   slotEndtime,
                  //   sessionEndTime,
                  // ).dif,
                  calculatedDuration: 0,
                  overlapMinutes: new Date(
                    sessionEndTime.getTime() - slotEndtime.getTime(),
                  ).getMinutes(),
                  hideTime: true,
                },
              };
            }
          }
          // push feedback to array
          slotsFeedback.push(slotFeedback);
          // add time to the calculated session end time
          sessionEndTimeCalculated = new Date(slotEndtime);
        }
      });
    }
  }

  //// III END BUFFER CREATION
  if (
    endTimePlanned &&
    sessionEndTimeCalculated.getTime() < sessionEndTime.getTime()
  ) {
    // if slotsData is empty (new session) set the default buffer
    const newBuffer = createNewBuffer(sessionEndTimeCalculated, sessionEndTime);

    // create a new Buffer element
    if (newBuffer) {
      // push buffer slot at END of arrays
      slotsRenderData.push(newBuffer.bufferData);

      // push to the timing array
      slotsTiming.push(newBuffer.bufferTiming);

      // push feedback to array
      slotsFeedback.push(newBuffer.bufferFeedback);
    }
  }

  // //// IV - Add EndOFSession Element
  // if (!endTimePlanned) {
  //   const newEosSlot = createNewEos(sessionEndTimeCalculated);
  //   slotsRenderData?.push(newEosSlot.eosData);
  //   slotsTiming.push(newEosSlot.eosTiming);
  //   slotsFeedback.push(newEosSlot.eosFeedback);
  // }

  if (!endTimePlanned) {
    const newEosSlot = createNewEos(sessionEndTimeCalculated, eosStore);
    slotsRenderData?.push(newEosSlot.eosData);
    slotsTiming.push(newEosSlot.eosTiming);
    slotsFeedback.push(newEosSlot.eosFeedback);

    // check if eos was already last item
    // if not persist new slot order

    // if (!openEosIsLast) {
    //   callUpdateSlotOrder = true;
    //   openEosIsLast = true;
    // }
  }

  //// V RETURNING FINAL DATA & OTHER UPDATES
  // if no endtime was set use the calculated endtime as planed endtime
  if (!endTimePlanned) sessionEndTime = sessionEndTimeCalculated;

  // persist the new slot order if necessary
  // ! MAY TRIGGER RERENDERS

  if (callUpdateSlotOrder) {
    // remove buffers
    const cleanedSlotList = slotsRenderData.filter(
      (slot) => !slot!.id!.includes('buffer-'),
    );

    // // remove eos slot for non fixed endtime sessions
    // if (!endTimePlanned) {
    //   cleanedSlotList = cleanedSlotList.filter(
    //     (slot) => !(slot!.type === 'eos'),
    //   );
    // }
    callUpdateSlotOrder = false;
    // finally persist the slot order
    if (!preview && persist) {
      updateSlotOrder(cleanedSlotList);
    }
  }

  // the sessionTiming obj
  const sessionTiming = {
    endTimePlanned: endTimePlanned,
    sessionStartTime: sessionStartTime,
    sessionEndTime: sessionEndTime,
    sessionEndTimeCalculated: sessionEndTimeCalculated,
  };

  return {
    sessionTiming: sessionTiming,
    slotsRenderData: slotsRenderData,
    slotsTiming: slotsTiming,
    slotsFeedback: slotsFeedback,
  };
}

// calc duration between two dates
export function calcDurationBetweenDates(
  firstDate: Date,
  secondDate: Date,
): { dif: number; abs: number } {
  // forceSameDay!!!???
  // const difference = Math.round(
  //   (secondDate.getTime() - firstDate.getTime()) / 1000 / 60,
  // );
  const difference = Math.ceil(
    (secondDate.getTime() - firstDate.getTime()) / 1000 / 60,
  );

  const differenceAbs = Math.round(
    Math.abs(secondDate.getTime() - firstDate.getTime()) / 1000 / 60,
  );

  return { dif: difference, abs: differenceAbs };
}

// create a new buffer slot in the "virtual" slots list
function createNewBuffer(startTime: Date, endTime: Date) {
  // calculate the time from end of this slot to the start of the next pause / eos
  const diffInMin = calcDurationBetweenDates(endTime, startTime);

  // create a new Buffer element
  if (diffInMin.abs >= 1 && endTime.getTime() > startTime.getTime()) {
    // slot data
    const bufferData: slotDataTypes = {
      // starttime: startTime,
      // endtime: endTime,
      durationMin: diffInMin.abs,
      durationPlaned: diffInMin.abs,
      title: 'Puffer',
      type: 'buffer',
      id:
        'buffer-' +
        performance.now().toString(36) +
        Math.random().toString(36).substr(2),
    };

    // slot timing
    const bufferTiming: slotsTimingTypes = {
      startTime: startTime,
      endTime: endTime,
      duration: diffInMin.abs,
      durationMin: diffInMin.abs,
    };

    // slot feedback
    const bufferFeedback: slotsFeedbackTypes = undefined;

    return {
      bufferData: bufferData,
      bufferTiming: bufferTiming,
      bufferFeedback: bufferFeedback,
    };
  }

  return undefined;
}

// create a new end of session slot in the "virtual" slots list
// function createNewEos(sessionEndTime: Date) {
//   // eos data
//   const eosData: slotDataTypes = {
//     // starttime: sessionEndTime,
//     starttimePlanned: sessionEndTime,
//     // endtime: sessionEndTime,
//     durationMin: 0,
//     durationPlaned: 0,
//     title: 'Ende der Session',
//     type: 'eos',
//     id: 'eos',
//     // id:
//     //   'eos-' +
//     //   performance.now().toString(36) +
//     //   Math.random().toString(36).substr(2),
//   };

//   // slot timing
//   const eosTiming: slotsTimingTypes = {
//     startTime: sessionEndTime,
//     endTime: sessionEndTime,
//     duration: 0,
//     durationMin: 0,
//   };

//   // slot feedback
//   const eosFeedback: slotsFeedbackTypes = undefined;

//   return {
//     eosData: eosData,
//     eosTiming: eosTiming,
//     eosFeedback: eosFeedback,
//   };
// }
function createNewEos(sessionEndTime: Date, eosObj: Parse.Object) {
  // eos data
  // const eosData: slotDataTypes = {
  //   // starttime: sessionEndTime,
  //   starttimePlanned: sessionEndTime,
  //   // endtime: sessionEndTime,
  //   durationMin: 0,
  //   durationPlaned: 0,
  //   title: 'Ende der Session',
  //   type: 'eos',
  //   id: 'eos',
  // };

  const eosData: slotDataTypes = {
    title: 'Ende der Session',
    ...eosObj,
    starttimePlanned: sessionEndTime,
    durationMin: 0,
    durationPlaned: 0,
    // title: 'Ende der Session',
    type: 'eos',
    id: eosObj.id,
  };

  // slot timing
  const eosTiming: slotsTimingTypes = {
    startTime: sessionEndTime,
    endTime: sessionEndTime,
    duration: 0,
    durationMin: 0,
  };

  // slot feedback
  const eosFeedback: slotsFeedbackTypes = undefined;

  return {
    eosData: eosData,
    eosTiming: eosTiming,
    eosFeedback: eosFeedback,
  };
}

// check for split slots
function findDuplicateIds(
  slotList: slotsDataTypes,
): Record<string, number[]> | void {
  if (slotList) {
    const idIndices: Record<string, number[]> = {};

    slotList.forEach((slot: slotDataTypes, index: number) => {
      if (!idIndices[slot!.id!]) {
        idIndices[slot!.id!] = [index];
      } else {
        idIndices[slot!.id!].push(index);
      }
    });

    return Object.keys(idIndices).reduce(
      (acc: Record<string, number[]>, id) => {
        if (idIndices[id].length > 1) {
          acc[id] = idIndices[id];
        }
        return acc;
      },
      {},
    );
  }
}

// automatic rejoin of invalid split slots
// (split slots that are not devided by at least one pause)
function removeInvalidSplits(slotList: slotDataTypes[]): slotDataTypes[] {
  const processed: slotDataTypes[] = [];
  let lastMainId: string | null = null;
  let lastIdIncluded: boolean = false;

  for (const obj of slotList!) {
    if (obj?.type === 'pause') {
      lastMainId = null; // Reset bei Pause
      processed.push(obj); // Pausen immer beibehalten
      continue;
    }

    const [mainId, part] = obj!.id!.split('-part_');

    if (lastMainId !== mainId || !part) {
      // Neue Haupt-ID oder kein Teil (einzelne ID), fügen Sie hinzu und setzen Sie den Zustand zurück
      processed.push(obj);
      lastMainId = mainId;
      lastIdIncluded = true;
    } else if (lastMainId === mainId && lastIdIncluded) {
      // Gleiche Haupt-ID, aber bereits ein Teil hinzugefügt und kein Pause dazwischen
      // Nichts tun (Objekt nicht hinzufügen), um Duplikate ohne Pause dazwischen zu vermeiden
      lastIdIncluded = false; // Nächstes Objekt mit gleicher Haupt-ID kann hinzugefügt werden, wenn eine Pause folgt
    } else {
      // Teil einer fortlaufenden Sequenz nach einer Pause
      processed.push(obj);
      lastIdIncluded = true;
    }
  }

  return processed;
}
