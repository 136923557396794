import {
  Box,
  IconButton,
  Popover,
  PopoverOrigin,
  Typography,
} from '@mui/material';
import { MutableRefObject } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MediaListDownloadWidget from './MediaListDownloadWidget';
import { downloadType } from '../../../api/media/downloadMediaFilesApi';

export interface MediaListDownLoadPopoverPropsTypes {
  open: boolean;
  toggleOpen: () => void;
  togglePaidOptionsModal: () => void;
  anchorRef: MutableRefObject<any>; // typing
  featurePaid: boolean;
  downloadHandler: (type: downloadType) => void;
  position?: { anchorOrigin: PopoverOrigin; transformOrigin: PopoverOrigin };
  headline?: string;
  subline?: string;
}

export default function MediaListDownloadPopover(
  props: MediaListDownLoadPopoverPropsTypes,
) {
  // props
  const {
    anchorRef,
    position = {
      anchorOrigin: { vertical: 'center', horizontal: 'center' },
      transformOrigin: { vertical: 'center', horizontal: 'center' },
    },
    headline,
    subline,

    open,
    toggleOpen,
    downloadHandler,
    featurePaid,
    togglePaidOptionsModal,
  } = props;

  return (
    <>
      <Popover
        open={open}
        onClose={toggleOpen}
        anchorEl={anchorRef.current}
        anchorOrigin={position.anchorOrigin}
        transformOrigin={position.transformOrigin}
      >
        <Box sx={{ maxWidth: '320px', p: 0 }}>
          {/* Header */}
          <Box sx={{ pt: '16px', px: '20px', position: 'relative' }}>
            {headline && <Typography variant="h4">{headline}</Typography>}

            <IconButton
              size="small"
              sx={{ p: 0, position: 'absolute', top: '10px', right: '10px' }}
              onClick={toggleOpen}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Subline */}
          {subline && (
            <Box sx={{ px: '20px', pb: '0px' }}>
              <Typography variant="caption" component="p">
                {subline}
              </Typography>
            </Box>
          )}

          <MediaListDownloadWidget
            featurePaid={featurePaid}
            downloadHandler={downloadHandler}
            togglePaidOptionsModal={togglePaidOptionsModal}
            callBackAction={toggleOpen}
          />
        </Box>
      </Popover>
    </>
  );
}
