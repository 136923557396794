import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default async function getSessionApi(sessionId: string) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'getSession',
    { sessionId: sessionId },
    { sessionToken: sessionToken },
  );
}
