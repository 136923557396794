import {
  Box,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import ActorDetailsWidget from './ActorDetailsWidget';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { actorDataTypes } from '../../Slots/Context/SlotContext';

export type ActorDetailsModalProps = {
  modalOpen: boolean;
  modalClose: () => void;
  //   actorList: actorDataTypes[];
  //   startActorId?: string;
  actorData: actorDataTypes;
  stepThroughActors: (direction: 1 | -1) => void;
};

export default function ActorDetailsModal(props: ActorDetailsModalProps) {
  // props
  const { modalOpen, modalClose, actorData, stepThroughActors } = props;

  // theme
  const theme = useTheme();

  return (
    <>
      {actorData && (
        <Modal open={modalOpen} onClose={modalClose}>
          <Paper
            sx={{
              width: { xs: '100svw', sm: '90%' },
              height: { xs: '100svh', sm: '80%' },
              maxWidth: { xs: 'unset', sm: '600px' },
              maxHeight: { xs: 'unset', sm: '80%' },
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              bgcolor: theme.palette.background.default,
              boxShadow: 'none',
              backgroundImage: 'none',
            }}
          >
            {/* <Box sx={{ position: 'relative', height: '10px' }}>
            <IconButton
              onClick={modalClose}
              sx={{ p: 0, position: 'absolute', top: 0, right: 0 }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box> */}
            <Box sx={{ borderColor: 'divider' }}>
              <Box
                sx={{
                  width: '100%',
                  height: '48px',
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  bgcolor: theme.palette.navigationBg.main,
                }}
              >
                {/* {!mediaQuery && ( */}
                <IconButton
                  onClick={modalClose}
                  sx={{
                    // pl: 0,
                    fontSize: theme.typography.body2.fontSize,
                    position: 'absolute',
                    color: theme.palette.primary.main,
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                {/* )} */}

                <Stack sx={{ width: '100%', alignItems: 'center' }}>
                  <Stack sx={{ width: '50%' }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        width: '100%',
                        textAlign: 'center',
                        color: theme.palette.text.primary,
                        lineHeight: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {actorData.get('firstname') +
                        ' ' +
                        actorData.get('lastname')}
                    </Typography>
                  </Stack>
                </Stack>

                {/* {detailsEditing === 'slots' && activeSlotData && ( */}
                <Stack
                  direction="row"
                  sx={{ position: 'absolute', right: 0 }}
                  alignItems="center"
                >
                  <IconButton
                    onClick={() => stepThroughActors(1)}
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    <ArrowBackIosIcon
                      sx={{
                        transform: 'rotate(-90deg) translateX(5px)',
                        transformOrigin: '50% 50%',
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => stepThroughActors(-1)}
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    <ArrowBackIosIcon
                      sx={{
                        transform: 'rotate(90deg) translateX(5px)',
                        transformOrigin: '50% 50%',
                      }}
                    />
                  </IconButton>
                </Stack>
                {/* )} */}
              </Box>
            </Box>
            {actorData && <ActorDetailsWidget actorData={actorData} />}
          </Paper>
        </Modal>
      )}
    </>
  );
}
