import { QueryClient, useQuery } from '@tanstack/react-query';
import getActorsSeessionApi from '../../api/actor/getActorsEventApi';
import getActorsEventApi from '../../api/actor/getActorsEventApi';

// react-query query definition
const actorsQuery = (eventId: string) => ({
  queryKey: ['session', 'actors', eventId],
  queryFn: () => getActorsEventApi(eventId),
});

export function useActorsQuery(eventId: string) {
  return useQuery(actorsQuery(eventId));
}
