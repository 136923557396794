import * as yup from 'yup';
import i18n from 'i18next';

export const eventDetailsCustomizationValidationSchema = yup.object({
  bannerImage: yup.array().of(yup.string()),
  primaryColor: yup.string(),
  secondaryColor: yup.string(),
  backgroundColor: yup.string(),
  legalText: yup.string(),
  displayLegalText: yup.boolean(),
  imprintText: yup.string(),
  displayImprintText: yup.boolean(),
  cookiesText: yup.string(),
  displayCookiesText: yup.boolean(),
  contactText: yup.string(),
  displayContactText: yup.boolean(),
  textSize: yup.string().oneOf(['small', 'medium', 'large']),
  dividerActors: yup.string().oneOf([', ', ' | ', ' - ']),
  dividerTitle: yup.string().oneOf([', ', ' | ', ' - ']),
  dateFormat: yup
    .string()
    .oneOf(['shortDot', 'mediumDot', 'longDot', 'shortSlash', 'mediumSlash']),
  timeFormat: yup
    .string()
    .oneOf(['shortDot', 'longDot', 'shortColon', 'longColon']),
  slotTitleInSecondaryColor: yup.boolean(),
  agendaHeadline: yup.string(),
  programHeadline: yup.string(),
  dateInTitle: yup.boolean(),
  locationInTitle: yup.boolean(),
  displayModeratorInAgenda: yup.boolean(),
});

export const eventDetailsCustomizationDefaults = {
  bannerImage: [],
  primaryColor: '',
  secondaryColor: '',
  backgroundColor: '',
  legalText: '',
  displayLegalText: true,
  imprintText: '',
  displayImprintText: true,
  cookiesText: '',
  displayCookiesText: true,
  contactText: '',
  displayContactText: true,
  textSize: 'medium',
  dividerActors: ' | ',
  dividerTitle: ' | ',
  dateFormat: 'shortDot',
  timeFormat: 'shortDot',
  slotTitleInSecondaryColor: false,
  agendaHeadline: 'Das ist unsere Agenda:',
  programHeadline: 'Das ist unser Programm:',
  dateInTitle: false,
  locationInTitle: false,
  // displayModeratorInAgenda: false,
};
