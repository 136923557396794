import React from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NumbersIcon from '@mui/icons-material/Numbers';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import EuroIcon from '@mui/icons-material/Euro';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import usePayment from './usePayment';

/**
 * 
 * @param props The Widget properties
 * @param props.closeHandler The function to be called when closing the dialog
 * @param props.initPaymentHandler The function to be called when closing the dialog
 * @param props.eventIds The event data from the main server, containing the paidFeatures flag
 * @param props.eventFeatures All feature available in the event active & inactive features
 * @param props.allAvailableFeatures All features available in the application
 * 
 * @returns Widget displaying the total of features to be paid with a check out button
 */
export default function ErPaymentWidget(props: ErPaymentWidgetPropsTypes) {
  // props
  const { closeHandler, initPaymentHandler, eventIds, eventFeatures, allAvailableFeatures } = props;
  // console.log('##!#-eventFeatures',eventFeatures);
  // console.log('##!#-allAvailableFeatures',allAvailableFeatures);
  

  const numberColWidth = 40;

  // payment hook
  const { featureList, total, orderList } = usePayment({
    eventIds: eventIds,
    allAvailableFeatures: allAvailableFeatures,
    eventFeatures: eventFeatures
  });

  // start the payment process
  const handlePayment = async () => {
    return initPaymentHandler({
      amount: total,
      itemsAcc: orderList,
      itemsAll: featureList,
    }).then((res) => {
        // open the payment dialog
        window.open(res, '_self');

        // close dialog
        closeHandler();
      })
      .catch((e) => console.error(e));
  };

  return (
    <>
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <DialogTitle>Event-Optionen buchen</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              closeHandler();
            }}
            size="dialogClose"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography variant="body2" sx={{ px: '20px' }}>
          basierend auf den Einstellungen in den Sessions und jederzeit flexibel
          ergänzbar
        </Typography>
      </Stack>
      <DialogContent>
        <Stack direction="row" sx={{ width: '100%', pb: '10px' }}>
          <Typography sx={{ flexGrow: 1, flexShrink: 0 }}></Typography>
          <NumbersIcon sx={{ flex: `0 0 ${numberColWidth}px` }} />
          <DifferenceOutlinedIcon sx={{ flex: `0 0 ${numberColWidth}px` }} />
          <EuroIcon sx={{ flex: `0 0 ${numberColWidth}px` }} />
        </Stack>
        <List sx={{ p: 0 }}>
          {orderList &&
            Object.keys(orderList as object).map((key, index) => {
              return (
                <ListItem
                  key={'featureToBePaid-' + index}
                  sx={{ p: 0, pb: '10px' }}
                >
                  <Stack
                    direction="row"
                    sx={{ width: '100%' }}
                    // alignItems="center"
                  >
                    <Stack sx={{ flexGrow: 1, flexShrink: 0 }}>
                      <Typography variant="body1">
                        {orderList![key].name}
                      </Typography>
                      <Typography variant="body2">
                        {orderList![key].description}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="body2"
                      sx={{
                        flex: `0 0 ${numberColWidth}px`,
                        textAlign: 'center',
                      }}
                    >
                      {orderList![key].count}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        flex: `0 0 ${numberColWidth}px`,
                        textAlign: 'center',
                      }}
                    >
                      -
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        flex: `0 0 ${numberColWidth}px`,
                        textAlign: 'center',
                      }}
                    >
                      {orderList![key].amount + ' €'}
                    </Typography>
                  </Stack>
                </ListItem>
              );
            })}
        </List>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ pb: '10px', width: '100%' }}
        >
          <Stack sx={{ flexGrow: 1, flexShrink: 0 }}>
            <Typography variant="body1">SUMME</Typography>
            <Typography variant="body2">inkl. 19% Mehrwertsteuer</Typography>
          </Stack>
          <Box
            sx={{
              flex: `0 0 ${numberColWidth}px`,
              textAlign: 'center',
            }}
          ></Box>
          <Box
            sx={{
              flex: `0 0 ${numberColWidth}px`,
              textAlign: 'center',
            }}
          ></Box>
          <Typography
            variant="body2"
            sx={{
              flex: `0 0 ${numberColWidth}px`,
              textAlign: 'center',
            }}
          >
            {/* {total + ' €'} */}
          </Typography>
        </Stack>
        <Typography variant="body2" sx={{ pb: '20px' }}>
          *Nicht genutzte bzw. angefangene Kontingente schreiben wir dem Konto
          gut. Sie können in diesem oder anderen Events verwendet werden.
        </Typography>
      </DialogContent>

      {/* Action Buttons */}
      <Stack
        direction="row"
        spacing="10px"
        sx={{ padding: '0px 10px 10px 10px' }}
      >
        <Button fullWidth variant="outlined">
          Anpassen
        </Button>
        <Button
          fullWidth
          variant="outlined"
          endIcon={<ShoppingCartCheckoutIcon />}
          onClick={handlePayment}
        >
          Bezahlen
        </Button>
      </Stack>
    </>
  );
}
