import {
  AppBar,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Switch,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { usePreviewContext } from '../Preview/PreviewContext';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useSessionsByEventIdQuery } from '../../data/session/sessions.query';
import { useTranslation } from 'react-i18next';

import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import EditIcon from '@mui/icons-material/Edit';
import usePreviewDataHandling from '../Preview/usePreviewDataHandling';
import PreviewTextEditor from '../Preview/PreviewTextEditor';
import EventSettingsModal from '../Event/EventSettingsModal';
import EventSettings from '../Event/EventSettings';

export default function PreviewNavigation() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const events = window.fsEvents;

  // theme
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  // translation
  const { t } = useTranslation('onlinePlayer');

  // get path params
  const { eventId, sessionId } = useParams();

  //// react query
  const queryClient = useQueryClient();

  // preview context
  const {
    view,
    changeViewIndex,
    activeSession,
    setActiveSession,
    eventData,
    eventDataStatus,
    reloadFromNav,
    updateReloadFromNav,
  } = usePreviewContext();

  // multisession dependend settings
  const multiSession = eventData.get('multiSession');
  const [agendaLabel, setAgendaLabel] = useState<
    'agenda' | 'sessionAgenda' | 'program'
  >('agenda');
  const [additionalTextLabel, setAdditionalTextLabel] = useState<
    'zusatztext' | 'sessiontext'
  >('zusatztext');

  const { data: sessionsData, status: sessionsDataStatus } =
    useSessionsByEventIdQuery(eventId!);

  // sessions select options
  const [sessionsOptions, setSessionsOptions] = useState([
    { value: '', label: '' },
  ]);

  // iFrame
  const [iframeSrc, setIframeSrc] = useState();

  // settings handling
  const [eventTextDisplay, setEventTextDisplay] = useState(false);
  const [additionalTextDisplay, setAdditionalTextDisplay] = useState(false);
  const [agendaDisplay, setAgendaDisplay] = useState(false);
  const [countdownDisplay, setCountdownDisplay] = useState(false);

  const [eventText, setEventText] = useState('');
  const [additionalText, setAdditionalText] = useState('');

  const handleSwitchChange = (
    element:
      | 'eventText'
      | 'additionalText'
      | 'agenda'
      | 'program'
      | 'countdown',
    value: boolean,
  ) => {
    switch (element) {
      case 'eventText':
        setEventTextDisplay(value);
        break;
      case 'additionalText':
        setAdditionalTextDisplay(value);
        break;
      case 'agenda':
        setAgendaDisplay(value);
        break;
      case 'countdown':
        setCountdownDisplay(value);
        break;
    }

    updateTextDisplay({ view, element, value, sessionId: activeSession });
  };

  // text editor handling
  const [textEditorOpen, setTextEditorOpen] = useState(false);
  const [textToEdit, setTextToEdit] = useState('');
  const [textTypeToEdit, setTextTypeToEdit] = useState('');

  const toggleTextEditorOpen = (element: string) => {
    setTextTypeToEdit(element);
    setTextToEdit(element === 'eventText' ? eventText : additionalText);
    setTextEditorOpen(!textEditorOpen);
  };

  // event settings handling
  const [eventSettingsOpen, setEventSettingsOpen] = useState(false);
  const toggleEventSettingsModal = () =>
    setEventSettingsOpen(!eventSettingsOpen);

  const submitCallbackHandler = (state: 'success' | 'error') => {
    if (state === 'success') {
      toggleEventSettingsModal();
      reloadPreview();
    }
  };

  // reload fct
  const reloadPreview = () => {
    const iframeNode: any = document.getElementById('bs_PREVIEW_PLAYER');
    iframeNode.src = iframeNode.src;

    updateReloadFromNav(true);
  };

  // preview data mutation hook
  const { updateTextDisplay, getTextDisplay, getText, updateText } =
    usePreviewDataHandling({
      reloadPreview: reloadPreview,
      sessionsData,
    });

  useEffect(() => {
    // set the iframe source
    const iframeNode: any = document.getElementById('bs_PREVIEW_PLAYER');
    if (iframeNode) setIframeSrc(iframeNode.src.split('&')[0]);
  }, []);

  // set sessions select options
  useEffect(() => {
    if (sessionsDataStatus === 'success') {
      const options = sessionsData.map((ses: any) => {
        console.log('##-sessionsData', sessionsData);

        return {
          value: ses.id,
          label: ses.get('title'),
          active: ses.get('scene').active,
        };
      });

      setSessionsOptions(options);
      setActiveSession(options?.[0]?.value);
    }
  }, [sessionsDataStatus]);

  // set view specific params
  useEffect(() => {
    // update the agenda / program & additional text label
    if (
      multiSession &&
      (view === 'announcement' ||
        view === 'registration' ||
        view === 'confirm' ||
        view === 'welcome' ||
        view === 'lobby' ||
        view === 'closed')
    ) {
      setAgendaLabel('program');
    } else {
      if (multiSession) {
        setAgendaLabel('sessionAgenda');
      } else {
        setAgendaLabel('agenda');
      }
    }

    // update the display settings
    if (
      view === 'announcement' ||
      view === 'registration' ||
      view === 'confirm' ||
      view === 'welcome' ||
      view === 'lobby'
    ) {
      // set addtional text label
      setAdditionalTextLabel('zusatztext');

      //
      setEventTextDisplay(getTextDisplay({ view: view, element: 'eventText' }));
      setAdditionalTextDisplay(
        getTextDisplay({ view: view, element: 'additionalText' }),
      );
      setAgendaDisplay(getTextDisplay({ view: view, element: 'agenda' }));
      setCountdownDisplay(getTextDisplay({ view: view, element: 'countdown' }));
    }

    if (view === 'live' || view === 'sessionWelcome') {
      // set addtional text label
      setAdditionalTextLabel('sessiontext');

      //
      setAdditionalTextDisplay(
        getTextDisplay({
          view: view,
          element: 'additionalText',
          sessionId: activeSession,
        }),
      );
      setAgendaDisplay(
        getTextDisplay({
          view: view,
          element: 'agenda',
          sessionId: activeSession,
        }),
      );
    }

    if (view === 'closed') {
      // set addtional text label
      setAdditionalTextLabel('zusatztext');

      //
      setEventTextDisplay(getTextDisplay({ view: view, element: 'eventText' }));
      setAdditionalTextDisplay(
        getTextDisplay({ view: view, element: 'additionalText' }),
      );
    }
  }, [view, activeSession]);
  // }, [view, eventData, activeSession]);
  // rerendering on evenData update might cause temporary false display...

  useEffect(() => {
    if (
      view === 'announcement' ||
      view === 'registration' ||
      view === 'confirm' ||
      view === 'welcome' ||
      view === 'lobby'
    ) {
      setEventText(getText({ view: view, element: 'eventText' }));
      setAdditionalText(getText({ view: view, element: 'additionalText' }));
    }

    if (view === 'live' || view === 'sessionWelcome') {
      setAdditionalText(
        getText({
          view: view,
          element: 'additionalText',
          sessionId: activeSession,
        }),
      );
    }

    if (view === 'closed') {
      setAdditionalText(getText({ view: view, element: 'additionalText' }));
    }
  }, [view, activeSession, eventData, sessionsData]);

  return (
    <>
      {eventDataStatus === 'success' && sessionsDataStatus === 'success' && (
        <>
          <AppBar
            position="fixed"
            sx={{
              boxShadow: 'none',
              zIndex: (theme) => {
                return theme.zIndex.drawer + 1;
              },
            }}
          >
            <Toolbar
              sx={{
                px: {
                  xs: 1,
                  sm: 1,
                },
                py: isMobile ? '8px' : '',
                maxHeight: isMobile ? '160px' : '56px',
                height: isMobile ? '160px' : 'unset',
                alignItems: isMobile ? 'flex-start' : 'center',
                overflow: 'auto',
              }}
            >
              <Stack
                direction={isMobile ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%' }}
                spacing={isMobile ? 1 : 0}
              >
                {/* left group */}
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      ml: { xs: 0, sm: '18px' },
                    }}
                    variant="logo"
                  >
                    Britestage
                  </Typography>

                  <Typography variant="body1">
                    {t('PREVIEW.SUBLINE')}
                  </Typography>

                  <IconButton
                    color="primary"
                    onClick={() => {
                      // events?.publish('toggleEventMenu', {
                      //   activateSection: 'customization',
                      //   singleForm: 'customization',
                      // });
                      toggleEventSettingsModal();
                    }}
                  >
                    <VideoSettingsIcon />
                  </IconButton>

                  {isMobile && (
                    <IconButton onClick={reloadPreview}>
                      <CachedOutlinedIcon />
                    </IconButton>
                  )}
                </Stack>

                {/* middle group */}
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    position: isMobile ? 'relative' : 'absolute',
                    left: isMobile ? '0' : '50%',
                    transform: isMobile ? '' : 'translateX(-50%)',
                  }}
                >
                  <IconButton onClick={() => changeViewIndex(-1)}>
                    <ArrowBackIosNewIcon />
                  </IconButton>

                  {sessionsOptions.length > 1 &&
                  (view === 'sessionWelcome' || view === 'live') ? (
                    <>
                      <FormControl sx={{ minWidth: '140px' }}>
                        <InputLabel id={'select-label-sessionSelectLabel'}>
                          {t(`PREVIEW.VIEWS.${view}`)}
                        </InputLabel>
                        <Select
                          labelId={'select-label-sessionSelectLabel'}
                          label={t(`PREVIEW.VIEWS.${view}`)}
                          size="small"
                          variant="outlined"
                          value={activeSession}
                          onChange={(e) => setActiveSession(e.target.value)}
                        >
                          {sessionsOptions.map((ses: any, index: number) => (
                            <MenuItem
                              key={'session-' + index}
                              value={ses.value}
                              disabled={!ses.active}
                            >
                              {ses.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <Typography sx={{ minWidth: '140px', textAlign: 'center' }}>
                      {t(`PREVIEW.VIEWS.${view}`)}
                    </Typography>
                  )}

                  <IconButton onClick={() => changeViewIndex(1)}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Stack>

                {/* right group */}
                {isMobile ? (
                  // MOBILE
                  <Stack direction={'row'} spacing={2}>
                    {/* Container */}
                    <Stack
                      direction={
                        view !== 'live' && view !== 'sessionWelcome'
                          ? 'row'
                          : 'column-reverse'
                      }
                      spacing={
                        view !== 'live' && view !== 'sessionWelcome' ? 4 : 0
                      }
                    >
                      {/* UPPER ROW */}
                      <Stack spacing={0} direction={'column'}>
                        {/* UPPER ROW-LEFT */}
                        {view !== 'live' && view !== 'sessionWelcome' && (
                          <Stack direction="row">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={eventTextDisplay}
                                  size="small"
                                  onChange={(e) =>
                                    handleSwitchChange(
                                      'eventText',
                                      e.target.checked,
                                    )
                                  }
                                />
                              }
                              label={t('PREVIEW.MENU.SWITCH_eventtext')}
                            />
                            <IconButton
                              sx={{ p: 0 }}
                              onClick={() => toggleTextEditorOpen('eventText')}
                            >
                              <EditIcon sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                          </Stack>
                        )}
                        {/* UPPER ROW-RIGTH */}
                        {view !== 'closed' && view !== 'lobby' && (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={agendaDisplay}
                                size="small"
                                onChange={(e) =>
                                  handleSwitchChange('agenda', e.target.checked)
                                }
                              />
                            }
                            label={t(`PREVIEW.MENU.SWITCH_${agendaLabel}`)}
                          />
                        )}
                      </Stack>

                      {/* LOWER ROW */}
                      {/* {view !== 'closed' && view !== 'lobby' && ( */}
                      <Stack spacing={0} direction={'column'}>
                        {/* LOWER ROW-LEFT */}
                        <Stack direction="row">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={additionalTextDisplay}
                                size="small"
                                onChange={(e) =>
                                  handleSwitchChange(
                                    'additionalText',
                                    e.target.checked,
                                  )
                                }
                              />
                            }
                            label={t(
                              `PREVIEW.MENU.SWITCH_${additionalTextLabel}`,
                            )}
                          />
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={() =>
                              toggleTextEditorOpen('additionalText')
                            }
                          >
                            <EditIcon sx={{ fontSize: '1.2rem' }} />
                          </IconButton>
                        </Stack>
                        {/* LOWER ROW-RIGTH */}
                        {view !== 'live' &&
                          view !== 'sessionWelcome' &&
                          view !== 'welcome' &&
                          view !== 'lobby' &&
                          view !== 'closed' && (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={countdownDisplay}
                                  size="small"
                                  onChange={(e) =>
                                    handleSwitchChange(
                                      'countdown',
                                      e.target.checked,
                                    )
                                  }
                                />
                              }
                              label={t('PREVIEW.MENU.SWITCH_countdown')}
                            />
                          )}
                      </Stack>
                      {/* )} */}
                    </Stack>
                  </Stack>
                ) : (
                  // DESKTOP
                  <Stack direction={'row'} spacing={2}>
                    <Stack
                      direction={
                        view !== 'live' && view !== 'sessionWelcome'
                          ? 'row'
                          : 'column'
                      }
                      spacing={
                        view !== 'live' && view !== 'sessionWelcome' ? 4 : 0
                      }
                    >
                      <Stack spacing={0} direction={'column'}>
                        {view !== 'live' && view !== 'sessionWelcome' && (
                          <Stack direction="row">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={eventTextDisplay}
                                  size="small"
                                  onChange={(e) =>
                                    handleSwitchChange(
                                      'eventText',
                                      e.target.checked,
                                    )
                                  }
                                />
                              }
                              label={t('PREVIEW.MENU.SWITCH_eventtext')}
                            />
                            <IconButton
                              sx={{ p: 0 }}
                              onClick={() => toggleTextEditorOpen('eventText')}
                            >
                              <EditIcon sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                          </Stack>
                        )}

                        <Stack direction="row">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={additionalTextDisplay}
                                size="small"
                                onChange={(e) =>
                                  handleSwitchChange(
                                    'additionalText',
                                    e.target.checked,
                                  )
                                }
                              />
                            }
                            label={t(
                              `PREVIEW.MENU.SWITCH_${additionalTextLabel}`,
                            )}
                          />
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={() =>
                              toggleTextEditorOpen('additionalText')
                            }
                          >
                            <EditIcon sx={{ fontSize: '1.2rem' }} />
                          </IconButton>
                        </Stack>
                      </Stack>

                      {view !== 'closed' && view !== 'lobby' && (
                        <Stack spacing={0} direction={'column'}>
                          {view !== 'live' &&
                            view !== 'sessionWelcome' &&
                            view !== 'welcome' && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={countdownDisplay}
                                    size="small"
                                    onChange={(e) =>
                                      handleSwitchChange(
                                        'countdown',
                                        e.target.checked,
                                      )
                                    }
                                  />
                                }
                                label={t('PREVIEW.MENU.SWITCH_countdown')}
                              />
                            )}
                          <FormControlLabel
                            control={
                              <Switch
                                checked={agendaDisplay}
                                size="small"
                                onChange={(e) =>
                                  handleSwitchChange('agenda', e.target.checked)
                                }
                              />
                            }
                            label={t(`PREVIEW.MENU.SWITCH_${agendaLabel}`)}
                          />
                        </Stack>
                      )}
                    </Stack>

                    {/* reload button */}

                    <IconButton onClick={reloadPreview}>
                      <CachedOutlinedIcon />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
            </Toolbar>
          </AppBar>

          {/* Texteditor Modal */}
          <Modal open={textEditorOpen} onClose={toggleTextEditorOpen}>
            <Paper
              sx={{
                // width: '480px',
                // height: '320px',
                width: '100%',
                height: '100%',
                minWidth: '320px',
                minHeight: '320px',
                maxWidth: '90svw',
                maxHeight: '80svh',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }}
            >
              <PreviewTextEditor
                text={textToEdit}
                closeHandler={() => toggleTextEditorOpen(textTypeToEdit)}
                submitHandler={(value: string) => {
                  updateText({
                    view: view,
                    value: value,
                    element: textTypeToEdit as 'eventText' | 'additionalText',
                    sessionId: activeSession,
                  });
                  toggleTextEditorOpen(textTypeToEdit);
                }}
              />
            </Paper>
          </Modal>

          <Modal
            open={eventSettingsOpen}
            onClose={toggleEventSettingsModal}
            keepMounted
          >
            <EventSettings
              handleCancel={toggleEventSettingsModal}
              singleForm={'customization'}
              activateSection={'customization'}
              submitCallback={submitCallbackHandler}
            />
          </Modal>
        </>
      )}
    </>
  );
}
