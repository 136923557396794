import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Stack,
  Box,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ErCheckbox,
  ErDatePicker,
  ErHtmlEditor,
  ErSwitch,
  ErTextField,
} from 'eventrocket-commoncomponents';
import {
  eventDetailsFeatureRegistrationValidationSchema,
  eventDetailsFeatureRegistrationDefaults,
} from './eventDetailsFeatureRegistrationValidationSchema';
import { ErDateTimePicker } from 'eventrocket-commoncomponents';
import { default as Grid } from '@mui/material/Unstable_Grid2/Grid2';
import { forEach } from 'lodash';

import { htmlEditorDefaultConfig } from '../../../util/htmlEditor/htmlEditorDefaultConfig';

type EventDetailsFormLayoutFeaturesRegistrationPropsTypes = {
  featureState?: boolean;
};
export default function EventDetailsFormLayoutFeaturesRegistration(
  props: EventDetailsFormLayoutFeaturesRegistrationPropsTypes,
) {
  // props
  const { featureState = false } = props;

  // media query
  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  // event details context
  const { registrationData, updateRegistrationData } =
    useEventDetailsFormData();

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsFeatureRegistrationValidationSchema),
    defaultValues: {
      ...eventDetailsFeatureRegistrationDefaults,
      ...registrationData?.data,
    },
  });

  const watch = useWatch({ control });
  const watchEmbed = useWatch({ name: 'embed', control });
  const watchFields = useWatch({ name: 'fieldDefinition', control });
  const watchMailConfirm = useWatch({ name: 'confirmEmail', control });

  // state
  const [embedActive, setEmbedActive] = useState(false);

  //
  const getSwitchActive = (fieldName: string) => {
    const activeState = getValues(`${fieldName}.active`);
    if (!activeState) {
      setValue(`${fieldName}.required`, false);
    }

    return activeState;
  };

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  useEffect(() => {
    // save changed form data to context
    if (!firstRun) {
      updateRegistrationData(getValues(), featureState ? featureState : false);
    } else {
      setFirstRun(false);
    }
  }, [watch, featureState]);

  useEffect(() => {
    setEmbedActive(getValues('embed'));
  }, [watchEmbed]);

  //// side effects
  // reset required state of field if field itself is not active
  useEffect(() => {
    if (!firstRun) {
      for (const key in watchFields) {
        if (watchFields.hasOwnProperty(key)) {
          if (!watchFields[key].active && watchFields[key].required) {
            setValue(`fieldDefinition.${key}.required`, false);
          }
        }
      }
    }
  }, [watchFields]);

  return (
    <Stack sx={{ mt: 2 }}>
      <Accordion disabled={!featureState}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="basic-options"
          id="basic-options-header"
        >
          Basic Settings
        </AccordionSummary>
        <AccordionDetails>
          <ErDateTimePicker
            disabled={!featureState}
            fullWidth
            textFieldSize="small"
            control={control}
            fieldLabel="Registrierung ab"
            fieldName="registrationStarts"
            sx={{
              mb: 2,
              width: 'fit-content',
              minWidth: '200px',
              maxWidth: '200px',
            }}
            error={
              errors.registrationStarts &&
              getErrorMessage(errors, 'registrationStarts')
            }
          />

          <ErSwitch
            disabled={!featureState}
            control={control}
            fieldName="confirmEmail"
            fieldLabel="Email Adresse bestätigen"
            size="small"
            labelPos="end"
            sx={{ mb: 2 }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion disabled={!featureState}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="registration-options"
          id="registration-options-header"
        >
          Felder
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: 'grid',
              columnGap: 1,
              gridTemplateColumns: 'max-content max-content',
            }}
          >
            {/* firstname */}
            <ErSwitch
              disabled
              control={control}
              fieldName="fieldDefinition.firstname.active"
              fieldLabel="Vorname"
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              disabled
              control={control}
              fieldName="fieldDefinition.firstname.required"
              fieldLabel="Pflicht"
              size="small"
              sx={{ mb: 2 }}
            />

            {/* lastname */}
            <ErSwitch
              disabled
              control={control}
              fieldName="fieldDefinition.lastname.active"
              fieldLabel="Nachname"
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              disabled
              control={control}
              fieldName="fieldDefinition.lastname.required"
              fieldLabel="Pflicht"
              size="small"
              sx={{ mb: 2 }}
            />

            {/* email */}
            <ErSwitch
              disabled
              control={control}
              fieldName="fieldDefinition.email.active"
              fieldLabel="E-Mail"
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              disabled
              control={control}
              fieldName="fieldDefinition.email.required"
              fieldLabel="Pflicht"
              size="small"
              sx={{ mb: 2 }}
            />

            {/* phone */}
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldName="fieldDefinition.phone.active"
              fieldLabel="Telephone"
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              disabled={
                !featureState || !getValues('fieldDefinition.phone.active')
              }
              control={control}
              fieldName="fieldDefinition.phone.required"
              fieldLabel="Pflicht"
              size="small"
              sx={{ mb: 2 }}
            />

            {/* organisation */}
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldName="fieldDefinition.organisation.active"
              fieldLabel="Organisation"
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              disabled={
                !featureState ||
                !getValues('fieldDefinition.organisation.active')
              }
              control={control}
              fieldName="fieldDefinition.organisation.required"
              fieldLabel="Pflicht"
              size="small"
              sx={{ mb: 2 }}
            />

            {/* Address */}
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldName="fieldDefinition.address.active"
              fieldLabel="Adresse"
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              disabled={
                !featureState || !getValues('fieldDefinition.address.active')
              }
              control={control}
              fieldName="fieldDefinition.address.required"
              fieldLabel="Pflicht"
              size="small"
              sx={{ mb: 2, py: 0 }}
            />

            {/* certificate */}
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldName="fieldDefinition.certificate.active"
              fieldLabel="Teilnahmebestätigung"
              labelPos="end"
              size="small"
              sx={{ mb: 2 }}
            />

            <ErCheckbox
              // disabled={
              //   !featureState ||
              //   !getValues('fieldDefinition.certificate.active')
              // }
              disabled
              control={control}
              fieldName="fieldDefinition.certificate.required"
              fieldLabel="Pflicht"
              size="small"
              sx={{ mb: 2, opacity: 0 }}
            />

            {/* memberId */}
            <Collapse
              in={getValues('fieldDefinition.certificate.active')}
              // sx={{ width: 'fit-content' }}
            >
              <ErSwitch
                disabled={!featureState}
                control={control}
                fieldName="fieldDefinition.memberId.active"
                fieldLabel="Mitgliedsnummer"
                labelPos="end"
                size="small"
                sx={{ mb: 2 }}
              />
            </Collapse>
            <Collapse
              in={getValues('fieldDefinition.certificate.active')}
              // sx={{ width: 'fit-content' }}
            >
              <ErCheckbox
                disabled={
                  !featureState || !getValues('fieldDefinition.memberId.active')
                }
                control={control}
                fieldName="fieldDefinition.memberId.required"
                fieldLabel="Pflicht"
                size="small"
                sx={{ mb: 2 }}
              />
            </Collapse>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* <Accordion disabled={!featureState}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="registration-options"
          id="registration-options-header"
        >
          Registrierungsseite
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: 'grid',
              columnGap: '10px',
              gridTemplateColumns: 'repeat(auto-fill, minmax(150px, auto));',
            }}
          >
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldName="showEventText"
              fieldLabel="Event-Text"
              labelPos="end"
              size="small"
            />
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldName="showAgenda"
              fieldLabel="Programm"
              labelPos="end"
              size="small"
            />
          </Box>

          <ErSwitch
            disabled={!featureState}
            control={control}
            fieldName="showRegistrationText"
            fieldLabel="Zusatztext"
            labelPos="end"
            size="small"
            sx={{ mb: 1 }}
          />

          <ErHtmlEditor
            fullWidth
            control={control}
            editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
            // fieldLabel="Registrierungstext"
            fieldName="registrationText"
            sx={{ mb: 2 }}
            error={
              errors.registrationText &&
              getErrorMessage(errors, 'registrationText')
            }
            onChangeCallback={() =>
              registrationData &&
              !registrationData?.isDirty &&
              updateRegistrationData({}, registrationData.active, true)
            }
            language="de"
          />
        </AccordionDetails>
      </Accordion> */}

      {/* {watchMailConfirm && (
        <Accordion disabled={!featureState}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="felder-options"
            id="felder-options-header"
          >
            Bestätigungsseite
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: 'grid',
                columnGap: '10px',
                gridTemplateColumns: 'repeat(auto-fill, minmax(150px, auto));',
              }}
            >
              <ErSwitch
                disabled={!featureState}
                control={control}
                fieldName="showEventTextConfirmation"
                fieldLabel="Event-Text"
                labelPos="end"
                size="small"
              />

              <ErSwitch
                disabled={!featureState}
                control={control}
                fieldName="showAgendaConfirmation"
                fieldLabel="Agenda anzeigen"
                labelPos="end"
                size="small"
              />
            </Box>

            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldName="showConfirmationTextConfirmation"
              fieldLabel="Zusatztext"
              labelPos="end"
              size="small"
              sx={{ mb: 1 }}
            />

            <ErHtmlEditor
              fullWidth
              control={control}
              editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
              // fieldLabel="Bestätigungstext"
              fieldName="confirmationText"
              sx={{ mb: 2 }}
              error={
                errors.confirmationText &&
                getErrorMessage(errors, 'confirmationText')
              }
              onChangeCallback={() =>
                registrationData &&
                !registrationData?.isDirty &&
                updateRegistrationData({}, registrationData.active, true)
              }
              language="de"
            />
          </AccordionDetails>
        </Accordion>
      )} */}

      {/* <Accordion disabled={!featureState}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="felder-options"
          id="felder-options-header"
        >
          Willkomensseite
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: 'grid',
              columnGap: '10px',
              gridTemplateColumns: 'repeat(auto-fill, minmax(150px, auto));',
            }}
          >
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldName="showEventTextWelcome"
              fieldLabel="Event-Text"
              labelPos="end"
              size="small"
            />
            <ErSwitch
              disabled={!featureState}
              control={control}
              fieldName="showAgendaWelcome"
              fieldLabel="Agenda"
              labelPos="end"
              size="small"
            />
          </Box>

          <ErSwitch
            disabled={!featureState}
            control={control}
            fieldName="showWelcomeTextWelcome"
            fieldLabel="Zusatztext"
            labelPos="end"
            size="small"
            sx={{ mb: 1 }}
          />

          <ErHtmlEditor
            fullWidth
            control={control}
            editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
            // fieldLabel="Willkomenstext"
            fieldName="welcomeText"
            sx={{ mb: 2 }}
            error={errors.welcomeText && getErrorMessage(errors, 'welcomeText')}
            onChangeCallback={() =>
              registrationData &&
              !registrationData?.isDirty &&
              updateRegistrationData({}, registrationData.active, true)
            }
            language="de"
          />
        </AccordionDetails>
      </Accordion> */}
    </Stack>
  );
}
