/**
 * A function that initiates a file download from a given url
 * @param filePath url of the file to download
 */
// export function downloadFile(filePath: string, fileName?: string) {
//   // if (!fileName) fileName = '';

//   const downloadLink = document.createElement('a');
//   downloadLink.setAttribute('download', '');
//   downloadLink.href = filePath;
//   document.body.appendChild(downloadLink);
//   downloadLink.click();
//   downloadLink.remove();
// }

export async function downloadFile(filePath: string, fileName?: string) {
  if (!fileName) fileName = '';

  try {
    const response = await fetch(filePath);
    if (!response.ok) {
      throw new Error(`Failed to fetch file from ${filePath}`);
    }
    const blob = await response.blob();
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}
