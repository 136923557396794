import * as yup from 'yup';
import i18n from 'i18next';

export const eventDetailsFeatureOnlineStageValidationSchema = yup.object({
  embed: yup.boolean(),
  eventUrl: yup.string(),
  accessCode: yup.string(),
  autoStartEvent: yup.boolean(),
  openingTimes: yup.array().of(
    yup.object().shape({
      startTime: yup.string().required('Start time is required'),
    }),
  ),
  starttimeLobby: yup.date(),
  playerText: yup.string(),
  showAgenda: yup.boolean(),
  showEventText: yup.boolean(),
  showCountDown: yup.boolean(),
  showLobbyText: yup.boolean(),
  showAgendaFarewell: yup.boolean(),
  showEventTextFarewell: yup.boolean(),
  showCountDownFarewell: yup.boolean(),
  showFarewellText: yup.boolean(),
  fallbackScene: yup.string(),
  farewellText: yup.string(),
});

export const eventDetailsFeatureOnlineStageDefaults = {
  embed: false,
  eventUrl: '',
  accessCode: '',
  autoStartEvent: false,
  openingTimes: [],
  starttimeLobby: new Date(),
  playerText: '',
  showAgenda: false,
  showEventText: false,
  showCountDown: false,
  showLobbyText: true,
  showAgendaFarewell: false,
  showEventTextFarewell: false,
  showCountDownFarewell: false,
  showFarewellText: true,
  fallbackScene: '',
  farewellText: '',
};
