import { QueryClient, useMutation } from '@tanstack/react-query';
import { slotDefaults } from '../Form/updateSlotValidationSchema';
import {
  deleteSlotApi,
  getUserRolesApi,
  setNewSlotApi,
  updateSlotApi,
} from '../../../api';

type addNewSlotParamsTypes = {
  slotData?: {
    title?: string;
    durationPlaned?: number;
    type?: string;
  };
  sessionId: string;
  eventId: string;
  addAtIndex?: number | undefined;
};

export async function addNewSlotMutation(params: addNewSlotParamsTypes) {
  // params
  const { slotData, sessionId, eventId, addAtIndex } = params;

  const newSlot = { ...slotDefaults, ...slotData };
  newSlot.sessionId = sessionId;

  const slot = setNewSlotApi(newSlot, eventId!, addAtIndex);

  return slot;
}

// type updateSlotParamsTypes = object

export async function updateSlotMutation(params: any) {
  return updateSlotApi(params.slotData);
}

type deleteNewSlotParamsTypes = {
  queryClient?: QueryClient;
  slotId: string;
};

export async function deleteNewSlotMutation(params: deleteNewSlotParamsTypes) {
  const { queryClient, slotId } = params;

  // delete slot on server
  return deleteSlotApi(slotId);
  // .then((res) => {
  //   //   setFeedback({
  //   //     message: 'Löschen erfolgreich',
  //   //     type: 'success',
  //   //     autoclose: true,
  //   //   });
  // })
  // .catch((e) => {
  //   console.error(e);
  //   return e;
  //   //   setFeedback({
  //   //     headline: 'Löschen fehlgeschlagen',
  //   //     message: e.message,
  //   //     type: 'error',
  //   //     autoclose: true,
  //   //   });
  // });

  //   // unset active slot data, so it will be updated to
  //   // first item on rq slots reload
  //   setActiveSlotData(undefined);

  //   // invalidate rq cache to display new data in ui
  //   queryClient.invalidateQueries({ queryKey: ['slots', 'list', sessionId] });

  //   // publish the new item (to set dnd list focus to it)
  //   slotsData &&
  //     events?.publish('slotListItemsChange', {
  //       newSlotListItem: (slotsData[0] as UpdateSlotDataTypes).id,
  //     });
}
