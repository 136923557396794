import { useForm, useWatch } from 'react-hook-form';
import { useEventDetailsFormData } from './EventDetailsFormContext';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
} from '@mui/material';
import {
  ErHtmlEditor,
  ErSwitch,
  ErTextField,
} from 'eventrocket-commoncomponents';
import {
  _TextField,
  _DateTimePicker,
  _FileUpload,
  _ColorPicker,
} from '../../GeneralUI/FormElements';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../../util/formHelpers';
import { yupResolver } from '@hookform/resolvers/yup';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  eventDetailsCustomizationValidationSchema,
  eventDetailsCustomizationDefaults,
} from './eventDetailsCustomizationValidationSchema';

import { htmlEditorDefaultConfig } from '../../../util/htmlEditor/htmlEditorDefaultConfig';

export default function EventDetailsFormLayoutLegal() {
  // props

  // translation
  const { t } = useTranslation('event');
  // event details context
  const { customizationData, updateCustomizationData } =
    useEventDetailsFormData();

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventDetailsCustomizationValidationSchema),
    defaultValues: {
      ...eventDetailsCustomizationDefaults,
      ...customizationData?.data,
    },
  });

  const watch = useWatch({ control });

  // state
  const [firstRun, setFirstRun] = useState(true);

  //// side effects
  useEffect(() => {
    // save changed form data to context

    if (!firstRun) {
      updateCustomizationData(getValues());
    } else {
      setFirstRun(false);
    }
  }, [watch]);

  return (
    <>
      <Stack sx={{ mt: 2 }}>
        <ErSwitch
          control={control}
          fieldName="displayLegalText"
          fieldLabel={t('DETAILS-FORM.SECTION.LEGAL.CHECKBOX_displayLegalText')}
          labelPos="end"
          size="small"
          sx={{ mb: 1 }}
        />

        <ErHtmlEditor
          control={control}
          editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
          // fieldLabel={t('DETAILS-FORM.SECTION.LEGAL.FIELD_legalText')}
          fieldName="legalText"
          sx={{ mb: 2 }}
          error={errors.legalText && getErrorMessage(errors, 'legalText')}
          onChangeCallback={() =>
            !customizationData?.isDirty && updateCustomizationData({}, true)
          }
          language="de"
        />

        <ErSwitch
          control={control}
          fieldName="displayImprintText"
          fieldLabel={t(
            'DETAILS-FORM.SECTION.LEGAL.CHECKBOX_displayImprintText',
          )}
          labelPos="end"
          size="small"
          sx={{ mb: 1 }}
        />

        <ErHtmlEditor
          control={control}
          editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
          // fieldLabel={t('DETAILS-FORM.SECTION.LEGAL.FIELD_imprintText')}
          fieldName="imprintText"
          sx={{ mb: 2 }}
          error={errors.imprintText && getErrorMessage(errors, 'imprintText')}
          onChangeCallback={() =>
            !customizationData?.isDirty && updateCustomizationData({}, true)
          }
          language="de"
        />

        <ErSwitch
          control={control}
          fieldName="displayCookiesText"
          fieldLabel={t(
            'DETAILS-FORM.SECTION.LEGAL.CHECKBOX_displayCookiesText',
          )}
          labelPos="end"
          size="small"
          sx={{ mb: 1 }}
        />

        <ErHtmlEditor
          control={control}
          editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
          // fieldLabel={t('DETAILS-FORM.SECTION.LEGAL.FIELD_cookiesText')}
          fieldName="cookiesText"
          sx={{ mb: 2 }}
          error={errors.cookiesText && getErrorMessage(errors, 'cookiesText')}
          onChangeCallback={() =>
            !customizationData?.isDirty && updateCustomizationData({}, true)
          }
          language="de"
        />

        <ErSwitch
          control={control}
          fieldName="displayContactText"
          fieldLabel={t(
            'DETAILS-FORM.SECTION.LEGAL.CHECKBOX_displayContactText',
          )}
          labelPos="end"
          size="small"
          sx={{ mb: 1 }}
        />

        <ErHtmlEditor
          control={control}
          editorConfig={{ ...htmlEditorDefaultConfig, theme: 'bsDark' }}
          // fieldLabel={t('DETAILS-FORM.SECTION.LEGAL.FIELD_contactText')}
          fieldName="contactText"
          sx={{ mb: 2 }}
          error={errors.contactText && getErrorMessage(errors, 'contactText')}
          onChangeCallback={() =>
            !customizationData?.isDirty && updateCustomizationData({}, true)
          }
          language="de"
        />
      </Stack>
    </>
  );
}
