import Parse from 'parse';
import { MediaUpdateTypes } from '../../Components/Slots/Slots';
import useAuthStore from '../../store/authStore';

export default function updateMediaApi(
  updateData: MediaUpdateTypes,
  eventId: string | undefined,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'updateMedia',
    { ...updateData, eventId: eventId },
    { sessionToken: sessionToken },
  );
}
