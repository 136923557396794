import { Box, Modal } from '@mui/material';
import useUserPermissions from '../Hooks/useUserPermissions';
import { Suspense, useEffect, useRef, useState } from 'react';
import EventSettings from './EventSettings';
import { ErLoadingIndicator } from 'eventrocket-commoncomponents';

type EventSettingsModalProps = {
  submitCallback?: (state: 'success' | 'error') => void;
};

export default function EventSettingsModal(props: EventSettingsModalProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const events = window.fsEvents;

  // props
  const { submitCallback } = props;

  //
  const [firstRun, setFirstRun] = useState(true);

  // toogle settings modal
  const [modalOpen, setModalOpen] = useState(false);
  const [activateSection, setActivateSection] = useState(); // activate a special form on opening the modal
  const [singleForm, setSingleForm] = useState(); // display only one of the event settings forms
  console.log('toggleEventMenu-modalOpen', modalOpen);

  // get the modal container
  const modalRoot = document.getElementById('modalRoot');

  const toggleEventSettingsModal = () => {
    setModalOpen(!modalOpen);
  };

  // permission settings
  const { hasPermission } = useUserPermissions();

  useEffect(() => {
    //
    if (firstRun) {
      //   toggleEventSettingsModal();
      //   toggleEventSettingsModal();
      setFirstRun(false);
    }

    const subSettingsMenuId = events?.subscribe(
      'toggleEventMenu',
      function (data: any) {
        if (data.activateSection) {
          setActivateSection(data.activateSection);
        } else {
          setActivateSection(undefined);
        }

        if (data.singleForm) {
          setSingleForm(data.singleForm);
        }

        toggleEventSettingsModal();
      },
    );

    return () => {
      if (subSettingsMenuId) {
        events?.unsubscribe(subSettingsMenuId);
      }
    };
  }, []);

  return (
    <>
      {hasPermission({ objPath: 'event.settingsMenu' }) && (
        <Modal
          open={modalOpen}
          onClose={toggleEventSettingsModal}
          container={modalRoot}
          disablePortal={true}
        >
          <Suspense>
            <EventSettings
              handleCancel={toggleEventSettingsModal}
              activateSection={activateSection}
              singleForm={singleForm}
              submitCallback={submitCallback}
            />
          </Suspense>
        </Modal>
      )}
    </>
  );
}
