import ActorInvited from '-!svg-react-loader!../svg/ActorList/actorInvited.svg';
import { createSvgIcon } from '@mui/material';

const props = {
  version: '1.1',
  width: '24px',
  height: '24px',
  viewBox: '0 0 512 512',
};

export const ActorInvitedIcon = createSvgIcon(
  <ActorInvited {...(props as any)} />,
  'Trim',
);
