import {
  Box,
  Container,
  Stack,
  Toolbar,
  Link,
  useTheme,
  Modal,
  Paper,
} from '@mui/material';
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import StaffList from '../Staff/StaffList';
import useUserPermissions from '../Hooks/useUserPermissions';
import { getUserMenuAccess } from '../../util/userAccessRights';

export type SubNavigationParamsTypes = {
  // menuData?: { path?: string; active: boolean; title: string }[] | undefined;
  menu?: string;
};

export default function SubNavigationMain({
  // menuData = [{ path: 'sessions', active: true, title: 'Session' }],
  menu = 'main',
}: SubNavigationParamsTypes) {
  // params
  const menuData = getUserMenuAccess(menu);

  // state
  const [backButton, setBackButton] = useState(false);
  const [activeLink, setActiveLink] = useState('');

  // navigation
  const navParams = useParams();

  // theme
  const theme = useTheme();

  // setup session settings modal
  // get the modal container
  const modalRoot = document.getElementById('modalRoot');

  // startup activeLink data
  const location = useLocation();
  const actPath = location.pathname.split('/');
  const actPathEnd = actPath[actPath.length - 1];

  const [modalOpen, setModalOpen] = useState(false);
  const toggleSessionSettingsModal = () => {
    setModalOpen(!modalOpen);
  };

  // parse link path
  const parseLinkPath = (linkPath: string) => {
    const splitPath = linkPath.split('/');

    if (splitPath.length > 1) {
      const parsePath = splitPath.map((path) => {
        if (path.includes(':')) {
          return navParams[path.replace(':', '')];
        }

        return path;
      });
      if (!backButton) setBackButton(true);
      return parsePath.join('/');
    }
    if (backButton) setBackButton(false);
    return splitPath[0];
  };

  // permission settings
  const { hasPermission } = useUserPermissions();

  useEffect(() => {
    // set startup active link
    menuData?.forEach((data, index) => {
      const checkPath = data.path.split('/');
      if (actPathEnd === checkPath[checkPath.length - 1]) {
        setActiveLink(data.title);
      }
    });
  }, [menu]);

  return (
    <>
      {/* Settings Modal */}
      <Modal
        open={modalOpen}
        onClose={toggleSessionSettingsModal}
        container={modalRoot}
        keepMounted={true}
      >
        <Paper
          sx={{
            p: 2,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Box
            sx={{
              maxHeight: { xs: '100vh', sm: '70vh' },
              width: { xs: '100vw', sm: '90vw' },
              height: { xs: '100vh', sm: '50vh' },
              maxWidth: { xs: '100vw', sm: 840 },
              overflowY: 'scroll',
              overflowX: { xs: 'hidden', sm: 'unset' },
            }}
          >
            <StaffList role="sessionManager" />
          </Box>
        </Paper>
      </Modal>

      {/* Spacer */}
      <Toolbar />
      {/* Desktop Toolbar */}
      <Box
        sx={{
          position: 'fixed',
          width: '100%',
          zIndex: { xs: 1200, sm: 1000 },
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.navigationBg.main,
            position: 'relative',
            boxShadow: 'none',
          }}
        >
          <Container sx={{ px: { xs: 1, sm: 2 } }}>
            <Toolbar
              variant="dense"
              sx={{
                px: { xs: 0 },
                minHeight: '23px',
                // minHeight: { xs: 'unset', sm: '48px' }
              }}
            >
              <Stack
                direction="row"
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                {/* Menu */}
                <Stack direction="row" spacing={0} width="100%">
                  {backButton && (
                    <></>
                    // <IconButton
                    //   onClick={() => {
                    //     navigate('../..', { relative: 'path' });
                    //     events?.publish('subMenuPos', {
                    //       subMenuPos: 'Sessions',
                    //       backLink: true,
                    //     });
                    //   }}
                    //   sx={{ pl: 0 }}
                    // >
                    //   <ArrowBackIosNewIcon />
                    // </IconButton>
                  )}

                  {menuData?.map((link, index) => {
                    parseLinkPath(link.path!); // only called here to setup the back button

                    const pathSegements = link.path?.split('/');
                    let targetSegment: string = '';

                    if (pathSegements && pathSegements.length > 0) {
                      targetSegment = pathSegements[pathSegements.length - 1];
                    }

                    return link.path !== undefined ? (
                      hasPermission({
                        objPath: 'subnavigation.' + menu + '.' + targetSegment,
                      }) && (
                        <Link
                          key={link.title}
                          component={RouterLink}
                          to={parseLinkPath(link.path!)}
                          underline="none"
                          sx={{
                            fontSize: theme.typography.subtitle1.fontSize,
                            textTransform: 'uppercase',
                            color: theme.palette.text.primary,
                            borderBottom:
                              activeLink === link.title
                                ? `2px solid ${theme.palette.text.primary}`
                                : `2px solid ${theme.palette.navigationBg}`,

                            px: 4,
                            width: {
                              xs: `${100 / menuData.length}%`,
                              sm: 'unset',
                            },
                            textAlign: 'center',
                            // lineHeight: { xs: 'unset', sm: '46px' },
                          }}
                          onClick={() => {
                            // events?.publish('subMenuPos', {
                            //   subMenuPos: link.title,
                            //   backLink: link.backLink,
                            // });
                            setActiveLink(link.title);
                          }}
                        >
                          {link.title}
                        </Link>
                      )
                    ) : (
                      <Link
                        key={link.title}
                        underline="none"
                        sx={{
                          lineHeight: '46px',
                        }}
                      >
                        {link.title}
                      </Link>
                    );
                  })}
                </Stack>

                {/* Settings */}
                {/* !!!NEED TO MAKE THIS DEPENDING IF NAV-LEVEL IS SESSION */}
                {
                  navParams.sessionId && <></>
                  // hasPermission({ objPath: 'sessions.settingsMenu' }) && (
                  //   <Stack direction="row">
                  //     <Button
                  //       variant="text"
                  //       startIcon={<SettingsIcon />}
                  //       onClick={toggleSessionSettingsModal}
                  //     >
                  //       Settings
                  //     </Button>
                  //   </Stack>
                  // )
                }
              </Stack>
            </Toolbar>
          </Container>
        </Box>
      </Box>
    </>
  );
}
