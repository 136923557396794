import { Box, IconButton, List, Stack, TextField } from '@mui/material';
import { useStaffQuery } from '../../Router/routes/staff';
import { useParams } from 'react-router-dom';
import StaffListItem from './StaffListItem';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteStaffApi, setNewStaffApi } from '../../api';
import { useState } from 'react';
import {
  ErTextFieldWithSubmit,
  ErTextFieldWithSubmitMO,
} from 'eventrocket-commoncomponents';

type StaffListParamsTypes = {
  role: string;
};

export default function StaffList(params: { role: string }) {
  //
  const { role } = params;
  // nav params
  const { eventId, sessionId } = useParams();

  // state
  const [staffName, setStaffName] = useState('');

  // build rolename
  let roleName: string;

  if (role.includes('eventManager')) {
    roleName = role + '_' + eventId;
  } else if (role.includes('sessionManager')) {
    roleName = role + '_' + sessionId;
  } else {
    throw new Error('Invalid rolename provided in StaffList');
  }

  // get stafflist (for current event or session)
  const staffResult = useStaffQuery(eventId!, roleName);

  //// mutations
  const queryClient = useQueryClient();

  // add new staff for event or session
  const mutateNewStaff = useMutation({
    mutationFn: (data: any) =>
      setNewStaffApi(data.eventId!, data.name, data.roleName),
    onSuccess: () => {
      setStaffName('');
      queryClient.invalidateQueries({
        queryKey: ['staff', 'list', eventId, roleName],
      });
    },
  });

  const addNewStaff = async (
    eventId: string,
    roleName: string,
    name: string,
  ) => {
    if (staffName && staffName !== '') {
      mutateNewStaff.mutate({
        eventId: eventId,
        roleName: roleName,
        name: name,
      });
    }
  };

  // delete staff from event or session
  const mutateDeleteStaff = useMutation({
    mutationFn: (data: any) => deleteStaffApi(data.staffId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['staff', 'list', eventId, roleName],
      });
    },
  });

  const deleteStaff = (staffId: string) => {
    mutateDeleteStaff.mutate({ staffId: staffId });
  };

  return (
    <>
      <Stack width="100%">
        <List>
          {staffResult.isSuccess &&
            staffResult.data.map((staff, index) => {
              return (
                <StaffListItem
                  role={role}
                  staffData={staff}
                  deleteStaff={deleteStaff}
                  key={'staff-' + index}
                />
              );
            })}
        </List>
        <Stack direction="row">
          {/* <TextField
            fullWidth
            variant="standard"
            size="small"
            label="Name"
            value={staffName}
            onChange={(e) => setStaffName(e.target.value)}
          /> */}
          <ErTextFieldWithSubmitMO
            fullWidth
            variant="outlined"
            size="small"
            fieldLabel="Member"
            onChangeCallback={(e) => setStaffName(e.target.value)}
            submitForm={() => addNewStaff(eventId!, roleName, staffName)}
            editActive={staffName !== ''}
            value={staffName}
          />
          {/* <Stack direction="row">
            <IconButton
              onClick={() => {
                addNewStaff(eventId!, roleName, staffName);
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Stack> */}
        </Stack>
      </Stack>
    </>
  );
}
