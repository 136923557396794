export function changeRgbAlpha(rgba: string, newAlpha: number) {
  // Extrahiert die rgba-Werte aus dem String

  const rgbaPattern =
    /rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),?\s*([\d\.]*)?\)/;
  const match = rgba.match(rgbaPattern);

  if (!match) {
    throw new Error('Invalid RGBA color value');
  }

  const r = parseInt(match[1], 10);
  const g = parseInt(match[2], 10);
  const b = parseInt(match[3], 10);
  const a = newAlpha;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
}
