/**
 * Checks if two date objects have the same time
 * @param date1
 * @param date2
 * @returns boolean
 */
export function isSameTime(date1: Date, date2: Date) {
  return (
    date1.getHours() === date2.getHours() &&
    date1.getMinutes() === date2.getMinutes() &&
    date1.getSeconds() === date2.getSeconds()
  );
}
