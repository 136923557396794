import {
  Box,
  CircularProgress,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { avaialbleView, usePreviewContext } from './PreviewContext';
import { useEffect, useRef, useState } from 'react';
import useAuthStore from '../../store/authStore';
import getSessionTokenForPreviewApi from '../../api/user/getSessionTokenForPreviewApi';

export default function Preview() {
  // state
  const [previewSessionToken, setPreviewSessionToken] = useState(
    localStorage.getItem('bs_PLAYER_PREVIEW_TOKEN'),
  );

  // url params
  const { masterEventId } = useParams();
  // url search params
  const [searchParams, setSearchParams] = useSearchParams();
  const previewView = searchParams.get('previewView');

  // preview Context
  const {
    view,
    setView,
    setInitialView,
    activeSession,
    updateReloadFromNav,
    reloadFromNav,
  } = usePreviewContext();

  // set selected view & remove url parameter
  // otherwise the selection of different views won't work
  if (previewView) {
    //
    setInitialView(previewView as avaialbleView);

    setSearchParams((prev) => {
      prev.delete('previewView');
      return prev;
    });
  }

  // iframe src
  const [iframeSrc, setIframeSrc] = useState(
    process.env.REACT_APP_PLAYER_BASE_URL +
      '?eventId=' +
      masterEventId +
      '&preview=true&previewSession=' +
      activeSession,
  );

  // update the iframe scrc url
  useEffect(() => {
    //
    updateReloadFromNav(true);

    if (view === 'announcement') {
      setIframeSrc(
        process.env.REACT_APP_PLAYER_BASE_URL +
          '?eventId=' +
          masterEventId +
          '&preview=true&previewSession=' +
          activeSession +
          '&pSessionToken=' +
          previewSessionToken,
      );
    } else {
      setIframeSrc(
        process.env.REACT_APP_PLAYER_BASE_URL +
          '/' +
          view +
          '?eventId=' +
          masterEventId +
          '&preview=true&previewSession=' +
          activeSession +
          '&pSessionToken=' +
          previewSessionToken,
      );
    }
  }, [view, activeSession, previewSessionToken]);

  const handleIframeLoad = () => {
    // reset loading indocator once the iframe is loaded
    updateReloadFromNav(false);
  };

  return (
    <>
      {/* preview iframe */}
      {previewSessionToken && previewSessionToken !== '' && (
        <Box sx={{ width: '100%', height: 'calc(100% - 64px)' }}>
          {reloadFromNav && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 10,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <iframe
            id="bs_PREVIEW_PLAYER"
            name="bs_PREVIEW_PLAYER"
            width="100%"
            height="100%"
            frameBorder="0"
            onLoad={handleIframeLoad}
            src={iframeSrc}
            allowFullScreen
          />
        </Box>
      )}
    </>
  );
}
