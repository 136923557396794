import Parse from 'parse';
import useAuthStore from '../../store/authStore';

// !!! typing
export default async function updateSlotOrderApi(
  sessionId: string,
  slotOrder: any[],
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  const slotOrderIds = slotOrder.map((slot, index) => {
    return slot.id;
  });

  return Parse.Cloud.run(
    'updateSlotOrder',
    {
      sessionId: sessionId,
      slotOrder: slotOrderIds,
    },
    { sessionToken: sessionToken },
  );
}
