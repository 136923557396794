import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default function getActorsEventApi(eventId: string) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'getActorsEvent',
    { eventId: eventId },
    { sessionToken: sessionToken },
  );
}
