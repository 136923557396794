import { useMutation, useQueryClient } from '@tanstack/react-query';
import useEventStore from '../../store/eventStore';
import updateEventApi, {
  updateEventApiParamsTypes,
} from '../../api/event/updateEventApi';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { updateSessionApi } from '../../api';

type updateDisplayParams = {
  view: string;
  element: 'eventText' | 'additionalText' | 'agenda' | 'program' | 'countdown';
  value: boolean;
  sessionId?: string;
};

type updateTextParams = {
  view: string;
  element: 'eventText' | 'additionalText';
  value: string;
  sessionId?: string;
};

type getDisplayParams = {
  view: string;
  element: 'eventText' | 'additionalText' | 'agenda' | 'program' | 'countdown';
  sessionId?: string;
};

type getTextParams = {
  view: string;
  element: 'eventText' | 'additionalText';
  sessionId?: string;
};

type usePreviewDataHandlingProps = {
  sessionsData: any;
  reloadPreview: () => void;
};

export default function usePreviewDataHandling(
  props: usePreviewDataHandlingProps,
) {
  // props
  const { reloadPreview = () => {}, sessionsData } = props;

  // url params
  const { eventId } = useParams();

  // global state
  const eventDataOrig = useEventStore((store) => store.data);
  const marketingData = eventDataOrig.attributes.marketing.attributes;
  // feature data obj
  const fData = eventDataOrig.attributes.features_active;
  const registrationData = fData?.registration?.[0]?.settingsObj?.attributes;
  const onlineData = fData?.onlineStage?.[0]?.settingsObj?.attributes;

  //
  const [payload, setPayload] = useState<any>();

  // update display of text messages
  const updateTextDisplay = (params: updateDisplayParams) => {
    //
    const { view, element, value, sessionId = '' } = params;

    switch (view) {
      case 'announcement':
        if (element === 'eventText')
          setPayload({
            eventId: eventId!,
            marketingData: { showEventText: value },
          });
        if (element === 'additionalText')
          setPayload({
            eventId: eventId!,
            marketingData: { showAnnouncementText: value },
          });
        if (element === 'agenda')
          setPayload({
            eventId: eventId!,
            marketingData: { showProgram: value },
          });
        if (element === 'countdown')
          setPayload({
            eventId: eventId!,
            marketingData: { showCountDown: value },
          });
        break;
      case 'registration':
        if (element === 'eventText')
          setPayload({
            eventId: eventId!,
            registrationData: { data: { showEventText: value }, active: true },
          });
        if (element === 'additionalText')
          setPayload({
            eventId: eventId!,
            registrationData: {
              data: { showRegistrationText: value },
              active: true,
            },
          });
        if (element === 'agenda')
          setPayload({
            eventId: eventId!,
            registrationData: { data: { showAgenda: value }, active: true },
          });
        if (element === 'countdown')
          setPayload({
            eventId: eventId!,
            registrationData: { data: { showCountDown: value }, active: true },
          });
        break;
      case 'confirm':
        if (element === 'eventText')
          setPayload({
            eventId: eventId!,
            registrationData: {
              data: { showEventTextConfirmation: value },
              active: true,
            },
          });
        if (element === 'additionalText')
          setPayload({
            eventId: eventId!,
            registrationData: {
              data: { showConfirmationTextConfirmation: value },
              active: true,
            },
          });
        if (element === 'agenda')
          setPayload({
            eventId: eventId!,
            registrationData: {
              data: { showAgendaConfirmation: value },
              active: true,
            },
          });
        if (element === 'countdown')
          setPayload({
            eventId: eventId!,
            registrationData: {
              data: { showCountDownConfirmation: value },
              active: true,
            },
          });
        break;
      case 'welcome':
        if (element === 'eventText')
          setPayload({
            eventId: eventId!,
            registrationData: {
              data: { showEventTextWelcome: value },
              active: true,
            },
          });
        if (element === 'additionalText')
          setPayload({
            eventId: eventId!,
            registrationData: {
              data: { showWelcomeTextWelcome: value },
              active: true,
            },
          });
        if (element === 'agenda')
          setPayload({
            eventId: eventId!,
            registrationData: {
              data: { showAgendaWelcome: value },
              active: true,
            },
          });
        if (element === 'countdown')
          setPayload({
            eventId: eventId!,
            registrationData: {
              data: { showCountDownWelcome: value },
              active: true,
            },
          });
        break;
      case 'lobby':
        if (element === 'eventText')
          setPayload({
            eventId: eventId!,
            onlineData: {
              data: { showEventText: value },
              active: true,
            },
          });
        if (element === 'additionalText')
          setPayload({
            eventId: eventId!,
            onlineData: {
              data: { showLobbyText: value },
              active: true,
            },
          });
        break;
      case 'sessionWelcome':
        // need to send full scene Object!!!
        if (element === 'additionalText')
          setPayload({
            eventId: eventId!,
            data: {
              id: sessionId,
              scene: {
                ...getSessionById(sessionId).get('scene'),
                showDescriptionOnSessionWelcome: value,
              },
            },
          });
        if (element === 'agenda')
          setPayload({
            eventId: eventId!,
            data: {
              id: sessionId,
              scene: {
                ...getSessionById(sessionId).get('scene'),
                showAgendaOnSessionWelcome: value,
              },
            },
          });
        break;
      case 'live':
        // need to send full scene Object!!!
        if (element === 'additionalText')
          setPayload({
            eventId: eventId!,
            data: {
              id: sessionId,
              scene: {
                ...getSessionById(sessionId).get('scene'),
                showDescriptionOnSessionBroadcast: value,
              },
            },
          });
        if (element === 'agenda')
          setPayload({
            eventId: eventId!,
            data: {
              id: sessionId,
              scene: {
                ...getSessionById(sessionId).get('scene'),
                showAgendaOnSessionBroadcast: value,
              },
            },
          });
        break;
      case 'closed':
        if (element === 'eventText')
          setPayload({
            eventId: eventId!,
            onlineData: {
              data: { showEventTextFarewell: value },
              active: true,
            },
          });
        if (element === 'additionalText')
          setPayload({
            eventId: eventId!,
            onlineData: {
              data: { showFarewellText: value },
              active: true,
            },
          });
        break;
    }

    if (view !== 'live' && view !== 'sessionWelcome') {
      submitEventData();
    } else {
      submitSessionData();
    }
  };

  // get the value of text display
  const getTextDisplay = (params: getDisplayParams) => {
    const { view, element, sessionId = '' } = params;
    // console.log('##-getTextDisplay', params);

    let result = false;
    switch (view) {
      case 'announcement':
        element === 'eventText' && (result = marketingData?.showEventText);
        element === 'additionalText' &&
          (result = marketingData?.showAnnouncementText);
        element === 'agenda' && (result = marketingData?.showProgram);
        element === 'countdown' && (result = marketingData?.showCountDown);
        break;
      case 'registration':
        element === 'eventText' && (result = registrationData?.showEventText);
        element === 'additionalText' &&
          (result = registrationData?.showRegistrationText);
        element === 'agenda' && (result = registrationData?.showAgenda);
        element === 'countdown' && (result = registrationData?.showCountDown);
        break;
      case 'confirm':
        element === 'eventText' &&
          (result = registrationData?.showEventTextConfirmation);
        element === 'additionalText' &&
          (result = registrationData?.showConfirmationTextConfirmation);
        element === 'agenda' &&
          (result = registrationData?.showAgendaConfirmation);
        element === 'countdown' &&
          (result = registrationData?.showCountDownConfirmation);
        break;
      case 'welcome':
        element === 'eventText' &&
          (result = registrationData?.showEventTextWelcome);
        element === 'additionalText' &&
          (result = registrationData?.showWelcomeTextWelcome);
        element === 'agenda' && (result = registrationData?.showAgendaWelcome);
        element === 'countdown' &&
          (result = registrationData?.showCountDownWelcome);
        break;
      case 'lobby':
        element === 'eventText' && (result = onlineData?.showEventText);
        element === 'additionalText' && (result = onlineData?.showLobbyText);
        break;
      case 'sessionWelcome':
        element === 'additionalText' &&
          (result =
            getSessionById(sessionId)?.get('scene')
              ?.showDescriptionOnSessionWelcome);
        element === 'agenda' &&
          (result =
            getSessionById(sessionId)?.get('scene')
              ?.showAgendaOnSessionWelcome);
        break;
      case 'live':
        element === 'additionalText' &&
          (result =
            getSessionById(sessionId)?.get('scene')
              ?.showDescriptionOnSessionBroadcast);
        element === 'agenda' &&
          (result =
            getSessionById(sessionId)?.get('scene')
              ?.showAgendaOnSessionBroadcast);
        break;
      case 'closed':
        element === 'eventText' && (result = onlineData?.showEventTextFarewell);
        element === 'additionalText' && (result = onlineData?.showFarewellText);
        break;
    }

    return result;
  };

  // get the text
  const getText = (params: getTextParams) => {
    //
    const { view, element, sessionId = '' } = params;

    let result = 'false';
    if (element === 'eventText') {
      result = eventDataOrig.get('descriptionHtml');
    } else {
      switch (view) {
        case 'announcement':
          element === 'additionalText' &&
            (result = marketingData?.announcementHtml);
          break;
        case 'registration':
          element === 'additionalText' &&
            (result = registrationData?.registrationText);
          break;
        case 'confirm':
          element === 'additionalText' &&
            (result = registrationData?.confirmationText);
          break;
        case 'welcome':
          element === 'additionalText' &&
            (result = registrationData?.welcomeText);
          break;
        case 'lobby':
          element === 'additionalText' && (result = onlineData?.playerText);
          break;
        case 'sessionWelcome':
          element === 'additionalText' &&
            (result = getSessionById(sessionId)?.get('description'));
          break;
        case 'live':
          element === 'additionalText' &&
            (result = getSessionById(sessionId)?.get('description'));
          break;
        case 'closed':
          element === 'additionalText' && (result = onlineData?.farewellText);
          break;
      }
    }

    return result;
  };

  // update the text
  const updateText = (params: updateTextParams) => {
    const { view, value, element, sessionId = '' } = params;

    if (element === 'eventText') {
      setPayload({
        eventId: eventId!,
        basicData: { descriptionHtml: value },
      });
    } else {
      switch (view) {
        case 'announcement':
          setPayload({
            eventId: eventId!,
            marketingData: { announcementHtml: value },
          });
          break;
        case 'registration':
          setPayload({
            eventId: eventId!,
            registrationData: {
              data: { registrationText: value },
              active: true,
            },
          });
          break;
        case 'confirm':
          setPayload({
            eventId: eventId!,
            registrationData: {
              data: { confirmationText: value },
              active: true,
            },
          });
          break;
        case 'welcome':
          setPayload({
            eventId: eventId!,
            registrationData: { data: { welcomeText: value }, active: true },
          });
          break;
        case 'lobby':
          setPayload({
            eventId: eventId!,
            onlineData: {
              data: { playerText: value },
              active: true,
            },
          });
          break;
        case 'sessionWelcome':
          setPayload({
            eventId: eventId!,
            data: {
              id: sessionId,
              description: value,
            },
          });
          break;
        case 'live':
          setPayload({
            eventId: eventId!,
            data: {
              id: sessionId,
              description: value,
            },
          });
          break;
        case 'closed':
          setPayload({
            eventId: eventId!,
            onlineData: {
              data: { farewellText: value },
              active: true,
            },
          });
          break;
      }
    }

    if (view !== 'live' && view !== 'sessionWelcome') {
      submitEventData();
    } else {
      submitSessionData();
    }
  };

  // get session form id
  const getSessionById = (sessionId: string) => {
    const session = sessionsData?.find(
      (session: any) => session.id === sessionId,
    );

    return session;
  };

  // handle data saving
  const queryClient = useQueryClient();

  const mutateSaveEvent = useMutation({
    mutationFn: () => updateEventApi(payload as updateEventApiParamsTypes),
    onSuccess: () => {
      // setFeedback({ type: 'success', headline: 'Daten gespeichert' });
      //   setFeedback(null);
      queryClient.invalidateQueries({
        queryKey: ['event', 'details', eventId],
      });
      queryClient.invalidateQueries({
        queryKey: ['sessions', 'list', eventId],
      });

      reloadPreview();
    },
    onError: (e) => {
      // setFeedback({ type: 'error', errorCode: e });
    },
  });

  const submitEventData = () => {
    // handleCancel();
    // setFeedback({ type: 'loading' });
    console.log('##-submitData');
    mutateSaveEvent.mutate();
    // delay the submit function to make sure onBlur data is set
    // setTimeout(function () {
    //   mutateSaveEvent.mutate();
    // }, 1000);
  };

  const mutateSaveSession = useMutation({
    mutationFn: () => updateSessionApi(payload.data, payload.eventId),
    onSuccess: () => {
      // setFeedback({ type: 'success', headline: 'Daten gespeichert' });
      //   setFeedback(null);
      queryClient.invalidateQueries({
        queryKey: ['sessions', 'list', eventId],
      });

      reloadPreview();
    },
    onError: (e) => {
      // setFeedback({ type: 'error', errorCode: e });
    },
  });

  const submitSessionData = () => {
    // handleCancel();
    // setFeedback({ type: 'loading' });
    console.log('##-submitData');
    mutateSaveSession.mutate();
    // delay the submit function to make sure onBlur data is set
    // setTimeout(function () {
    //   mutateSaveEvent.mutate();
    // }, 1000);
  };

  return {
    updateTextDisplay: updateTextDisplay,
    getTextDisplay: getTextDisplay,
    getText: getText,
    updateText: updateText,
  };
}
