import useAuthStore from '../../store/authStore';
import Parse from 'parse';

export default async function getLiveDataApi(eventId: string) {
  // get the user form global store
  const user = useAuthStore.getState().user;

  return await Parse.Cloud.run(
    'getLiveData',
    { eventId: eventId },
    { sessionToken: user.sessionToken },
  );
}
