import * as yup from 'yup';
import i18n from 'i18next';
import { UpdateSlotDataTypes } from './slotDetailsFormTypes';

// !!! Error Messages Missing !!!
export const updateSlotValSchema = yup.object({
  id: yup.string(),
  sessionId: yup.string(),
  title: yup
    .string()
    .required(() => i18n.t('event:LOGIN.ERROR.title_required')),
  description: yup.string(),
  type: yup.string().required(),
  durationPlaned: yup.number().required(),
  durationMin: yup.number().required(),
  starttimePlanned: yup.date().nullable(),
  showInAgenda: yup.boolean(),
  showActorsInAgenda: yup.boolean(),
  showDescriptionInAgenda: yup.boolean(),
  newColumnAfter: yup.boolean(),
  slotContentType: yup.string(),
});

export const slotDefaults: UpdateSlotDataTypes = {
  id: undefined,
  sessionId: undefined,
  title: '',
  description: '',
  type: 'standard',
  durationPlaned: 0,
  durationMin: 0,
  starttimePlanned: undefined,
  showInAgenda: true,
  showActorsInAgenda: false,
  showDescriptionInAgenda: false,
  newColumnAfter: false,
  slotContentType: '',
};
