import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getSlotsApi } from '../../api';
import { useEffect } from 'react';
import useSlotsStore from '../../store/slotsStore';
import getSlotlistApi from '../../api/slot/getSlotlistApi';

// Layer II Data Handling
export function useSlotsBySessionIdQuery(sessionId: string) {
  const queryClient = useQueryClient();

  return useQuery(
    {
      queryKey: ['slots', 'list', sessionId],
      queryFn: () =>
        // process.env.NODE_ENV === 'production'
        //   ? getSlotsApi(sessionId)
        //   : getSlotlistApi(sessionId),
        getSlotsApi(sessionId),
    },
    queryClient,
  );
}

// Layer III Data Handling
export default function useSlotsQueryHook(sessionId: string) {
  // event query
  const { data, error, isLoading, isSuccess, isError, status } =
    useSlotsBySessionIdQuery(sessionId);

  // event store
  const { setData, setStatus } = useSlotsStore();

  useEffect(() => {
    // update data
    if (data) setData(data);
  }, [data]);

  useEffect(() => {
    // update state
    setStatus(status);
  }, [status]);

  return { data, isLoading, isSuccess, isError };
}
