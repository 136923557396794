import Parse from 'parse';
import useAuthStore from '../../store/authStore';

// Level I Data handling
export default async function getEventApi(eventId: string) {
  // get the user form global store
  const user = useAuthStore.getState().user;

  const event = await Parse.Cloud.run(
    'getEvent',
    { id: eventId },
    { sessionToken: user.sessionToken },
  ).catch((e) => {
    console.error(e);
    throw new Error(e);
  });

  return event.parse;
}
