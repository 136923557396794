import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export type getSessionsApiParamsTypes = {
  eventId: string | undefined;
  itemsPerRequest?: number | undefined;
  currentPage?: number | undefined;
};

export default async function getSessionsApi(
  params: getSessionsApiParamsTypes,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'getSessions',
    {
      eventId: params.eventId,
      itemsPerRequest: params.itemsPerRequest,
      currentPage: params.currentPage,
    },
    { sessionToken: sessionToken },
  );
}
