import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default function deleteActorApi(
  actorId: string,
  eventId: string,
  sessionId: string,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'deleteActor',
    { actorId: actorId, eventId: eventId, sessionId: sessionId },
    { sessionToken: sessionToken },
  );
}
