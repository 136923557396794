export const onlinePlayerTextSizeOptions = [
  { label: 'Klein', value: 'small' },
  { label: 'Mittel', value: 'medium' },
  { label: 'Groß', value: 'large' },
];

export const onlinePlayerDividerActors = [
  { label: ', (Komma)', value: ', ' },
  { label: '| (Spiegelstrich)', value: ' | ' },
  { label: '- (Trennstrich)', value: ' - ' },
];

export const onlinePlayerDividerTitle = [
  { label: ', (Komma)', value: ', ' },
  { label: '| (Spiegelstrich)', value: ' | ' },
  { label: '- (Trennstrich)', value: ' - ' },
];

export const onlinePlayerDateFormat = [
  { label: 'TT.MM.JJ', value: 'shortDot' },
  { label: 'TT/MM/JJ', value: 'shortSlash' },
  { label: 'TT.MM.JJJJ', value: 'mediumDot' },
  { label: 'TT/MM/JJJJ', value: 'mediumSlash' },
  { label: 'TT. Monat JJJJ', value: 'longDot' },
];

export const onlinePlayerDateTime = [
  { label: 'hh.mm', value: 'shortDot' },
  { label: 'hh:mm', value: 'shortColon' },
  { label: 'hh.mm Uhr', value: 'longDot' },
  { label: 'hh:mm Uhr', value: 'longColon' },
];
