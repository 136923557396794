import { Theme, useTheme } from '@mui/material';
import {
  mediaDataTypes,
  slotDataTypes,
} from '../../Components/Slots/Context/SlotContext';
import { mediaIconStateTypes } from '../../Components/Slots/SlotListItem';

// export default function getMediaState(
//   media: mediaDataTypes[],
//   mediaRequests?: boolean,
//   actorOptions?: any[], // actorOptions of the slot / session to check for media requests
//   actor?: any, // check media requests for a specif actor? if not all mediarequests of the slot will be checked
// ) {

type getMediaStateParams = {
  media: mediaDataTypes[];
  mediaRequests?: boolean;
  actorOptions?: any[]; // actorOptions of the slot / session to check for media requests
  actor?: any; // check media requests for a specif actor? if not all mediarequests of the slot will be checked}
  sessionActors?: any[];
  theme: Theme;
};

export default function getMediaState(params: getMediaStateParams) {
  const { media, mediaRequests, actorOptions, actor, sessionActors, theme } =
    params;

  // get theme
  // const theme = useTheme();

  // media icon color
  const mediaIconState: mediaIconStateTypes = {
    mediaColor: theme.palette.warning.main,
    mediaActive: true,
  };

  // check if we need to check for mediaRequests
  const mediaRequestActorIds: any[] = [];

  if (actorOptions && !actor) {
    // no specific actor get all actorOptions that have a media request
    const actorOptionsWithMediaRequests = actorOptions.filter(
      (actorOpt: any) => actorOpt.requestMediaUpload === true,
    );

    actorOptionsWithMediaRequests?.forEach((actOpt: any) =>
      mediaRequestActorIds.push(actOpt.actorId),
    );
  } else if (actorOptions && actor) {
    // specific actor only check the singele actor
    const specificActorOption = actorOptions.find(
      (actorOpt: any) => actorOpt.actorId === actor.id,
    );

    if (specificActorOption?.requestMediaUpload === true) {
      mediaRequestActorIds.push(specificActorOption.actorId);
    }
  }

  console.log('###!!!-media', media);

  if (media) {
    // check existing media
    if (media?.length === 0) {
      mediaIconState.mediaColor = theme.palette.action.disabled;
      mediaIconState.mediaActive = false;
      if (mediaRequests) {
        mediaIconState.mediaColor = theme.palette.warning.main;
        mediaIconState.mediaActive = true;
      }
    } else {
      // CHECK EXISTING MEDIA
      const hasUnapprovedNewerVersion = media?.some((media: any) => {
        const approvedFile = media
          ?.get('files')
          ?.find((file: any) => file.file.get('approved'));
        if (!approvedFile) {
          return false;
        }
        const approvedCreatedAt = new Date(
          approvedFile?.file?.get('createdAt'),
        );
        return media?.get('files')?.some((file: any) => {
          const fileCreatedAt = new Date(file.file.get('createdAt'));
          return (
            !file.file.get('approved') && fileCreatedAt > approvedCreatedAt
          );
        });
      });

      const allLatestVersionsApproved = media?.every((media: any) => {
        if (media?.get('files').length === 0) return false;
        const latestFile = media
          ?.get('files')
          ?.reduce((latest: any, file: any) => {
            return new Date(file.file.get('createdAt')) >
              new Date(latest.file.get('createdAt'))
              ? file
              : latest;
          });

        return latestFile?.file.get('approved');
      });

      if (hasUnapprovedNewerVersion)
        mediaIconState.mediaColor = theme.palette.info.main;

      if (allLatestVersionsApproved)
        mediaIconState.mediaColor = theme.palette.success.main;
    }
  } else {
    mediaIconState.mediaColor = theme.palette.action.disabled;
  }

  // CHECK REQUESTED MEDIA

  if (mediaRequestActorIds && mediaRequestActorIds.length > 0) {
    let overwritePrevious = false;

    for (let index = 0; index < mediaRequestActorIds.length; index++) {
      // get a the user for the actor
      let user: any;
      if (actor) {
        // specific user get user from actor obj
        user = actor.get('user');
      } else {
        // none specific user(s) get user from sessionActorslist
        if (sessionActors) {
          user = sessionActors
            .find((actor: any) => actor.id === mediaRequestActorIds[index])
            ?.get('user');
        } else {
          // session actors doesn' exist means we cannot determie the state
          console.error('getMediaState: Missing required sessionActors list');
        }
      }

      const haveMediaFromUser = media?.some((medium: any) => {
        return medium.get('owner')?.id === user?.id;
      });

      if (!haveMediaFromUser) {
        overwritePrevious = true;
        break;
      }
    }

    if (overwritePrevious)
      mediaIconState.mediaColor = theme.palette.warning.main;
  }

  // }

  return mediaIconState;
}
