import { create } from 'zustand';
import Parse from 'parse';

type LiveUpdateStore = {
  liveQueryClient: Parse.LiveQueryClient | null;
  setLiveQueryClient: (lqc: Parse.LiveQueryClient) => void;
};

const useLiveUpdateStore = create<LiveUpdateStore>((set) => ({
  liveQueryClient: null,
  setLiveQueryClient: (lqc: Parse.LiveQueryClient) =>
    set({ liveQueryClient: lqc }),
}));

// export hook
export default useLiveUpdateStore;
