import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default async function getUserRolesApi(eventId: string) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return await Parse.Cloud.run(
    'getUserRoles',
    { eventId: eventId },
    { sessionToken: sessionToken },
  );
}
