import React from 'react';

// this object servers as a global store for
// navigation elements received from the parent application
// it MUST NOT contain elements that are essentail for the child application
// not a very beautiful solution, more kind of a hack...

export type navigationComponentsTypes = {
  userMenu: null | React.ReactNode;
};

export const navigationComponents: navigationComponentsTypes = {
  userMenu: null,
};
