import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  SxProps,
  InputProps,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type _TextFieldWithSubmitTypes<T extends FieldValues> = {
  fullWidth?: boolean;
  fieldName: Path<T>;
  fieldLabel?: string;
  control: Control<T>;
  required?: boolean;
  error?: string;
  defaultValue?: any;
  variant?: 'outlined' | 'filled' | 'standard';
  type?: 'number' | 'password' | 'search';
  multiline?: boolean;
  rows?: number;
  size?: 'small' | 'medium' | undefined;
  readOnly?: boolean;
  disabled?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  placeholder?: string;
  sx?: SxProps;
  InputPropsSx?: SxProps;
  InputLabelPropsSx?: SxProps;
  editActive: boolean;
  submitForm: () => void;
  submitOnEnter?: boolean;
  //   onMouseEnter?: any;
  //   onMouseLeave?: any;
};

export default function _TextFieldWithSubmit<T extends FieldValues>(
  props: _TextFieldWithSubmitTypes<T>,
) {
  const {
    fullWidth,
    fieldName,
    fieldLabel,
    control,
    required,
    variant,
    error,
    defaultValue = '',
    type,
    multiline,
    rows,
    size,
    readOnly,
    disabled,
    autoComplete,
    autoFocus = false,
    placeholder,
    sx,
    InputPropsSx,
    InputLabelPropsSx,
    editActive,
    submitForm,
    submitOnEnter = true,
    // onMouseEnter,
    // onMouseLeave,
  } = props;

  return (
    <FormControl fullWidth={fullWidth}>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextField
            // onMouseEnter={onMouseEnter}
            // onMouseLeave={onMouseLeave}
            inputRef={(input) => autoFocus && input && input.focus()}
            sx={sx}
            required={required}
            disabled={disabled}
            label={fieldLabel}
            autoComplete={autoComplete}
            variant={variant}
            type={type}
            multiline={multiline}
            rows={rows}
            size={size}
            error={Boolean(error)}
            helperText={error}
            InputProps={{
              sx: { ...InputPropsSx },
              readOnly: readOnly,
              endAdornment: editActive && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => submitForm()}
                    color="success"
                    size="small"
                  >
                    <CheckIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { ...InputLabelPropsSx },
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && submitOnEnter) {
                submitForm();
                event.preventDefault();
              }
            }}
            inputProps={{ autoFocus: autoFocus, placeholder: placeholder }}
            {...field}
          />
        )}
      />
    </FormControl>
  );
}
