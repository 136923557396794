import * as yup from 'yup';
import i18n from 'i18next';
import { UpdateActorDataTypes } from './actorFormTypes';

// !!! Error Messages Missing !!!
export const updateActorValSchema = yup.object({
  name: yup.string(),
  firstname: yup.string(),
  lastname: yup.string(),
  email: yup.string().email(),
  title: yup.string().max(128),
  organisation: yup.string().max(128),
  mobile: yup.string(),
  vita: yup.string(),
  photo: yup.array().of(yup.string()),
  // displayActorInAgenda: yup.boolean(),
  // displayActorInAgendaSlots: yup.array().of(yup.mixed<Parse.Pointer>()),
});

export const ActorDefaults: UpdateActorDataTypes = {
  name: 'Neuer Benutzer',
  firstname: 'Neuer',
  lastname: 'Benutzer',
  email: '',
  title: '',
  organisation: '',
  mobile: '',
  photo: [],
  vita: '',
  // displayActorInAgenda: true,
  // displayActorInAgendaSlots: [],
  // id: undefined,
  // sessionId: undefined,
  // title: '',
  // description: '',
  // type: 'standard',
  // durationPlaned: 0,
  // durationMin: 0,
  // starttimePlanned: undefined,
};
