import Parse from 'parse';
import {
  MediaFileUpdateTypes,
  MediaUpdateTypes,
} from '../../Components/Slots/Slots';
import useAuthStore from '../../store/authStore';

export default function updateMediaFileApi(
  updateData: MediaUpdateTypes | MediaFileUpdateTypes,
  eventId: string | undefined,
  sessionId: string | undefined,
) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'updateMediaFile',
    { ...updateData, eventId: eventId, sessionId: sessionId },
    { sessionToken: sessionToken },
  );
}
