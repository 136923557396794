import React from 'react';
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Controller, FieldValues } from 'react-hook-form';
import { ErTextFieldWithSubmitMOTypes } from './ErTextFieldWithSubmitMO.types';



export default function ErTextFieldWithSubmitMO(
  props: ErTextFieldWithSubmitMOTypes
) {
  const {
    fullWidth,
    fieldName,
    fieldLabel,
    required,
    variant,
    error,
    defaultValue = '',
    type,
    multiline,
    rows,
    size,
    readOnly,
    disabled,
    autoComplete,
    autoFocus = false,
    placeholder,
    sx,
    InputPropsSx,
    InputLabelPropsSx,
    editActive=true,
    submitForm,
    submitOnEnter = true,
    // onMouseEnter,
    // onMouseLeave,
    fontSize,
    fontWeight,
    lineHeight,
    value,
    onChangeCallback,
  } = props;

  return (
    <FormControl fullWidth={fullWidth}>
          <TextField
            // onMouseEnter={onMouseEnter}
            // onMouseLeave={onMouseLeave}
            inputRef={(input) => autoFocus && input && input.focus()}
            sx={{
              ...sx}}
            required={required}
            disabled={disabled}
            label={fieldLabel}
            autoComplete={autoComplete}
            variant={variant}
            type={type}
            multiline={multiline}
            rows={rows}
            size={size}
            error={Boolean(error)}
            helperText={error}
            onChange={(e) => { if(onChangeCallback) onChangeCallback(e)}}
            value={value}
            InputProps={{
              sx: { ...InputPropsSx, fontSize: fontSize, fontWeight: fontWeight, lineHeight:lineHeight,
                 },
              readOnly: readOnly,
              endAdornment: editActive && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => submitForm()}
                    color="success"
                    size="small"
                  >
                    <CheckIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { ...InputLabelPropsSx },
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && submitOnEnter) {
                submitForm();
                event.preventDefault();
              }
            }}
            inputProps={{ autoFocus: autoFocus, placeholder: placeholder }}
          />
    </FormControl>
  );
}
