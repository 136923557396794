import { FormControl, SxProps, Typography } from '@mui/material';
import FileUpload, { FileUploadParamsTypes } from './FileUpload';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { UpdateEventDataTypes } from '../../../Event/Form/eventDetailsFormTypes';
import Resizer from 'react-image-file-resizer';
import { useEffect } from 'react';

interface _FileUploadParamsTypes<T extends FieldValues = FieldValues> {
  fullWidth?: boolean;
  sx?: SxProps;
  fieldName: Path<T>;
  fieldLabel: string;
  control: Control<T>;
  required?: boolean;
  error?: string;
  defaultValue?: any;
  // setValue?: (name: keyof UpdateEventDataTypes, file: any) => void;
  setValue?: (name: Path<T>, file: any) => void;
  multiple?: boolean;
  maxFiles?: number;
  disabled?: boolean;
  imageResizerSettings: {
    maxWidth: number;
    maxHeight: number;
    minWidth: number;
    minHeight: number;
    jpegQuality: number;
  };
  submitForm?: () => void;
  // refreshOnlyOnFirstRun?: boolean; // prevents flickering of the preview in some situations
}

// export default function _FileUpload(parms: FileUploadParamsTypes) {
export default function _FileUpload<T extends FieldValues>({
  fullWidth = true,
  sx,
  fieldName,
  fieldLabel,
  control,
  required,
  defaultValue,
  setValue,
  imageResizerSettings,
  multiple,
  maxFiles,
  disabled = false, // refreshOnlyOnFirstRun = true,
  submitForm,
}: _FileUploadParamsTypes<T>) {
  // disabled style
  const disabledSx = { opacity: disabled ? 1 : 1 };
  // set image
  const handleFiles = async (files: Blob[]) => {
    if (!disabled) {
      const preparedImages = await prepareImages(files);
      setValue!(fieldName, preparedImages);

      submitForm && submitForm();
    }
  };

  // prepare handler
  const prepareImages = async (files: Blob[]) => {
    const filesOut = await Promise.all(
      files.map((file, index) => {
        return resizeImage(file);
      }),
    ).catch((e) => {
      return e;
    });

    return filesOut;
  };

  // resize & convert images
  const resizeImage = (file: Blob) =>
    new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        imageResizerSettings.maxWidth,
        imageResizerSettings.maxHeight,
        'JPEG',
        imageResizerSettings.jpegQuality,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64',
        imageResizerSettings.minWidth,
        imageResizerSettings.minHeight,
      );
    });

  // useEffect(() => {
  //   if (defaultValue) {
  //     setValue!(fieldName, defaultValue);
  //   }
  // }, [defaultValue]);

  return (
    <FormControl fullWidth={fullWidth} sx={{ ...disabledSx, ...sx }}>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <>
              <Typography>{fieldLabel}</Typography>
              <FileUpload
                name=""
                error={false}
                helperText={''}
                defaultValue={defaultValue}
                // defaultValue={field.value}
                handleFiles={handleFiles}
                multiple={multiple}
                maxFiles={maxFiles}
                disabled={disabled}
                // refreshOnlyOnFirstRun={refreshOnlyOnFirstRun}
              />
            </>
          );
        }}
      />
    </FormControl>
  );
}
