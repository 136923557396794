import React from 'react';
import { FormControl, FormHelperText, Stack, Switch, TextField, Typography } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { ErSwitchTypes } from './ErSwitch.types';



/**
 * TextField Component
 * @param props 
 * @returns a TextField component to be directly used in react-hook-form contexts
 */
export default function ErSwitch<T extends FieldValues>(
  props: ErSwitchTypes<T>,
) {
  const {
    fullWidth,
    fieldName,
    fieldLabel,
    labelPos='start',
    labelVariant='body1',
    control,
    required,
    error,
    defaultValue = false,
    disabled,
    size,
    sx,
    onChange
  } = props;

  return (
    <Stack direction="row" alignItems="baseline">
      {fieldLabel && labelPos === 'start' && <Typography variant={labelVariant} sx={{flexShrink: 0}} component='span'>{fieldLabel}</Typography>}
      <FormControl sx={{flexShrink: 0}} fullWidth={fullWidth} component='span'>
        <Controller
          name={fieldName}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Switch
              checked={field.value}
              sx={sx}
              required={required}
              disabled={disabled}
              size={size}
              {...field}
              onChange={(event) => {
                field.onChange(event);
                if (onChange) {
                  onChange(event);
                }}}
            />
          )}
        />
        {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
      {fieldLabel && labelPos === 'end' && <Typography variant={labelVariant} sx={{flexShrink: 0}} component='span'>{fieldLabel}</Typography>}
    </Stack>
  );
}
