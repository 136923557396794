import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export type downloadType =
  | 'new'
  | 'newConfirmed'
  | 'newUnconfirmed'
  | 'allNewSinceLast';

export type parentClass = 'Event' | 'Session' | 'Slot';

export type downloadMediaFilesApiParamsTypes = {
  downloadType: downloadType;
  parentClass: parentClass;
  parentId: string;
  eventId: string;
  allFilesOfSubobjects?: boolean;
};

export type downloadMediaFilesApiReturnTypes = any;

export default function downloadMediaFilesApi(
  params: downloadMediaFilesApiParamsTypes,
): downloadMediaFilesApiReturnTypes {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  return Parse.Cloud.run(
    'downloadMediaFiles',
    { ...params },
    { sessionToken: sessionToken },
  );
}
