import { IconButton, ListItem, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useAuthStore from '../../store/authStore';
import { useParams } from 'react-router-dom';
import AddLinkIcon from '@mui/icons-material/AddLink';

type StaffListItemParmsTypes = {
  staffData: Parse.Object;
  role: string;
  deleteStaff: (staffId: string) => void;
};

export default function StaffListItem(props: StaffListItemParmsTypes) {
  const { staffData, deleteStaff, role } = props;

  // nav params
  const { masterEventId, eventId, sessionId } = useParams();

  const staffRoles = staffData.get('roles');

  let invitationLink =
    process.env.REACT_APP_PARENT_PUBLIC_URL +
    '?eit=' +
    staffData.get('invitationToken') +
    '&sId=' +
    useAuthStore.getState().user.ouid;

  if (role === 'eventManager') {
    invitationLink +=
      '&rp=/events/' + masterEventId + '/' + eventId + '/sessions';
  } else if (role === 'sessionManager') {
    invitationLink +=
      '&rp=/events/' + masterEventId + '/' + eventId + '/sessions/' + sessionId;
  } else {
    throw new Error('No mathcing role in StaffListItem');
  }

  const copyInvitationLink = () => {
    // copy to clipboard
    navigator.clipboard.writeText(invitationLink);
  };

  return (
    <>
      <ListItem sx={{ width: '100%' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{staffData.get('firstname')}</Typography>
          <Stack direction="row">
            <IconButton
              onClick={() => {
                copyInvitationLink();
              }}
            >
              <AddLinkIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                deleteStaff(staffData.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Stack>
      </ListItem>
    </>
  );
}
